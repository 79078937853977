/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';

import messages from './NavFilter.messages';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import SVG from '@broadleaf/admin-components/dist/common/components/SVG';

const NavFilter = props => {
  const { filter, setFilter } = props;
  const formatMessage = useFormatMessage();

  return (
    <div className="tw-m-4 tw-mb-5 tw-flex tw-items-center tw-overflow-hidden tw-rounded-full tw-bg-gray-800 tw-p-1">
      <SVG
        name="search"
        className="tw-mx-2 tw-h-4 tw-w-4 tw-fill-current tw-text-gray-600"
      />
      <input
        value={filter}
        onChange={e => setFilter(e.target.value)}
        className="tw-ml-1 tw-w-full tw-flex-1 tw-bg-gray-800 tw-text-sm tw-text-gray-400 focus:tw-outline-none"
        placeholder={formatMessage(messages.filterPlaceholder)}
      />
      {filter && (
        <SVG
          name="close-solid"
          className="tw-mx-2 tw-h-4 tw-w-4 tw-cursor-pointer tw-fill-current tw-text-gray-500 hover:tw-text-gray-400"
          onClick={() => setFilter('')}
        />
      )}
    </div>
  );
};
NavFilter.propTypes = {
  /** The current navigation section filter **/
  filter: PropTypes.string.isRequired,
  /** Setter to update the navigation filter**/
  setFilter: PropTypes.func.isRequired
};

export default NavFilter;
