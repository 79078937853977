/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { some } from 'lodash';

import ColumnHeader from '@broadleaf/admin-components/dist/common/components/ColumnHeader';
import { SelectableType } from '@broadleaf/admin-components/dist/common/constants';
import useCollectionState, {
  selectCurrentPage,
  selectCurrentItems,
  selectFilters,
  selectPaginateProps,
  selectIsSelectVisible,
  selectIsSelectAll,
  selectIsSelectNonVisible,
  selectSelectedCount,
  selectSelectedIds,
  selectSorts
} from '@broadleaf/admin-components/dist/collection/hooks/useCollectionState';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import useInterval from '@broadleaf/admin-components/dist/common/hooks/useInterval';
import useRefreshEffect from '@broadleaf/admin-components/dist/common/hooks/useRefreshEffect';
import useTracking from '@broadleaf/admin-components/dist/tracking/hooks/useTracking';
import ColumnTypeRenderer from '@broadleaf/admin-components/dist/form/renderers/ColumnTypeRenderer';
import { ContextParameters } from '@broadleaf/admin-components/dist/tracking/utils/ContextRequest';
import { getDefaultSummaryHeaders } from '@broadleaf/admin-components/dist/view/site-updates/utils/SummaryGridUtils';
import { IFormFilter } from '@broadleaf/admin-components/dist/types/form';

/**
 * The structure of the {@link SummaryGridHook} object returned by this hook.
 *
 * @typedef {Object} SummaryGridHook
 * @property {CollectionStateHook} collectionState - the {@link CollectionStateHook} object
 * @property {Function} getActionListGridProps - used to get the props for an {@link ActionListGrid}
 */

/**
 * The structure of the {@link SummaryGridProps} object accepted as parameters to this hook.
 *
 * @typedef {Object} SummaryGridProps
 * @property {string} idKey - the key used to get a summaries ID
 * @property {Object} readConfig - the config used for read requests
 * @property {Object} readContextParams - the context params used for read requests
 * @property {boolean} [selectable=true] - whether or not this grid is selectable
 * @property {boolean} [selectableType=SelectableType.is.ALL] - the selectable type
 */

/**
 * The defaults for the {@link SummaryGridProps}.
 *
 * @type {SummaryGridProps}
 */
const defaultProps = {
  idKey: 'id',
  selectable: true,
  selectableType: SelectableType.is.ALL,
  excludeInvoiceStatuses: false,
  invoiceStatuses: []
};

/**
 * Hook used for providing state and utility to a summary grid.
 *
 * @param {SummaryGridProps}
 * @return {SummaryGridHook}
 */
export default function useInvoicesGrid(props) {
  props = { ...defaultProps, ...props };

  const tracking = useTracking();
  const contextParams = {
    [ContextParameters.TRACKING]: tracking
  };

  const implicitFilters = [
    {
      name: 'cq',
      value: "paymentTypes=in=('INVOICE_CREDIT','STORE_CREDIT')"
    } as IFormFilter
  ];
  if (props.invoiceStatuses.length) {
    implicitFilters.push({
      name: 'cq',
      value: `invoiceStatus=${
        props.excludeInvoiceStatuses ? 'out' : 'in'
      }=('${props.invoiceStatuses.join("','")}')`
    } as IFormFilter);
  }

  const defaultSort = props.defaultSort || {
    field: 'submitDate',
    direction: 'desc'
  };

  /**
   * The {@link CollectionStateHook} that is the primary provider of this grid's state.
   *
   * @type {CollectionStateHook}
   */
  const collectionState = useCollectionState({
    defaultSort,
    idKey: props.idKey,
    readConfig: props.readConfig,
    readContextParams: contextParams,
    selectable: props.selectable,
    selectableType: props.selectableType,
    implicitFilters: implicitFilters
  });
  const {
    handleClearAllSelections,
    handleFilterChange,
    handleFiltersChange,
    handlePageChange,
    handleRead,
    handleReinitialize,
    handleSelectVisible,
    handleSelectAll,
    handleSelectOne,
    handleSortChange,
    state
  } = collectionState;

  /**
   * This hook ensures that our results are refreshed when the tracking context changes.
   */
  useRefreshEffect(
    () => {
      handleReinitialize();
      handleRead();
    },
    { tracking },
    undefined,
    false
  );

  const currentFilters = selectFilters(state);
  const currentSorts = selectSorts(state);
  const currentPage = selectCurrentPage(state);
  const currentPaginateProps = selectPaginateProps(state, handlePageChange);
  const currentItems = selectCurrentItems(state);
  const isSelectAll = selectIsSelectAll(state);
  const isSelectNonVisible = selectIsSelectNonVisible(state);
  const isSelectVisible = selectIsSelectVisible(state);
  const selectedCount = selectSelectedCount(state);
  const selectedIds = selectSelectedIds(state);

  /**
   * This ensures that we refresh the grid on an interval when one of our summaries is in-progress
   *
   * @type {Object}
   */
  useInterval(
    () => {
      handleRead();
    },
    hasTransitioningSummary(currentItems) ? 3000 : null
  );

  const headers = useDefaultHeaders();
  const internalActionProps = {
    // the collection state is passed down in the event extensions need access to it
    collectionState,
    // context parameters used by actions for any requests they might need to make, e.g. LOOKUP-type filters
    contextParams,
    // we pass down the read handler in the event an action needs to read
    handleRead,
    handleReinitialize
  };

  function getActionListGridProps() {
    return {
      actionProps: internalActionProps,
      error: currentPage.isFailure ? currentPage.error : undefined,
      filters: currentFilters,
      headers: headers,
      isSelectAll: isSelectAll,
      isSelectNonVisible: isSelectNonVisible,
      isSelectVisible: isSelectVisible,
      loading: currentPage.isFetching,
      onClearAllSelections: handleClearAllSelections,
      onFilterChange: handleFilterChange,
      onFiltersChange: handleFiltersChange,
      onSelectVisible: handleSelectVisible,
      onSelectAll: handleSelectAll,
      onSelectRow: handleSelectOne,
      onSortChange: handleSortChange,
      paginateProps: currentPaginateProps,
      renderColumn: renderColumn,
      renderHeaderColumn: renderHeaderColumn,
      rowKey: props.idKey,
      rows: currentItems,
      selectable: props.selectable,
      selectableType: props.selectableType,
      selectedCount: selectedCount,
      selectedIds: selectedIds,
      sorts: currentSorts
    };
  }

  return {
    collectionState,
    getActionListGridProps
  };
}

function useDefaultHeaders() {
  const formatMessage = useFormatMessage();
  return getDefaultSummaryHeaders(formatMessage);
}

function renderColumn(columnData) {
  return <ColumnTypeRenderer {...columnData} />;
}

function renderHeaderColumn(headerData) {
  return <ColumnHeader {...headerData} />;
}

function hasTransitioningSummary(currentItems) {
  return some(currentItems, item => item.status.endsWith('ING'));
}
