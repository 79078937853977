/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import ReactDOM from 'react-dom';
import '@broadleaf/admin-style';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './loadLocaleData';
import './componentRegistration';
import './buildTimePropertyResolver';
import '../styles/index.css';
import '../styles/tooltip.css';
import '../styles/select.css';

ReactDOM.render(<App />, document.getElementById('root'));

// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
