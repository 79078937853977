/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

import { RequestUtils } from '@broadleaf/admin-components/dist/metadata';

export const useFetchBlcProduct = () => {
  const getProductByDeviceID = async (deviceIdentifier, contextParams) => {
    let response = null;
    try {
      response = await RequestUtils.get(
        {
          headers: {},
          mapParams: [],
          method: 'GET',
          params: { query: `${deviceIdentifier}` },
          scope: 'LIST_PRODUCT',
          type: 'HYDRATE',
          uri: '/catalog/list-product/search'
        },
        contextParams
      );
    } catch (e) {
      console.log(e);
    }

    if (!response || !response.data || response.data.numberOfElements < 1) {
      return null;
    }

    const singleProduct = response.data.content.filter(
      product =>
        product !== null &&
        (product.upc === deviceIdentifier ||
          product.attributes.deviceIds?.value?.includes(deviceIdentifier))
    );
    if (singleProduct.length >= 1) {
      return singleProduct[0];
    }
    return null;
  };

  return { getProductByDeviceID };
};
