/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  merchandiseTotal: {
    id: 'FulfillmentView.components.Details.merchandiseTotal',
    defaultMessage: 'Merchandise Total'
  },
  fulfillmentTotal: {
    id: 'FulfillmentView.components.Details.fulfillmentTotal',
    defaultMessage: 'Shipping Fees'
  },
  taxTotal: {
    id: 'FulfillmentView.components.Details.taxTotal',
    defaultMessage: 'Taxes'
  },
  grandTotal: {
    id: 'FulfillmentView.components.Details.grandTotal',
    defaultMessage: 'Grand Total'
  }
});
