import { defineMessages } from 'react-intl';
export default defineMessages({
  invoicesSubmittedSuccessfully: {
    id: 'SubmitInvoicesAction.toast.invoice.success',
    defaultMessage: 'The invoices submitted successfully!'
  },
  downloadExport: {
    id: 'SubmitInvoicesAction.toast.export.download',
    defaultMessage: 'Download the invoice file'
  },
  exportError: {
    id: 'SubmitInvoicesAction.toast.export.error',
    defaultMessage: 'The invoice submission failed'
  }
});
