/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';

import NavFilter from './NavFilter';
import NavModule from './NavModule';

const NavMenu = ({
  filter,
  modules,
  setFilter,
  menuCollapsed,
  setMenuOpen
}) => {
  return (
    <React.Fragment>
      {!menuCollapsed && <NavFilter filter={filter} setFilter={setFilter} />}
      <div className="tw-flex-1 tw-max-h-full tw-mb-4 tw-overflow-y-auto">
        {modules.map(module => (
          <NavModule
            key={module.id}
            {...module}
            filter={filter}
            setMenuOpen={setMenuOpen}
            menuCollapsed={menuCollapsed}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

NavMenu.propTypes = {
  /** The list of modules in the menu **/
  modules: PropTypes.array.isRequired,
  /** The function responsible for toggling the menu **/
  setMenuOpen: PropTypes.func.isRequired,
  /** The current filter query to limit section display **/
  filter: PropTypes.string.isRequired,
  /** The function responsible for setting the current filter **/
  setFilter: PropTypes.func.isRequired
};

export default NavMenu;
