/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import _memoize from 'lodash/memoize';
import _template from 'lodash/template';

/**
 * Compiles a string "template" into a function that can be executed against a context.
 *
 * For instance, say you had a uri template string, `/products/${parent.id}`:
 *
 * ```js
 * const compiled = compile('/products/${parent.id}');
 *
 * // returns "/products/1"
 * compiled({ parent: { id: 1 } });
 * ```
 *
 * Memoization is applied to this function as well, so the next time you compile
 * the same expression it will return you the cached version of the compilation.
 */
export const compile = _memoize(template => {
  try {
    const compiled = _template(template);
    return context => {
      try {
        return compiled(context);
      } catch (err) {
        throw new TemplateError(template, context, err.message);
      }
    };
  } catch (err) {
    throw new TemplateError(template, null, err.message);
  }
});

export class TemplateError extends Error {
  constructor(template, context, message, ...args) {
    super(
      `${message}\n\n` +
        `Template: "${template}"\n` +
        (context ? `Context: ${JSON.stringify(context, null, 2)}\n` : ''),
      ...args
    );

    // this must be done in order for this to properly work with Babel
    // see https://github.com/babel/babel/issues/3083#issuecomment-315569824
    this.constructor = TemplateError;
    this.__proto__ = TemplateError.prototype;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TemplateError);
    }

    this.template = template;
    this.context = context;
  }
}
