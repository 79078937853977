/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { map } from 'lodash';

import Dashboard from '../view/Dashboard';

import { ErrorType } from '@broadleaf/admin-components/dist/common';
import {
  ComponentRouterContext,
  ComponentRoute,
  ComponentRouteSplash
} from '@broadleaf/admin-components/dist/metadata';
import { useTenantContext } from '@broadleaf/admin-components/dist/tracking';
import { BaseLayout } from '@broadleaf/admin-components/dist/view';

const emotionCache = createCache({
  key: 'css',
  nonce: document.querySelector('meta[name="csp-nonce"]')?.['content'] || ''
});

export const AdminApp: React.FC<AdminAppProps> = ({ children }) => {
  const { routes } = useContext(ComponentRouterContext);
  const [{ applicationId, tenantId }] = useTenantContext();
  const key = `${tenantId}_${applicationId}`;
  return (
    <CacheProvider value={emotionCache}>
      <BaseLayout>
        <Switch key={key}>
          {children}
          <Route exact path="/" component={Dashboard} />
          {map(routes, renderComponentRoute)}
          <Route>
            <ComponentRouteSplash
              isActive={true}
              error={{ type: ErrorType.Symbols.NOT_FOUND }}
            />
          </Route>
        </Switch>
      </BaseLayout>
    </CacheProvider>
  );
};
export interface AdminAppProps {
  children?: React.ReactNode;
}

function renderComponentRoute(route, index) {
  const key = route.path || index;
  return <ComponentRoute key={key} {...route} />;
}

export default AdminApp;
