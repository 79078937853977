/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  labelProfile: {
    id: 'Content.Components.label.profile',
    defaultMessage: 'Select a Profile'
  },
  add: {
    id: 'Content.Components.add',
    defaultMessage: 'Add {label}'
  },
  new: {
    id: 'Content.Components.new',
    defaultMessage: 'New {modelName}'
  },
  submit: {
    id: 'ModalForm.submit',
    defaultMessage: 'Submit',
    description: 'The label for the modal submit button.'
  },
  chooseExisting: {
    id: 'Content.Components.chooseExisting',
    defaultMessage: 'Choose existing'
  }
});
