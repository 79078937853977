/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty, isNil, omit } from 'lodash';
import { Formik } from 'formik';

import setIn from '@broadleaf/admin-components/dist/common/utils/lodash/setIn';
import Button from '@broadleaf/admin-components/dist/common/elements/Button';
import Spinner from '@broadleaf/admin-components/dist/common/elements/Spinner';
import SubmissionError from '@broadleaf/admin-components/dist/common/components/SubmissionError';
import SimpleModal from '@broadleaf/admin-components/dist/common/components/SimpleModal';
import FormComponents from '@broadleaf/admin-components/dist/form/components/FormComponents';
import SchemaService from '@broadleaf/admin-components/dist/form/services/SchemaService';
import {
  setFormikErrors,
  clearFormikErrors,
  getFormikErrorMessages
} from '@broadleaf/admin-components/dist/form/utils/RequestErrorHelpers';
import withParentFormValues from '@broadleaf/admin-components/dist/form/helpers/withParentFormValues';

import messages from './ModalForm.messages';
import { IFormik } from '@broadleaf/admin-components/dist/types/form';

/**
 * This component is used for rendering a form within a modal. This is primarily
 * used when creating or editing items within a collection.
 *
 * @see See [ModalFormAction](#modalformaction) for an example of how this is used when rendering actions
 */
export const ModalForm: React.FC<ModalFormProps> = ({
  closeOnSubmit,
  closeOnClickOutside = false,
  components,
  initialValues,
  initialValidationSchema,
  isOpen,
  onClose,
  onSubmit,
  parentFormValues,
  renderFormComponents,
  renderFooter,
  title,
  ...footerProps
}) => {
  if (!isOpen) {
    return null;
  }

  // we build a validation schema for the current form
  let schema = SchemaService.generateSchema(components);

  if (!isNil(initialValidationSchema)) {
    schema = schema.concat(initialValidationSchema);
  }

  // cast the initial values thereby applying any default values
  initialValues = SchemaService.castSchema(initialValues, schema);

  /**
   * Inject the parent Formik values to this form as the "$parent" field.
   * This is useful when endpoint configurations need to access a grand-parent's
   * form values, e.g. `/products/${parent.$parent.id}/variants`.
   */
  initialValues = setIn(initialValues, '$parent', parentFormValues);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(formikValues, formikBag) => {
        // exclude the $parent since this is not actually part of the form state that is being submitted
        const values = omit(formikValues, ['$parent']);
        formikBag.setSubmitting(true);
        return onSubmit(values, formikBag)
          .then(() => {
            // if closeOnSubmit is enabled, the trigger the onClose
            if (closeOnSubmit) {
              onClose(null);
            } else {
              formikBag.setSubmitting(false);
              clearFormikErrors(formikBag);
            }
          })
          .catch(error => setFormikErrors(error, formikBag));
      }}
      validateOnChange={false}
      validate={values => SchemaService.runSchemaValidation(values, schema)}
    >
      {formik => {
        return (
          <SimpleModal
            /*// @ts-ignore */
            closeOnClickOutside={closeOnClickOutside}
            title={title}
            body={
              <Fragment>
                {renderFormComponents ? (
                  renderFormComponents({
                    components,
                    formik,
                    readOnly: formik.isSubmitting
                  })
                ) : (
                  <FormComponents
                    components={components}
                    formik={formik}
                    readOnly={formik.isSubmitting}
                  />
                )}
              </Fragment>
            }
            footer={
              renderFooter ? (
                renderFooter({ formik, ...footerProps })
              ) : (
                <ModalFooter formik={formik} {...footerProps} />
              )
            }
            isOpen={isOpen}
            onClose={onClose}
            size="lg"
            centerDialog={true}
          />
        );
      }}
    </Formik>
  );
};

ModalForm.defaultProps = {
  closeOnSubmit: true,
  submitColor: 'primary',
  submitLabel: <FormattedMessage {...messages.submit} />
};

export interface ModalFormProps {
  /** whether or not to trigger the onClose handle after a successful submit */
  closeOnSubmit: boolean;
  /** Whether or not this modal should close when we click outside. Defaults to false.*/
  closeOnClickOutside?: boolean;
  /** the set of fields, collections, or groups to be rendered */
  components: Array<object>;
  /** the initial form values */
  initialValues?: object;
  initialValidationSchema?: any;
  /** whether or not the modal is open */
  isOpen: boolean;
  /**
   * Triggers when the modal closes.
   */
  onClose?: React.MouseEventHandler<HTMLElement>;
  /**
   * Triggers when this modal's form is submitted. If `closeOnSubmit` is true,
   * this will trigger `#onClose`.
   *
   * @param values the values that were submitted
   * @param formikBag the bag of formik operations
   * @return a Promise that resolves when succesful
   */
  onSubmit: Function;
  /** if this modal form is rendered within a Formik, these are that form's values */
  parentFormValues?: object;
  /**
   * Override the render function to render the form differently. Useful for
   * inserting additional non-metadata-driven fields.
   */
  renderFormComponents?: Function;
  /**
   * The custom function to render the footer.
   * Executes with the {formik, tooltipPlacement, submitColor, submitLabel} properties.
   */
  renderFooter?: Function;
  /** the color class for the submit button **/
  submitColor?: string;
  /**
   * the label rendered for the submit action. Should be a
   * {@link FormattedMessage} or a pre-localized string.
   */
  submitLabel?: string | React.ReactNode | Element;
  /** Tooltip position with submit error e.g. "top", "bottom" */
  tooltipPlacement?: string;
  /**
   * the title that is rendered in the modal header. Should be a
   * {@link FormattedMessage} or a pre-localized string.
   */
  title: string | React.ReactNode | Element;
}

const ModalFooter: React.FC<ModalFooterProps> = ({
  formik,
  tooltipPlacement,
  submitColor,
  submitLabel
}) => {
  const SubmissionErrors = getFormikErrorMessages(formik);
  return (
    <Fragment>
      {!isEmpty(SubmissionErrors) && (
        <SubmissionError
          errors={SubmissionErrors}
          tooltipPlacement={tooltipPlacement}
        />
      )}
      {formik.isSubmitting && <Spinner size="sm" />}
      <Button
        color={submitColor}
        disabled={formik.isSubmitting}
        onClick={formik.submitForm}
        type="submit"
      >
        {submitLabel}
      </Button>
    </Fragment>
  );
};

export interface ModalFooterProps {
  formik: IFormik;
  /** the color class for the submit button **/
  submitColor?: string;
  /**
   * the label rendered for the submit action. Should be a
   * {@link FormattedMessage} or a pre-localized string.
   */
  submitLabel?: string | React.ReactNode | Element;
  /** Tooltip position with submit error e.g. "top", "bottom" */
  tooltipPlacement?: string;
}

export default withParentFormValues(ModalForm);
