/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import NavSectionToolTip from './NavSectionTooltip';

import { log } from '@broadleaf/admin-components/dist/common';
import SVG from '@broadleaf/admin-components/dist/common/components/SVG';
import { useHasMatchingRoute } from '@broadleaf/admin-components/dist/metadata';

const logger = log.getLogger('view.components.Navigation');

const NavSection = props => {
  const { label, url, icon, menuCollapsed, setMenuOpen } = props;
  const [isHovered, setIsHovered] = useState(false);
  const sectionRef = useRef(null);
  const hasAccess = useHasSectionAccess(props);

  if (!hasAccess) {
    return null;
  }

  return (
    <NavLink
      to={url}
      activeClassName="tw-bg-gray-800 tw-text-gray-100 tw-border-green-700"
      className="group tw-text-md tw-relative tw-block tw-border-r-4 tw-border-transparent tw-text-gray-400 tw-no-underline hover:tw-bg-gray-800 hover:tw-text-gray-100 lg:tw-text-sm"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => setMenuOpen(menuOpen => false)}
    >
      <div
        ref={sectionRef}
        className={cx(
          'tw-flex tw-cursor-pointer tw-items-center tw-px-4 tw-py-2',
          {
            'tw-justify-center': menuCollapsed
          }
        )}
      >
        <SVG
          name={icon}
          className="tw-inline-block tw-h-4 tw-w-4 tw-fill-current tw-text-gray-600 group-hover:tw-text-gray-100"
        />
        {!menuCollapsed && (
          <div className="tw-whitespace-no-wrap tw-ml-4 tw-overflow-hidden">
            {label}
          </div>
        )}
      </div>
      {menuCollapsed && isHovered && (
        <NavSectionToolTip parent={sectionRef}>{label}</NavSectionToolTip>
      )}
    </NavLink>
  );
};

NavSection.defaultProps = {
  icon: 'question'
};

NavSection.propTypes = {
  /** The name of the admin section **/
  label: PropTypes.string.isRequired,
  /** The name of the icon for this section **/
  icon: PropTypes.string,
  /** The url for the admin section **/
  url: PropTypes.string.isRequired,
  /** Whether the navigation is currently collapsed **/
  menuCollapsed: PropTypes.bool
};

function useHasSectionAccess({ label, url }) {
  const hasAccess = useHasMatchingRoute(url);

  useEffect(() => {
    logger.debug(
      `Excluding the menu section ${label}. Either there is no matching route, or the user lacks access to the route.`
    );
  }, [hasAccess, label]);

  return hasAccess;
}

export default NavSection;
