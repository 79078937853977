/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

import React from 'react';
import { get } from 'lodash';

/**
 * Component to display product into from the backend.
 * Wrapped inside of a higher-order component that obtains the data.
 *
 * @param {bool} error          Whether or not the obtaining the data caused an error
 * @param {bool} isLoaded       Whether or not the data has finished loading
 * @param {Object} productInfo  The product data for the component to render
 * @returns {ReactComponent}
 */
const ProductInfoDisplay = props => {
  const { error, isLoaded, productInfo } = props;

  if (error) {
    return <p>Error fetching product details!</p>;
  }

  if (!isLoaded) {
    return <p>Loading...</p>;
  }
  const weight = get(productInfo, 'weight.weight');
  const dimension = get(productInfo, 'dimension');
  return (
    <div>
      <div className="tw-relative tw-flex tw-flex-wrap tw-items-baseline sm:tw-flex-no-wrap sm:tw-no-space">
        <div className="tw-relative tw-w-1/2 md:tw-w-1/4 mb-3">
          <span className="control-label d-block font-bold">Name</span>
          <span>{get(productInfo, 'name', 'N/A')}</span>
        </div>
        <div className="tw-relative tw-w-1/2 md:tw-w-1/4 mb-3">
          <span className="control-label d-block font-bold">Company Name</span>
          <span>{get(productInfo, 'companyName', 'N/A')}</span>
        </div>
        <div className="tw-relative tw-w-1/2 md:tw-w-1/4 mb-3">
          <span className="control-label d-block font-bold">
            Catalog Number
          </span>
          <span>{get(productInfo, 'catalogNumber')}</span>
        </div>
        <div className="tw-relative tw-w-1/2 md:tw-w-1/4 mb-3">
          <span className="control-label d-block font-bold">
            Primary Device ID
          </span>
          <span>{get(productInfo, 'upc')}</span>
        </div>
        {get(productInfo, 'attributes.containsDINumber.value') && (
          <div className="tw-relative tw-w-1/2 md:tw-w-1/4 mb-3">
            <span className="control-label d-block font-bold">
              Contains Device ID
            </span>
            <span>{get(productInfo, 'attributes.containsDINumber.value')}</span>
          </div>
        )}
        {weight && (
          <div className="tw-relative tw-w-1/2 md:tw-w-1/4 mb-3">
            <span className="control-label d-block font-bold">
              Device Weight
            </span>
            <span>
              {' '}
              {weight} {get(productInfo, 'weight.units')}
            </span>
          </div>
        )}
        {dimension?.width && (
          <div className="tw-relative tw-w-1/2 md:tw-w-1/4 mb-3">
            <span className="control-label d-block font-bold">
              Device Dimensions
            </span>
            <span>
              {dimension?.width} x {dimension?.height} x {dimension?.depth}{' '}
              {get(productInfo, 'dimension.units')}
            </span>
          </div>
        )}
        <div className="tw-relative tw-w-full md:tw-w-1/2 mb-3">
          <span className="control-label d-block font-bold">Description</span>
          <span>{get(productInfo, 'description', 'N/A')}</span>
        </div>
      </div>
    </div>
  );
};

export default ProductInfoDisplay;
