/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

const rowActions = defineMessages({
  rebase: {
    id: 'OtherInvoicesGrid.rowAction.rebase',
    defaultMessage: 'Rebase',
    description: ''
  }
});

const headersMain = defineMessages({
  submitDate: {
    id: 'invoices.columns.submit-date',
    defaultMessage: 'Submit Date',
    description: ''
  },
  invoiceStatus: {
    id: 'invoices.columns.invoice-status',
    defaultMessage: 'Invoice Status',
    description: ''
  },
  invoiceStatusReason: {
    id: 'invoices.columns.invoice-status-reason',
    defaultMessage: 'Invoice Status Reason',
    description: ''
  },
  invoiceDate: {
    id: 'invoices.columns.invoice-date',
    defaultMessage: 'Invoice Date',
    description: ''
  },
  orderNumber: {
    id: 'invoices.columns.order-number',
    defaultMessage: 'Order Number',
    description: ''
  },
  invoiceNumber: {
    id: 'invoices.columns.invoice-number',
    defaultMessage: 'Invoice Number',
    description: ''
  },
  poNumber: {
    id: 'invoices.columns.po-number',
    defaultMessage: 'PO Number',
    description: ''
  },
  shipToOrg: {
    id: 'invoices.columns.ship-to-org',
    defaultMessage: 'Ship To Org',
    description: ''
  },
  invoiceTotal: {
    id: 'invoices.columns.invoice-total',
    defaultMessage: 'Invoice Total',
    description: ''
  },
  revMedCreditAmount: {
    id: 'invoices.columns.revmed-credit-amount',
    defaultMessage: 'RevMed Credit Amount',
    description: ''
  }
});

const changeTypes = defineMessages({
  create: {
    id: 'ReadyToSubmitGrid.headers.changeTypes.create',
    defaultMessage: 'Create',
    description: ''
  },
  update: {
    id: 'ReadyToSubmitGrid.headers.changeTypes.update',
    defaultMessage: 'Update',
    description: ''
  },
  delete: {
    id: 'ReadyToSubmitGrid.headers.changeTypes.delete',
    defaultMessage: 'Delete',
    description: ''
  }
});

const headers = {
  changeTypes,
  ...headersMain
};

const gridActions = defineMessages({
  submit: {
    id: 'ReadyToSubmitGrid.gridActions.submit',
    defaultMessage: 'Submit',
    description: ''
  },
  viewDetails: {
    id: 'ReadyToSubmitGrid.gridActions.viewDetails',
    defaultMessage: 'View Details',
    description: ''
  },
  criteriaQuery: {
    id: 'ReadyToSubmitGrid.gridActions.criteriaQuery',
    defaultMessage: 'Filter',
    description: ''
  }
});

const mainMessages = defineMessages({
  label: {
    id: 'ReadyToSubmitGrid.label',
    defaultMessage: `Ready to Submit`,
    description: ''
  }
});

export default {
  gridActions,
  headers,
  rowActions,
  ...mainMessages
};
