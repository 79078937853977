/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import betweenWith from '@broadleaf/admin-components/dist/common/utils/lodash/betweenWith';
import FulfillmentItemSummary from '../FulfillmentItemSummary';
import { IFulfillmentViewCommon } from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/fulfillment';

export const Items: React.FC<ItemsProps> = ({ className, state }) => {
  if (!state.data) return null;
  return (
    <div className={className}>
      {betweenWith(
        state.data.fulfillmentItems.map(fulfillmentItem => (
          <FulfillmentItemSummary
            key={fulfillmentItem.id}
            className={className}
            fulfillmentItem={fulfillmentItem}
          />
        )),
        (_, ___, index) => (
          <hr key={`hr-${index}`} className="tw-my-1 tw-border-gray-300" />
        )
      )}
    </div>
  );
};

export interface ItemsProps extends IFulfillmentViewCommon {
  className?: string;
}

export default Items;
