/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import useEventCallback from '@broadleaf/admin-components/dist/common/hooks/useEventCallback';
import IconButton from '@broadleaf/admin-components/dist/common/components/IconButton';


const ResidentMapFieldDelete: React.FC<ResidentMapFieldDeleteProps> = ({
  actionDefinition,
  disabled,
  formik,
  entryKey,
  metadata
}) => {
  const handleClick = useEventCallback(() => {
    const entryPath = `${metadata.name}.${entryKey}`;
    formik.setFieldValue(entryPath, undefined);
  }, [entryKey, formik.setFieldValue, metadata.name]);
  return (
    <IconButton
      key={actionDefinition.type}
      className="ml-1"
      color="danger-clear"
      disabled={disabled}
      label={actionDefinition.label}
      name="trash-alt"
      onClick={handleClick}
      type="button"
    />
  );
};

export interface ResidentMapFieldDeleteProps extends IEntryActionProps {}

export default ResidentMapFieldDelete;
