/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import { map } from 'lodash';

import NavSection from './NavSection';

import { log } from '@broadleaf/admin-components/dist/common';
import useHasMatchingRoute from '@broadleaf/admin-components/dist/metadata/hooks/useHasMatchingRoute';
import { useNavigationCache } from '@broadleaf/admin-components/dist/view';
import SVG from '@broadleaf/admin-components/dist/common/components/SVG';

const logger = log.getLogger('view.components.Navigation');

const NavModule = props => {
  const { label, submenu, filter, menuCollapsed, setMenuOpen } = props;
  const navCache = useNavigationCache();

  const [isCollapsed, setIsCollapsed] = useState(navCache.get(label));
  useEffect(() => {
    navCache.set(label, isCollapsed);
  }, [label, isCollapsed, navCache]);

  const hasAccess = useHasModuleAccess(props);
  const filteredSections = submenu.filter(section =>
    section.label.toLowerCase().includes(filter.toLowerCase())
  );
  if (!hasAccess || !filteredSections.length) {
    return null;
  }

  return (
    <div className="tw-mb-6">
      {!menuCollapsed && (
        <div
          className="tw-whitespace-no-wrap tw-group tw-mb-1 tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-overflow-hidden tw-px-4 tw-text-sm tw-font-semibold tw-uppercase tw-tracking-wide tw-text-gray-600"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {label}
          {!filter && (
            <SVG
              name={isCollapsed ? 'cheveron-up' : 'cheveron-down'}
              className="tw-h-4 tw-w-4 tw-fill-current tw-text-gray-700 group-hover:tw-text-gray-600"
            />
          )}
        </div>
      )}

      {(filter || menuCollapsed) &&
        filteredSections.map(section => (
          <NavSection
            key={section.id}
            {...section}
            setMenuOpen={setMenuOpen}
            menuCollapsed={menuCollapsed}
          />
        ))}
      {!filter && !menuCollapsed && (
        <AnimateHeight height={isCollapsed ? 0 : 'auto'} duration={500}>
          {filteredSections.map(section => (
            <NavSection
              key={section.id}
              {...section}
              setMenuOpen={setMenuOpen}
              menuCollapsed={menuCollapsed}
            />
          ))}
        </AnimateHeight>
      )}
    </div>
  );
};

NavModule.propTypes = {
  /** The modules name **/
  label: PropTypes.string.isRequired,
  /** The list of sections contained in this module **/
  submenu: PropTypes.arrayOf(
    PropTypes.shape({
      /** The name of the admin section **/
      label: PropTypes.string.isRequired,
      /** The url for the admin section **/
      url: PropTypes.string.isRequired
    })
  ),
  /** The current filter query to limit section display **/
  filter: PropTypes.string,
  /** Whether the navigation is currently collapsed **/
  menuCollapsed: PropTypes.bool
};

function useHasModuleAccess({ label, submenu }) {
  const paths = map(submenu, 'url');
  const hasAccess = useHasMatchingRoute(paths);

  useEffect(() => {
    logger.debug(
      `Excluding the menu module ${label}. Either there are no matching routes within, or the user lacks access to the routes.`
    );
  }, [hasAccess, label]);

  return hasAccess;
}

export default NavModule;
