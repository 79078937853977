/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

const rowActions = defineMessages({
  rebase: {
    id: 'HeldInvoicesGrid.rowAction.rebase',
    defaultMessage: 'Rebase',
    description: ''
  }
});

const headersMain = defineMessages({});

const changeTypes = defineMessages({
  create: {
    id: 'HeldInvoicesGrid.headers.changeTypes.create',
    defaultMessage: 'Create',
    description: ''
  },
  update: {
    id: 'HeldInvoicesGrid.headers.changeTypes.update',
    defaultMessage: 'Update',
    description: ''
  },
  delete: {
    id: 'HeldInvoicesGrid.headers.changeTypes.delete',
    defaultMessage: 'Delete',
    description: ''
  }
});

const headers = {
  changeTypes,
  ...headersMain
};

const gridActions = defineMessages({
  submit: {
    id: 'HeldInvoicesGrid.gridActions.submit',
    defaultMessage: 'Submit',
    description: ''
  },
  viewDetails: {
    id: 'HeldInvoicesGrid.gridActions.viewDetails',
    defaultMessage: 'View Details',
    description: ''
  },
  criteriaQuery: {
    id: 'HeldInvoicesGrid.gridActions.criteriaQuery',
    defaultMessage: 'Filter',
    description: ''
  }
});

const mainMessages = defineMessages({
  label: {
    id: 'HeldInvoicesGrid.label',
    defaultMessage: `Ready to Submit`,
    description: ''
  }
});

export default {
  gridActions,
  headers,
  rowActions,
  ...mainMessages
};
