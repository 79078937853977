/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useEffect, useRef, useState } from 'react';
import { findIndex } from 'lodash';
import cx from 'classnames';

import { RMToggleSwitchLabel } from './RMToggleSwitchLabel';
import { ToggleSwitchInput } from './ToggleSwitchInput';
import { ToggleSwitchItem } from './ToggleSwitchItem';

/**
 * This is the same component as ToggleSwitch provided in AdminWeb, just without
 * rounded corners
 */
export const RMToggleSwitch: React.FC<ToggleSwitchProps> = props => {
  const { disabled, options, name, onBlur, className, title, value } = props;
  const [{ selectedIndex }, setState] = useState({
    // Current index of mapped label value
    selectedIndex: 1
  });
  const firstRender = useRef(true);

  useEffect(() => {
    const index = findIndex(options, { value: value });
    if (index !== -1 && index !== selectedIndex) {
      setState({
        selectedIndex: index
      });
    }
  }, [options, value, selectedIndex]);

  const handleKeyPress = e => {
    if (disabled) return false;
    // Empty space maps to SPACE_BAR key.
    // This provides a very accessible shortcut for keyboards.
    if (e.key === ' ') addOneToSelectedIndex();
  };

  const handleOptionClick = index => {
    // Only handle when index has truly changed.
    if (disabled) return false;

    if (index !== selectedIndex) {
      setState({
        selectedIndex: index
      });

      // Ensure that this was passed in
      if (props.onChange) {
        // Setting the specific value for Formik
        props.onChange(props.options[index].value);
      }

      if (props.onChangeOption) {
        props.onChangeOption(props.options[index]);
      }
    }
  };

  const handleOnBlur: React.FocusEventHandler<HTMLDivElement> = e => {
    if (disabled || !onBlur) return false;

    onBlur({
      ...e,
      target: {
        ...e.target,
        type: 'radio',
        value: options[selectedIndex].value,
        name
      }
    });
  };

  const addOneToSelectedIndex = () => {
    // Length starts at 1, index starts at 0... minus one!
    const adjustedLengthForIndex = options.length - 1;
    // Want to set to the first radio regardless
    let indexToSetTo = 0;
    // Ensure that the currently selected index is less than the total possible.
    if (selectedIndex < adjustedLengthForIndex)
      // Go to the next index
      indexToSetTo = selectedIndex + 1;

    handleOptionClick(indexToSetTo);
  };

  const renderOptions = (options: Array<Option>) => {
    return options.map((option: Option, index: number) => {
      // Give each of the radio inputs a unique ID attribute for the page.
      // Maps to a sibling label element that controls it. This will help
      // when there's more than one <ToggleSwitch> on the page.
      const id = `toggleSwitch-${name}-${option.value}`;
      const selected = index === selectedIndex;
      return (
        <ToggleSwitchItem
          key={index}
          handleKeyPress={e => {
            handleKeyPress(e);
          }}
          index={index}
        >
          <ToggleSwitchInput
            disabled={disabled}
            handleBlur={e => {
              if (onBlur) onBlur(e);
            }}
            id={id}
            name={name}
            option={option}
            selected={selected}
          />
          <RMToggleSwitchLabel
            disabled={disabled}
            handleClick={e => {
              // Ensure that the event is NOT being passed up through callback
              e.preventDefault();
              handleOptionClick(index);
            }}
            id={id}
            option={option}
            selected={selected}
          />
        </ToggleSwitchItem>
      );
    });
  };

  /**
   * This effect triggers the mintCondition toggle to handle a blur event (which
   * triggers a revalidation) every time a different option is selected
   */
  useEffect(
    () => {
      // Only trigger for mintCondition toggle since this seems to break logic for
      // toggles that control conditionally rendered fields, like expiration
      if (name !== 'mintCondition') {
        return;

      // Don't make an update when first rendered to prevent error from being
      // displayed initially
      } else if (firstRender.current) {
        firstRender.current = false;

      // Simulate a blur since that is what triggers validation
      } else {
        handleOnBlur({target: null} as React.FocusEvent<HTMLDivElement>)
      }
    }
  , [selectedIndex]);

  return (
    <div
      className={cx(
        'tw-relative tw-inline-flex tw-items-center tw-p-1 tw-text-white tw-whitespace-nowrap tw-text-sm tw-tracking-tight tw-border tw-border-gray-300 tw-uppercase',
        className
      )}
      onBlur={handleOnBlur}
      title={title}
    >
      <div className="tw-absolute tw-inset-0 tw-m-1 tw-bg-gray-300" />
      {renderOptions(options)}
    </div>
  );
};

interface ToggleSwitchProps {
  title?: string;
  className?: string;
  name?: string;
  disabled?: boolean;
  options?: Array<Option>;
  onBlur?: Function;
  onChange?: (value: any) => void;
  value?: any;
  onChangeOption?: (option: Option) => void;
}

export type Option = {
  color?: string;
  /**
   * The label to display for an option. Should be a
   * {@link FormattedMessage} or a pre-localized string.
   */
  label: string | React.ReactNode | Element;
  value: any;
};

export default RMToggleSwitch;
