/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  changePassword: {
    id: 'Header.changePassword',
    defaultMessage: 'Change Password',
    description: 'The label for the Change Password dropdown action.'
  },
  logout: {
    id: 'Header.logout',
    defaultMessage: 'Log Out',
    description: 'The label for the Logout dropdown action.'
  },
  timeZoneTitle: {
    id: 'Header.timeZoneTitle',
    defaultMessage:
      "The user's time zone. Can be changed using 'REACT_APP_DEFAULT_TIME_ZONE'.",
    description:
      'The message for the "title" attribute of the Time Zone in the header.'
  }
});
