/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import clone from 'lodash/clone';
import setWith from 'lodash/setWith';

/**
 * Immutable helper method that sets the value at the path and returns a new object.
 * @param {Object} state the state to update
 * @param {String} path  the path to set the value
 * @param {Any}    value the value to set
 */
export default function setIn(state, path, value) {
  return setWith(clone(state), path, value, clone);
}
