/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

const NavSectionToolTip = props => {
  const { parent, children } = props;
  const [position, setPosition] = useState({});
  const [show, setShow] = useState(false);
  const pNode = parent.current.getBoundingClientRect();

  const scrollY =
    window.scrollY !== undefined ? window.scrollY : window.pageYOffset;
  const scrollX =
    window.scrollX !== undefined ? window.scrollX : window.pageXOffset;
  const offsetX = props.offsetX || 16;
  const offsetY = props.offsetY || 16;

  const top = scrollY + pNode.top + pNode.height / 2 - offsetY;
  const left = scrollX + pNode.left + pNode.width + offsetX;

  useEffect(() => {
    setPosition({ top, left });
    setShow(false);

    const timeout = setTimeout(() => {
      setShow(true);
    });

    return () => {
      clearTimeout(timeout);
    };
  }, [top, left]);

  if (!position.left) {
    return null;
  }

  return createPortal(
    <div
      className="tw-absolute tw-p-2 tw-bg-gray-900 tw-text-xs tw-text-gray-400 tw-whitespace-no-wrap tw-rounded tw-z-20 tw-transition-all tw-transition-faster"
      style={{
        top: position.top,
        left: show ? position.left : position.left - 8,
        opacity: show ? 1 : 0.5
      }}
    >
      {children}
    </div>,
    document.body
  );
};

NavSectionToolTip.propTypes = {
  /** The parent NavSection for this tooltip **/
  parent: PropTypes.object,
  offset: PropTypes.number
};

export default NavSectionToolTip;
