/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { UserAccess } from '@broadleaf/admin-components/dist/authentication';
import {
  selectAllSelectedIds,
  selectAllSelectedItems
} from '@broadleaf/admin-components/dist/collection/hooks/useCollectionState';
import Button from '@broadleaf/admin-components/dist/common/elements/Button';
import useActionCreators from '@broadleaf/admin-components/dist/common/hooks/useActionCreators';
import { startPromote } from '@broadleaf/admin-components/dist/tracking/actions/sandboxOperation';
import SandboxOperationDispatchContext from '@broadleaf/admin-components/dist/tracking/contexts/SandboxOperationDispatchContext';
import {
  IMetadata,
  IMetadataAction
} from '@broadleaf/admin-components/dist/types/metadata';
import type { ICollectionStateHook } from '@broadleaf/admin-components/dist/types/collection';
import log from '@broadleaf/admin-components/dist/common/utils/log';
import { request } from '@broadleaf/admin-components/dist/metadata/utils/request';
import { ITracking } from '@broadleaf/admin-components/dist/types/tracking';
import { IOrder } from '@broadleaf/admin-components/dist/types/oms';
import { ContextParameters } from '@broadleaf/admin-components/dist/tracking/utils/ContextRequest';
import useTracking from '@broadleaf/admin-components/dist/tracking/hooks/useTracking';
import { getSubmitInvoicesEndpoint } from '../../../utils/InvoicesOperationUtils';
import { ToastContainerContext } from '@broadleaf/admin-components/dist/common';
import { DownloadLink } from '@broadleaf/admin-components/dist/form';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import { get } from 'lodash';
import messages from './SubmitInvoicesAction.messages';
import InvoiceDetailsModal from '../../InvoiceDetailsModal';
import SubmitInvoicesModal from '../../SubmitInvoicesModal';

const logger = log.getLogger(
  'oms.components.InvoicesView.ReadyToSubmitGrid.action-components.SubmitInvoicesAction'
);

export const SubmitInvoicesAction: React.FC<
  SubmitInvoicesActionProps
> = props => {
  const { actionDefinition, dispatch, metadata } = props;
  const formatMessage = useFormatMessage();
  const checkAccess = UserAccess.useCheckAccess();
  const actions = useActionCreators(SandboxOperationDispatchContext, {
    startPromote
  });

  const collectionState = props.collectionState as ICollectionStateHook;
  const allSelectedItems = useMemo(() => {
    return selectAllSelectedItems(props.collectionState.state);
  }, [props.collectionState.state]);
  const disabled = !props.isSelectAll && allSelectedItems.length === 0;
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const contextParams = useContextParams(metadata);
  const endpoint = getSubmitInvoicesEndpoint();
  const toast = useContext(ToastContainerContext);

  const submitInvoices = useCallback(async () => {
    setLoading(true);
    try {
      const req = {
        invoiceIds: allSelectedItems.map(item => item.id),
        includePreviouslyExported: true
      };
      const { data: exportData } = await request(
        { method: 'post', data: req, ...endpoint },
        contextParams
      );
      if (exportData.status === 'FINISHED') {
        const downloadExportUri = `/api/order/exports/orders/${exportData.id}/download`;

        toast.addToast(
          <>
            <div>{formatMessage(messages.invoicesSubmittedSuccessfully)}</div>
            <DownloadLink
              href={downloadExportUri}
              scope={'ORDER'}
              label={formatMessage(messages.downloadExport)}
            />
          </>,
          {
            type: 'success'
          }
        );
      } else if (exportData.status === 'ERROR') {
        const error = get(
          exportData,
          'errorMessage',
          formatMessage(messages.exportError)
        );
        toast.addToast(error, {
          type: 'error'
        });
      }
      setTimeout(() => {
        collectionState.handleRead();
        collectionState.handleClearAllSelections();
      }, 500);
    } catch (err) {
      if (err.cancelled) {
        return;
      }
      const errorMessage = `An error occurred while submitting invoices: ${err.message}`;
      logger.error(errorMessage);
      toast.addToast(errorMessage, {
        autoClose: 5000,
        closeOnClick: true,
        type: 'error'
      });
    } finally {
      setLoading(false);
      setIsOpen(false);
    }
  }, [
    allSelectedItems,
    endpoint,
    contextParams,
    collectionState,
    toast,
    formatMessage
  ]);

  function handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
  }
  if (!checkAccess('INVOICE', 'UPDATE')) {
    // must have access to submit
    return null;
  }

  return (
    <>
      <Button
        className="ml-2"
        color="primary"
        disabled={disabled || loading}
        onClick={handleClick}
        size="sm"
        type="button"
      >
        {props.actionDefinition.label}
      </Button>
      {isOpen && allSelectedItems && (
        <SubmitInvoicesModal
          allSelectedItems={allSelectedItems}
          submitInvoices={submitInvoices}
          isSubmitting={loading}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      )}
    </>
  );
};

function useContextParams(metadata: IMetadata): IInvoiceContextParams {
  const { id } = useParams() as Record<string, any>;
  const tracking = useTracking(metadata);
  return useMemo(() => {
    const contextParams = {} as IInvoiceContextParams;
    if (id) {
      contextParams.id = id;
    }

    if (tracking) {
      contextParams[ContextParameters.TRACKING] = tracking;
    }
    return contextParams;
  }, [id, tracking]);
}

export interface IInvoiceContextParams {
  tracking: ITracking;
  id?: string;
  parent?: IOrder;
  order?: IOrder;
  orderId?: string;
}

export interface SubmitInvoicesActionProps {
  actionDefinition?: IMetadataAction;
  collectionState?: ICollectionStateHook;
  isSelectAll?: boolean;
  dispatch: Function;
  metadata: IMetadata;
}

export default SubmitInvoicesAction;
