/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useEffect, useState } from 'react';
import { includes } from 'lodash';

import useEventCallback from '@broadleaf/admin-components/dist/common/hooks/useEventCallback';
import { getEndpointByType } from '@broadleaf/admin-components/dist/metadata/utils/MetadataUtils';
import { request } from '@broadleaf/admin-components/dist/metadata/utils/request';
import FulfillmentAction from '@broadleaf/admin-components/dist/oms/components/FulfillmentAction';
import useContextParams from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/hooks/useContextParams';
import { queueFetch } from '@broadleaf/admin-components/dist/oms/hooks/useFufillmentState/state/fulfillmentActions';
import {
  IMetadata,
  IMetadataAction
} from '@broadleaf/admin-components/dist/types/metadata';
import { IFulfillmentViewLocalState } from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/fulfillment';
import UpdateFulfillmentCommissionsModal from '../../UpdateFulfillmentCommissionsModal';
import log from '@broadleaf/admin-components/dist/common/utils/log';
import { IOrderFulfillmentCommissions } from '../../../utils/RmFulfillmentUtils';

const logger = log.getLogger(
  'oms.components.FulfillmentView.action-components.UpdateFulfillmentCommissions'
);

export interface StatusChangeProps {
  actionDefinition: IMetadataAction;
  dispatch: Function;
  metadata: IMetadata;
  state: IFulfillmentViewLocalState;
}

const UpdateFulfillmentCommissions: React.FC<StatusChangeProps> = ({
  actionDefinition,
  dispatch,
  metadata,
  state
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const contextParams = useContextParams(metadata, state);
  const { attributes = {}, label } = actionDefinition;
  const {
    allowedStatuses = [],
    fulfillmentTypeAllowedStatuses = {},
    fulfillmentType = ''
  } = attributes as Record<string, any>;
  const handleClose = () => setIsOpen(false);
  const [commissionsLoaded, setCommissionsLoaded] = useState<boolean>(false);
  const handleSubmit = useHandleSubmit({
    actionDefinition,
    dispatch,
    metadata,
    state,
    setIsSubmitting,
    setCommissionsLoaded
  });
  const readEndpoint = getEndpointByType(
    actionDefinition,
    'READ_FULFILLMENT_COMMISSIONS'
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [commissions, setCommissions] = useState<IOrderFulfillmentCommissions>(
    null
  );
  const allAllowedStatuses = allowedStatuses.concat(
    fulfillmentTypeAllowedStatuses[state.data.type]
  );
  useEffect(() => {
    if (loading || commissionsLoaded || state.isFetching) {
      return;
    }
    refreshCommissions(
      setLoading,
      setCommissions,
      setCommissionsLoaded,
      readEndpoint,
      state,
      contextParams
    )();
  }, [contextParams, readEndpoint, loading, state.data, state]);

  if (
    !includes(allAllowedStatuses, state.data.status) ||
    (fulfillmentType !== '' && fulfillmentType !== state.data.type)
  ) {
    return null;
  }

  return (
    <>
      <FulfillmentAction
        color="green"
        disabled={
          state.isFetching || loading || !commissionsLoaded || !commissions
        }
        onClick={() => setIsOpen(true)}
      >
        {label}
      </FulfillmentAction>

      {isOpen && (
        <UpdateFulfillmentCommissionsModal
          metadata={actionDefinition}
          fulfillment={state.data}
          fulfillmentCommissions={commissions}
          onClose={handleClose}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          title={label}
        />
      )}
    </>
  );
};

function refreshCommissions(
  setLoading,
  setCommissions,
  setCommissionsLoaded,
  readEndpoint,
  state,
  contextParams
) {
  return async () => {
    try {
      setLoading(true);
      console.log('Loading commissions');
      const { data } = await request(
        { method: 'get', ...readEndpoint, data: state.data },
        contextParams
      );
      console.log(data);
      setCommissions(data);
    } catch (err) {
      if (err.cancelled) {
        return;
      }
      console.log(
        `An error occurred trying to read the commissions for fulfillment ${contextParams.id}: ${err}`
      );
      logger.error(
        `An error occurred trying to read the commissions for fulfillment ${contextParams.id}: ${err}`
      );
    } finally {
      setLoading(false);
      setCommissionsLoaded(true);
    }
  };
}

function useHandleSubmit({
  actionDefinition,
  dispatch,
  metadata,
  state,
  setIsSubmitting,
  setCommissionsLoaded
}) {
  const contextParams = useContextParams(metadata, state);
  const submitEndpoint = getEndpointByType(
    actionDefinition,
    'UPDATE_FULFILLMENT_COMMISSIONS'
  );
  return useEventCallback(
    async values => {
      setIsSubmitting(true);
      const data = {
        ...values,
        reason: values.reason?.value
      };
      try {
        await request(
          {
            method: 'post',
            ...submitEndpoint,
            data
          },
          contextParams
        );
      } finally {
        setIsSubmitting(false);
        // We need to refetch the commissions after an update
        setCommissionsLoaded(false);
        dispatch(queueFetch());
      }
    },
    [submitEndpoint, contextParams, setIsSubmitting]
  );
}

export default UpdateFulfillmentCommissions;
