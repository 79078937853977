/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  itemUnitPrice: {
    id: 'FulfillmentItemSummary.itemUnitPrice',
    defaultMessage: 'Unit Price'
  },
  itemSubtotal: {
    id: 'FulfillmentItemSummary.itemSubtotal',
    defaultMessage: 'Subtotal'
  },
  itemAdjustmentsTotal: {
    id: 'FulfillmentItemSummary.itemAdjustmentsTotal',
    defaultMessage: 'Discounts'
  },
  itemTaxTotal: {
    id: 'FulfillmentItemSummary.itemTaxTotal',
    defaultMessage: 'Taxes'
  },
  itemTotal: {
    id: 'FulfillmentItemSummary.itemTotal',
    defaultMessage: 'Item Total'
  },
  promotionsApplied: {
    id: 'FulfillmentItemSummary.promotionsApplied',
    defaultMessage: 'Promotions Applied'
  }
});
