/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { FC } from 'react';
import { FormattedNumber } from 'react-intl';
import { get } from 'lodash';
import { ICommonGridColumn } from '@broadleaf/admin-components/dist/types/common';

export const BulkScanSummaryColumn: FC<BulkScanSummaryColumnProps> = ({
  header,
  row
}) => {
  if (header.name === 'product.catalogNumber') {
    return (
      <div>
        {get(row, 'product.companyName')}
        <br />
        <span className="tw-whitespace-nowrap">
          {get(row, 'product.catalogNumber')}
        </span>
      </div>
    );
  }
  return (
    <div>
      {get(row, 'lotNumber') && (
        <>
          Lot Number:{' '}
          <span className="tw-whitespace-nowrap">{get(row, 'lotNumber')}</span>
          <br />
        </>
      )}
      {get(row, 'serialNumber') && (
        <>
          Serial Number:{' '}
          <span className="tw-whitespace-nowrap">
            {get(row, 'serialNumber')}
          </span>
          <br />
        </>
      )}
      {get(row, 'expirationDate') && (
        <>
          Expiration Date:{' '}
          <span className="tw-whitespace-nowrap">
            {get(row, 'expirationDate')}
          </span>
          <br />
        </>
      )}
      {get(row, 'skuInventory.quantityOnHand') > 0 && (
        <>
          Quantity: {get(row, 'skuInventory.quantityOnHand')}
          <br />
        </>
      )}
      {get(row, 'unitOfMeasurement') && (
        <>
          UOM:{' '}
          <span className="tw-whitespace-nowrap">
            {get(row, 'unitOfMeasurement')}
          </span>
          <br />
        </>
      )}
      {get(row, 'listPrice') > 0 && (
        <>
          Listing Price:{' '}
          <FormattedNumber
            currency="USD"
            value={get(row, 'listPrice')}
            style="currency"
          />
          <br />
        </>
      )}
    </div>
  );
};

export interface BulkScanSummaryColumnProps extends ICommonGridColumn {}

export default BulkScanSummaryColumn;
