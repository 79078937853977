/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useMemo, useState, isValidElement } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  find,
  get,
  differenceWith,
  isArray,
  isEmpty,
  map,
  pick,
  size,
  without,
  isString
} from 'lodash';

import setIn from '../../utils/lodash/setIn';
import Lookup from './Lookup';
import {
  getAttribute,
  getEndpointByType,
  isFieldDisabled
} from '@broadleaf/admin-components/dist/metadata/utils/MetadataUtils';
import useTranslateMode from '@broadleaf/admin-components/dist/form/hooks/useTranslateMode';
import {
  ChangeHighlight,
  useTracking
} from '@broadleaf/admin-components/dist/tracking';

import useEventCallback from '@broadleaf/admin-components/dist/common/hooks/useEventCallback';
import { findComponent } from '@broadleaf/admin-components/dist/metadata/utils/MetadataUtils/MetadataUtils';
import {
  ComponentRegistrar,
  mapper,
  log,
  HelpText,
  Hint,
  useRefreshEffect
} from '@broadleaf/admin-components/dist/common';
import {
  FieldError,
  lookupComponents,
  lookupExtensions
} from '@broadleaf/admin-components/dist/form';
import { request } from '@broadleaf/admin-components/dist/metadata/utils/request';

const logger = log.getLogger('form.named-components.field-types.LookupField');

export const SelectionType = Object.freeze({
  OPTION: 'OPTION',
  VALUE: 'VALUE'
});

const LookupField = props => {
  const components = useComponents(props);
  const translateMode = useTranslateMode();
  const isDecorated = getAttribute(
    props.metadata,
    'decorated',
    props.decorated
  );
  const isModalSupport = getAttribute(props.metadata, 'isModalSupport', false);
  const isMulti = getAttribute(props.metadata, 'isMulti', false);
  const isSearchable = getAttribute(props.metadata, 'isSearchable', true);
  const labelKey = getAttribute(props.metadata, 'labelKey', 'name');
  const loadingMessage = getAttribute(props.metadata, 'loadingMessage');
  const modalToggleLabel = getAttribute(
    props.metadata,
    'modalToggleLabel',
    'Browse'
  );
  const noOptionsMessage = getAttribute(props.metadata, 'noOptionsMessage');
  const valueKey = getAttribute(props.metadata, 'valueKey', 'id');
  const implicitFilters = getAttribute(props.metadata, 'implicitFilters', []);
  const parentIdField = getAttribute(props.metadata, 'parentIdField', 'id');
  const dependentFields = getAttribute(props.metadata, 'dependentFields', []);
  const dependencies = without(
    [...dependentFields, parentIdField],
    props.metadata.name
  );
  const parent = {
    ...pick(props.formik.values, dependencies),
    $parent: get(props, 'formik.values.$parent', {})
  };
  const tracking = useTracking(props.metadata);
  const contextParams = {
    parent,
    tracking
  };

  const { onValueChange, value } = useHydratedValue({
    contextParams,
    ...props
  });

  const onBlur = useEventCallback(() => {
    props.formik.setFieldTouched(props.metadata.name, true);
  }, [props.formik.setFieldTouched, props.metadata.name]);

  const onModalClose = useEventCallback(() => {
    props.formik.setFieldTouched(props.metadata.name, true);
  }, [props.formik.setFieldTouched, props.metadata.name]);

  const modalMetadata = useMemo(() => {
    const modalMetadata = findComponent(props.metadata, {
      type: 'LOOKUP_MODAL'
    });

    if (!modalMetadata) {
      return undefined;
    }

    // we default the label of the Modal to the label of the field if none exists
    if (!modalMetadata.label) {
      return {
        ...modalMetadata,
        label: props.metadata.label
      };
    }

    return modalMetadata;
  }, [props.metadata]);

  return (
    <Lookup
      components={components}
      contextParams={contextParams}
      dependsOn={parent}
      endpoint={getEndpointByType(props.metadata, 'READ')}
      fieldMetadata={props.metadata}
      formik={props.formik}
      implicitFilters={implicitFilters}
      isClearable={!props.metadata.required}
      isDecorated={isDecorated}
      isDisabled={isFieldDisabled(props, translateMode)}
      isModalSupport={isModalSupport}
      isMulti={isMulti}
      isSearchable={isSearchable}
      labelKey={labelKey}
      loadingMessage={loadingMessage}
      modalMetadata={modalMetadata}
      modalToggleLabel={modalToggleLabel}
      name={props.metadata.name}
      noOptionsMessage={noOptionsMessage}
      onBlur={onBlur}
      onChange={onValueChange}
      onModalClose={onModalClose}
      placeholder={props.metadata.placeholder}
      valueKey={valueKey}
      value={value}
    />
  );
};

LookupField.propTypes = {
  decorated: PropTypes.bool,
  disabled: PropTypes.bool,
  formik: PropTypes.object.isRequired,
  metadata: PropTypes.shape({
    attributes: PropTypes.shape({
      /**
       * The names of the fields that this Lookup is dependent on within the parent
       * Formik state.
       */
      dependentFields: PropTypes.arrayOf(PropTypes.string),

      /**
       * Any implicit RSQL queries or SPEL expressions to be applied to the lookup field.
       */
      implicitFilters: PropTypes.arrayOf(PropTypes.object),

      /**
       * Whether or not modal search is supported.
       */
      isModalSupport: PropTypes.bool,

      /**
       * Whether or not multi-selected is supported.
       */
      isMulti: PropTypes.bool,

      /**
       * Whether or not the Select is searchable.
       */
      isSearchable: PropTypes.bool,

      /**
       * The property key for the option's label. It can contain the template to create more complicated labels.
       * For example to display the label like `Name (id)` the template like `${name} - (${id})` can be used.
       */
      labelKey: PropTypes.string,

      /**
       * The message shown within the Select when loading.
       */
      loadingMessage: PropTypes.string,

      /**
       * The name of the component to use for the Modal search.
       */
      modalComponent: PropTypes.string,

      /**
       * The label for the ModalToggle used to open the Modal search.
       */
      modalToggleLabel: PropTypes.string,

      /**
       * The meessage shown within the Select when no options are found.
       */
      noOptionsMessage: PropTypes.string,

      /**
       * The name of the component to usee for the Select.
       */
      selectComponent: PropTypes.string,

      /**
       * The {@link SelectionType} of the lookup, which controls how the selected
       * option is persisted within the form state.
       *
       * The {@link SelectionType#OPTION} persists the entire within the form state.
       * If `isMulti`, then this persists the array of options within the form state.
       *
       * The {@link SelectionType#VALUE} persists the option's value within the form state.
       * If `isMulti`, then this persists the array of option values within the form state.
       */
      selectionType: PropTypes.string,

      /**
       * The set of Mappings used to transform the selected option when using
       * {@link SelectionType#OPTION}. For {@link SelectionType#VALUE}, this will
       * result in additional fields being mapped over, however, the persistence
       * and clearing of these values will not be managed.
       */
      transformSelection: PropTypes.arrayOf(PropTypes.object),

      /**
       * The property key for the option's value.
       */
      valueKey: PropTypes.string
    })
  })
};

LookupField.defaultProps = {
  decorated: true,
  disabled: false
};

function useComponents(props) {
  const components = {
    Header: LookupFieldHeader,
    LookupContainer: LookupFieldContainer,
    SelectContainer: LookupFieldSelectContainer
  };

  const selectComponent = getAttribute(props.metadata, 'selectComponent');
  if (selectComponent) {
    const Select =
      ComponentRegistrar.getComponent(selectComponent) ||
      lookupExtensions[selectComponent] ||
      lookupComponents[selectComponent];
    if (Select) {
      components.Select = Select;
    } else {
      logger.warn(
        `LookupField configured with a replacement Select component named, "${selectComponent}", however, no extension with that name was found.`
      );
    }
  }

  const modalComponent = getAttribute(props.metadata, 'modalComponent');
  if (modalComponent) {
    const Modal =
      ComponentRegistrar.getComponent(modalComponent) ||
      lookupExtensions[modalComponent] ||
      lookupComponents[modalComponent];
    if (Modal) {
      components.Modal = Modal;
    } else {
      logger.warn(
        `LookupField configured with a replacement Modal component named, "${modalComponent}", however, no extension with that name was found.`
      );
    }
  }

  return components;
}

/**
 * Facilitates the lifecycle for hydrating the initial value for Lookup's with
 * a `HYDRATE` endpoint. This is necessary in order to show proper labeling
 * for the existing value(s) when using {@link SelectionType#VALUE}.
 *
 * @param  {Object} contextParams        the context parameters for the hydrate request
 * @param  {FormikPropShape} formik      the formik object
 * @param  {ComponentPropShape} metadata the metadata
 * @return {{ onValueChange: Function, value: Object }} the change handler and value
 */
function useHydratedValue({ contextParams, formik, metadata }) {
  const transformSelection = getTransformSelectionMappings(metadata);
  const fieldName = metadata.name;
  const hydrateConfig = getEndpointByType(metadata, 'HYDRATE');
  const isMulti = getAttribute(metadata, 'isMulti', false);
  const selectionType = getAttribute(
    metadata,
    'selectionType',
    SelectionType.OPTION
  );
  const isValueType = selectionType === SelectionType.VALUE;
  const valueKey = getAttribute(metadata, 'valueKey', 'id');

  function transformValue(rawValue) {
    if (!rawValue) {
      return undefined;
    }

    if (isEmpty(transformSelection)) {
      return rawValue;
    }

    return mapper.transform(rawValue, transformSelection);
  }

  function reverseTransformValue(transformedValue) {
    if (!transformedValue) {
      return undefined;
    }

    if (isEmpty(transformSelection)) {
      return transformedValue;
    }

    return mapper.transform(transformedValue, transformSelection, {
      reverse: true
    });
  }

  function getOptionFromFormik(value) {
    if (!isMulti) {
      return reverseTransformValue(value);
    } else {
      return map(value, reverseTransformValue);
    }
  }

  // the raw Formik value of the field, an Object if SelectionType.OPTION, or primitive if SelectionType.VALUE
  const formikValue = get(formik.values, fieldName);
  // the processed option from Formik for the field, only applicable if SelectionType.OPTION
  const formikOption = !isValueType && getOptionFromFormik(formikValue);
  // the hydrated value representing the fully qualified option, only applicable with HYDRATE is provided
  const [hydratedOption, setHydratedOption] = useState(isMulti ? [] : null);
  // represents the ID (or set of IDs) for the lookup
  const valueOrValues = extractValueOrValues(
    formikValue,
    formikOption,
    isValueType,
    isMulti,
    valueKey
  );

  useRefreshEffect(
    /**
     * This refresh effect is used hydrate the option for Lookup's with a `HYDRATE` endpoint.
     */
    () => {
      /**
       * For a given value, this function uses the HYDRATE endpoint to load the option
       * for that value. If an option is not found, or it fails to reach the server,
       * it will default the option's label to "${value}".
       *
       * @param  {Any} value the value of the option
       * @return {Promise}   a Promise that resolves with the option
       */
      function readOptionForValue(value) {
        return request(hydrateConfig, {
          ...contextParams,
          id: value,
          value: value
        })
          .then(response => response.data)
          .then(nextHydratedOption => {
            logger.debug(
              `Hydrated fully qualified option for field "${fieldName}" with ${valueKey} ${value}: \n${JSON.stringify(
                nextHydratedOption,
                null,
                2
              )}`
            );
            return nextHydratedOption;
          })
          .catch(error => {
            logger.error(
              `Unable to hydrate the persisted "${fieldName}" of ${valueKey} ${value} with the fully qualified option.`,
              error
            );

            // if we failed to hydrate, then we need to simply return the value as it was un-hydrated
            if (!isValueType) {
              if (!isMulti) {
                return formikOption;
              } else {
                return find(formikOption, valueMatcher(value, valueKey)) || {};
              }
            }

            return value;
          });
      }

      if (hydrateConfig) {
        if (!isEmpty(formikValue)) {
          // if formikValue is non-empty, then hydrate!
          if (
            !isMulti &&
            // for single, if the option is not already hydrated, we must hydrate
            isHydratedOptionStale(hydratedOption, valueOrValues, valueKey)
          ) {
            logger.debug(
              `Hydrating single option for field with name "${fieldName}" and ${valueKey} ${valueOrValues}`
            );

            readOptionForValue(valueOrValues).then(setHydratedOption);
          } else if (
            isMulti &&
            // for multi, if every option is not already hydrated, we must hydrate
            isHydratedOptionsStale(hydratedOption, valueOrValues, valueKey)
          ) {
            logger.debug(
              `Hydrating multiple options for field with name "${fieldName}" and ${valueKey}s ${valueOrValues}`
            );

            Promise.all(map(valueOrValues, readOptionForValue)).then(
              setHydratedOption
            );
          }
        } else {
          // if formikValue is empty, then set to empty
          setHydratedOption(isMulti ? [] : null);
        }
      }
    },
    // we only want this refresh effect to run when the formikValue changes
    { formikValue }
  );

  const onValueChange = useEventCallback(
    nextValue => {
      if (hydrateConfig) {
        setHydratedOption(nextValue);
      }

      if (isValueType) {
        if (!isMulti) {
          // optionA ==> optionA.value

          if (isEmpty(transformSelection)) {
            formik.setFieldValue(fieldName, get(nextValue, valueKey));
          } else {
            formik.setValues(
              setIn(
                {
                  ...formik.values,
                  ...transformValue(nextValue)
                },
                fieldName,
                get(nextValue, valueKey)
              )
            );
          }
        } else {
          // [optionA, optionB] ==> [valueA, valueB]
          formik.setFieldValue(fieldName, map(nextValue, valueKey));
        }
      } else {
        if (!isMulti) {
          // optionA ==> transformedOptionA
          formik.setFieldValue(fieldName, transformValue(nextValue));
        } else {
          // [optionA, optionB] ==> [transformedOptionA, transformedOptionB]
          formik.setFieldValue(fieldName, map(nextValue, transformValue));
        }
      }
    },
    [formik.setFieldValue, formik.setFieldTouched, transformValue, valueKey]
  );

  return {
    onValueChange,
    value: !isEmpty(hydratedOption)
      ? hydratedOption
      : formikOption || formikValue || null
  };
}

function extractValueOrValues(
  formikValue,
  formikOption,
  isValueType,
  isMulti,
  valueKey
) {
  let valueOrValues;
  if (!isValueType) {
    valueOrValues = !isMulti
      ? get(formikOption, valueKey)
      : map(formikOption, valueKey);
  } else {
    valueOrValues = formikValue;
  }
  return valueOrValues;
}

function isHydratedOptionsStale(hydratedOption, valueOrValues, valueKey) {
  return (
    size(valueOrValues) !== size(hydratedOption) ||
    !isEmpty(
      differenceWith(hydratedOption, valueOrValues, (option, value) =>
        valueMatcher(value, valueKey)(option)
      )
    )
  );
}

function isHydratedOptionStale(hydratedOption, valueOrValues, valueKey) {
  return !valueMatcher(valueOrValues, valueKey)(hydratedOption);
}

function valueMatcher(value, valueKey) {
  return option => get(option, valueKey) === value;
}

/**
 * Helper function used to the the correct mappings for the option selection transformation.
 *
 * @param metadata the metadata
 * @returns {Array}
 */
function getTransformSelectionMappings(metadata) {
  const transformSelection = getAttribute(metadata, 'transformSelection');
  if (isArray(transformSelection)) {
    return transformSelection;
  }

  const deprecatedFieldMappings = getAttribute(metadata, 'fieldMappings');
  if (isArray(deprecatedFieldMappings)) {
    logger.warn(
      'Found usage of deprecated `fieldMappings` within LOOKUP field. Please use `transformSelection` instead.'
    );
    return deprecatedFieldMappings;
  }

  return [];
}

export const LookupFieldHeader = ({ children, ...props }) => {
  const { fieldMetadata, isDecorated } = props.lookupProps;

  if (!isDecorated) {
    // render no header when undecorated
    return null;
  }

  return (
    <lookupComponents.Header {...props}>
      <label className="control-label">
        {fieldMetadata.required ? (
          <span className="required">{fieldMetadata.label}</span>
        ) : (
          fieldMetadata.label
        )}
        {!!fieldMetadata.helpText && buildHelpText(fieldMetadata.helpText)}
      </label>
      {children}
    </lookupComponents.Header>
  );
};

export const LookupFieldContainer = ({ children, ...props }) => {
  const { fieldMetadata, formik, isDecorated } = props.lookupProps;
  const error = get(formik.errors, fieldMetadata.name);
  const touched = get(formik.touched, fieldMetadata.name);
  const submitted = formik.submitCount > 0;
  return (
    <lookupComponents.LookupContainer
      className={cx({ 'form-group': isDecorated })}
      {...props}
    >
      {children}
      {isDecorated && (
        <footer>
          {!!fieldMetadata.hint && buildHint(fieldMetadata.hint)}
          {(touched || submitted) && !!error && (
            <FieldError error={error} inline />
          )}
        </footer>
      )}
    </lookupComponents.LookupContainer>
  );
};

export const LookupFieldSelectContainer = ({ children, ...props }) => {
  const { fieldMetadata, formik } = props.lookupProps;
  return (
    <lookupComponents.SelectContainer {...props}>
      <ChangeHighlight entity={formik.values} fieldName={fieldMetadata.name}>
        {children}
      </ChangeHighlight>
    </lookupComponents.SelectContainer>
  );
};

/**
 * Returns a HelpText component with the provided message or props.
 *
 * @param {Object|string|React.Element} messageOrProps the message or props
 * @returns {React.Element}
 */
export function buildHelpText(messageOrProps) {
  if (isString(messageOrProps) || isValidElement(messageOrProps)) {
    return <HelpText message={messageOrProps} />;
  }

  return <HelpText {...messageOrProps} />;
}

export function buildHint(hint) {
  hint = parseJsonIfNeeded(hint);

  if (isString(hint) || isValidElement(hint)) {
    return <Hint message={hint} />;
  }

  return <Hint {...hint} />;
}

function parseJsonIfNeeded(hint) {
  if (isValidElement(hint)) {
    return hint;
  }

  try {
    return JSON.parse(hint);
  } catch (e) {
    return hint;
  }
}

export default LookupField;
export { LookupField };
