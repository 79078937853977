/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import UserSettingsDropdown from './UserSettingsDropdown';
import printLogo from '../../img/revmed-logo-light-bg.png';
import logo from '../../img/revmed-logo.png';
import { useUserProfile } from '@broadleaf/admin-components/dist/authentication';
import Link from '@broadleaf/admin-components/dist/common/elements/Link';

export const Header = ({ className, onLogoutClick, onChangePasswordClick }) => {
  const user = useUserProfile();
  return (
    <div
      className={cx(
        className,
        'tw-relative tw-z-20 tw-flex tw-h-16 tw-max-w-full tw-items-center tw-bg-gray-900 tw-p-4 tw-shadow print:tw-mt-8'
      )}
    >
      <div className="tw-ml-8 tw-p-3 md:tw-flex print:tw-ml-0 print:tw-pl-0">
        <Link to={'/'}>
          <img
            className="tw-w-40 print:tw-hidden"
            src={logo}
            alt="RevMed Logo"
          />
          <img
            className="tw-hidden tw-w-40 print:tw-block"
            src={printLogo}
            alt="RevMed Logo"
          />
        </Link>
      </div>
      <div className="tw-ml-auto" />
      <div className="print:tw-hidden">
        <UserSettingsDropdown
          onChangePasswordClick={onChangePasswordClick}
          onLogoutClick={onLogoutClick}
          user={user}
        />
      </div>
    </div>
  );
};

Header.propTypes = {
  /** the currently logged in user's context */
  user: PropTypes.shape({
    /** the current user's username */
    username: PropTypes.string
  })
};

export default Header;
