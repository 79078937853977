/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useMemo } from 'react';

import generateOptionFields from '@broadleaf/admin-components/dist/form/named-components/external-types/VariantsExternalGridCollection/utils/generateOptionFields';
import CollapsibleGroup from '@broadleaf/admin-components/dist/form/named-components/group-types/CollapsibleGroup';
import { IFormik } from '@broadleaf/admin-components/dist/types/form';
import {
  IMetadata,
  IMetadataAction
} from '@broadleaf/admin-components/dist/types/metadata';

export const VariantIdentificationGroup: React.FC<VariantIdentificationGroupProps> = props => {
  const { formik, metadata } = props;
  const components = useMemo(() => {
    const optionsField = formik.values.product?.options
      ? 'product.options'
      : 'product.itemOptions';
    const generatedOptionFields = generateOptionFields({
      actionDefinition: {
        ...metadata,
        attributes: {
          ...metadata.attributes,
          optionsField
        }
      } as IMetadataAction,
      formik,
      variantOptionsOrder: 1
    }).map(optionObj => {
      const option = optionObj as any;
      // if there is only one value make that the default
      if (option.options && option.options.length === 1) {
        option.defaultValue = option.options[0].value;
        option.readOnly = true;
      }
      return option;
    });

    return [...generatedOptionFields, ...props.metadata.components];
  }, [formik.values.product, props.metadata.components]);
  const groupProps = useMemo(() => {
    return {
      ...props,
      metadata: {
        ...props.metadata,
        components
      }
    };
  }, [components, props]);
  return <CollapsibleGroup {...groupProps} />;
};

export interface VariantIdentificationGroupProps {
  disabled?: boolean;
  /** Reference to Formik instance **/
  formik: IFormik;
  metadata: IMetadata;
}

export default VariantIdentificationGroup;
