import React from 'react';
import cx from 'classnames';

import { Option } from './RMToggleSwitch';

/**
 * This component is the same as the ToggleSwitchLabel provided in AdminWeb,
 * except it doesn't have rounded corners.
 */
export const RMToggleSwitchLabel: React.FC<ToggleSwitchLabelProps> = ({
  disabled = false,
  handleClick,
  id,
  option,
  selected = false
}) => {
  return (
    <label
      className={cx(
        'tw-flex tw-items-center tw-mb-0 tw-px-3 tw-h-8 tw-text-xs tw-font-bold focus:tw-outline-none tw-cursor-pointer tw-transition-colors',
        {
          'tw-bg-primary-500': !option.color && !!selected,
          [`tw-bg-${option.color}-600 hover:tw-bg-${option.color}-700`]:
            !!option.color && !!selected && !disabled,
          'tw-bg-gray-600': !!option.color && !!selected && disabled,
          'tw-cursor-not-allowed': !!disabled,
          'tw-bg-gray-300': !!disabled && !selected
        }
      )}
      htmlFor={id}
      onClick={handleClick}
    >
      <span>{option.label}</span>
    </label>
  );
};

interface ToggleSwitchLabelProps {
  disabled?: boolean;
  handleClick: React.MouseEventHandler<HTMLLabelElement>;
  id: string;
  option: Option;
  selected?: boolean;
}

export default RMToggleSwitchLabel;