/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import { createPath } from 'history';
import { get } from 'lodash';

import Link, {
  LinkProps
} from '@broadleaf/admin-components/dist/common/elements/Link';
import { SimpleIcon } from '@broadleaf/admin-components/dist/common/elements/SimpleIcon';
import Preloader from '@broadleaf/admin-components/dist/common/helpers/Preloader';
import { mustHaveMetadataProps } from '@broadleaf/admin-components/dist/form/utils/invariantHelpers';
import useRouteLink from '@broadleaf/admin-components/dist/metadata/hooks/useRouteLink';
import metadataInvariant from '@broadleaf/admin-components/dist/metadata/utils/MetadataInvariant';
import { ICommonGridColumn } from '@broadleaf/admin-components/dist/types/common';

/**
 * Renders a column with a link. The link contains a back-reference to the previous location.
 *
 * @visibleName LINK
 */
export const LinkColumn: React.FC<LinkColumnProps> = ({ header, row }) => {
  const from = useLocation();
  mustHaveMetadataProps(header, ['name']);

  const title = get(row, header.name);
  const link = get(header, 'attributes.link');
  const sliceValue = get(header, 'attributes.sliceValue');
  const truncate = get(header, 'attributes.truncate', true);
  const newTab = get(header, 'attributes.newTab', false);

  metadataInvariant(
    !!link,
    'LINK column types must be provided `attributes.link`.',
    header
  );

  const { location: to, getPreloaderProps } = useRouteLink({
    from,
    link,
    vars: row
  });

  const linkProps: LinkProps = { to, title };
  if (newTab) {
    const href = typeof to === 'string' ? to : createPath(to);

    linkProps.target = '_blank';
    linkProps.rel = href.startsWith('/') ? undefined : 'noopener noreferrer';
  }
  let value = title;
  if (sliceValue) {
    value = value.slice(sliceValue);
  }

  // if we provided a "link" attribute, use that to link to an existing route
  return (
    <Preloader {...getPreloaderProps()}>
      <div className={cx({ 'tw-truncate': truncate })}>
        {to ? (
          <Link
            className="tw-flex tw-items-center tw-font-semibold tw-text-link-600 hover:tw-text-link-700"
            {...linkProps}
          >
            <span>{value}</span>
            {newTab && (
              <SimpleIcon className="tw-ml-2" iconName="external-link-alt" />
            )}
          </Link>
        ) : (
          <span title={title}>{value}</span>
        )}
      </div>
    </Preloader>
  );
};

/**
| Property | Description |
| ---- | ---- |
| `name` | the name of this column in the row data (passed as result prop) |
| `attributes.link` | the link to be redirected to. You can insert values from the row by using `:` as a prefix character
For example, `/products/:id` would indicate we should replace `:id` with the value of `row.id`.|
 */
export interface LinkColumnProps extends ICommonGridColumn {}

export default LinkColumn;
