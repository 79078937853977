/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { withRouter } from 'react-router-dom';

import { UserAccess } from '@broadleaf/admin-components/dist/authentication';
import Button from '@broadleaf/admin-components/dist/common/elements/Button';
import Preloader from '@broadleaf/admin-components/dist/common/helpers/Preloader';
import useTranslateMode from '@broadleaf/admin-components/dist/form/hooks/useTranslateMode';
import useRouteLink from '@broadleaf/admin-components/dist/metadata/hooks/useRouteLink';
import { getAttribute } from '@broadleaf/admin-components/dist/metadata/utils/MetadataUtils';
import { History, Location } from 'history';
import { IMetadataAction } from '@broadleaf/admin-components/dist/types/metadata';

/**
 * A link button for the EntityGrid component that is used to help the user
 */
const LinkButtonComponent: React.FC<LinkButtonProps> = props => {
  const checkAccess = UserAccess.useCheckAccess();
  const translateMode = useTranslateMode();
  const disabled =
    translateMode.isActive ||
    !checkAccess(
      props.actionDefinition.scope,
      props.actionDefinition.operationType
    );
  const link = getAttribute(props.actionDefinition, 'link', {
    path: props.location.pathname + '/create'
  });
  const { location: locationForCreate, getPreloaderProps } = useRouteLink({
    link
  });
  return (
    <Preloader {...getPreloaderProps()}>
      <Button
        className="tw-flex-initial"
        color="primary"
        size="sm"
        disabled={disabled}
        onClick={() => props.history.push(locationForCreate)}
        title={props.actionDefinition.label}
      >
        {props.actionDefinition.label}
      </Button>
    </Preloader>
  );
};

export const LinkButton = withRouter(LinkButtonComponent);

export interface LinkButtonProps {
  // the action definition with the link and label
  actionDefinition?: IMetadataAction;
  // the route history object
  history: History;
  location: Location;
  hasSecondaryAction?: boolean;
}
export default LinkButton;
