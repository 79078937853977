/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';
import SVG from '@broadleaf/admin-components/dist/common/components/SVG';

const NavError = props => {
  const { fetchNavigation } = props;
  return (
    <div className="tw-mt-4 tw-truncate tw-text-center">
      <SVG
        className="tw-mx-auto tw-h-10 tw-w-10 tw-fill-current tw-text-red-500"
        name="close-solid"
      />
      <div className="tw-mt-4 tw-text-gray-100">
        <div>An error occurred</div>
        <div className="tw-text-sm">Unable to load navigation</div>

        <div
          className="flex items-center tw-mt-8 tw-cursor-pointer tw-text-sm tw-text-gray-300 hover:tw-text-gray-100"
          onClick={() => fetchNavigation()}
        >
          <SVG
            className="tw-mx-auto tw-inline-block tw-h-4 tw-w-4 tw-fill-current"
            name="refresh"
          />
          <span>Retry</span>
        </div>
      </div>
    </div>
  );
};

NavError.propTypes = {
  /** The function used to reload the menu from the API **/
  fetchNavigation: PropTypes.func.isRequired
};

export default NavError;
