/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import cx from 'classnames';

import Currency from '@broadleaf/admin-components/dist/common/components/Currency';
import PlaceholderBlock from '@broadleaf/admin-components/dist/common/components/PlaceholderBlock';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import {
  getFulfillmentTotal,
  getGrandTotal,
  getMerchandiseTotal,
  getTaxTotal
} from '@broadleaf/admin-components/dist/oms/utils/FulfillmentUtils';

import messages from './Details.messages';
import { getAttributeAmount } from '../../utils/RmFulfillmentUtils';
import { IFulfillmentViewCommon } from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/fulfillment';

const Details: React.FC<DetailsProps> = ({ className, metadata, state }) => {
  if (!state.data) {
    return (
      <div className={cx(className, 'tw-mx-auto tw-w-full tw-py-4 md:tw-px-4')}>
        <PlaceholderBlock height="300" width="full" />
      </div>
    );
  }
  return (
    <div className={cx(className, 'tw-flex tw-flex-col tw-p-4')}>
      <div className="tw-flex tw-flex-col md:tw-flex-row">
        <LabeledText className="tw-flex-1" label="Buyer Totals">
          <BuyerFulfillmentTotal fulfillment={state.data} />
        </LabeledText>
        <LabeledText className="tw-flex-1" label="Seller Totals">
          <SellerFulfillmentTotal fulfillment={state.data} />
        </LabeledText>
      </div>
    </div>
  );
};

export interface DetailsProps extends IFulfillmentViewCommon {
  className?: string;
}

const BuyerFulfillmentTotal = ({ fulfillment }) => {
  const formatMessage = useFormatMessage();
  const revMedFees = getAttributeAmount(fulfillment, 'revMedProcessingFee');
  const fulfillmentTotal = getFulfillmentTotal(fulfillment);
  const merchandiseTotal = getMerchandiseTotal(fulfillment);
  const grandTotal = getGrandTotal(fulfillment);
  const taxTotal = getTaxTotal(fulfillment);
  return (
    <>
      <div className="tw-p-2">
        {merchandiseTotal && (
          <LabeledPrice
            className="tw-mb-1 tw-text-sm"
            label={formatMessage(messages.merchandiseTotal)}
            total={merchandiseTotal}
          />
        )}
        {revMedFees && (
          <LabeledPrice
            className="tw-mb-1 tw-text-sm"
            label="Processing"
            total={revMedFees}
          />
        )}
        {fulfillmentTotal && (
          <LabeledPrice
            className="tw-mb-1 tw-text-sm"
            label="Shipping"
            total={fulfillmentTotal}
          />
        )}
        {taxTotal && (
          <LabeledPrice
            className="tw-mb-1 tw-text-sm"
            label={formatMessage(messages.taxTotal)}
            total={taxTotal}
          />
        )}
      </div>
      <hr className="tw-border-gray-300" />
      <div className="tw-p-2">
        <LabeledPrice
          className="tw-text-base"
          label={'Buyer ' + formatMessage(messages.grandTotal)}
          total={grandTotal}
        />
      </div>
    </>
  );
};

const SellerFulfillmentTotal = ({ fulfillment }) => {
  const formatMessage = useFormatMessage();
  const revMedFees = getAttributeAmount(fulfillment, 'revMedFee', true);
  const insuranceCost = getAttributeAmount(fulfillment, 'insuranceCost', true);
  const revMedFulfillmentFee = getAttributeAmount(
    fulfillment,
    'revMedFulfillmentFee',
    true
  );
  const sellerNetTotal = getAttributeAmount(fulfillment, 'sellerNetTotal');
  const merchandiseTotal = getMerchandiseTotal(fulfillment);
  return (
    <>
      <div className="tw-p-2">
        <LabeledPrice
          className="tw-mb-1 tw-text-sm"
          label={formatMessage(messages.merchandiseTotal)}
          total={merchandiseTotal}
        />
        <LabeledPrice
          className="tw-mb-1 tw-text-sm"
          label={'RevMed Service Fee'}
          total={revMedFees}
        />
        <LabeledPrice
          className="tw-mb-1 tw-text-sm"
          label={'Fulfillment'}
          total={revMedFulfillmentFee}
        />
        <LabeledPrice
          className="tw-mb-1 tw-text-sm"
          label={'Shipping Insurance'}
          total={insuranceCost}
        />
      </div>
      <hr className="tw-border-gray-300" />
      <div className="tw-p-2">
        <LabeledPrice
          className="tw-text-base"
          label={'Seller Net Total'}
          total={sellerNetTotal}
        />
      </div>
    </>
  );
};

const LabeledText = ({ children, className, label }) => {
  return (
    <div
      className={cx(
        className,
        'sm:tw-text-normal tw-mb-4 tw-flex tw-flex-col tw-text-sm'
      )}
    >
      <span className="tw-text-xs tw-font-medium tw-uppercase tw-tracking-tight tw-text-gray-600">
        {label}
      </span>
      {children}
    </div>
  );
};

const LabeledPrice = ({ className, label, total }) => {
  const textColor = 'tw-text-gray-900';
  return (
    <div className={cx(className, 'tw-flex tw-justify-between')}>
      <div className="tw-text-gray-700">{label}</div>
      <div className={cx(textColor, 'tw-font-medium')}>
        <Currency currency={total.currency} value={total.amount} />
      </div>
    </div>
  );
};

export default Details;
