/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

import React from 'react';

import actionComponents from './action-components';
import Body from './components/Body';
import Details from './components/Details';
import Items from './components/Items';
import Header from './components/Header';
import { FulfillmentView } from '@broadleaf/admin-components/dist/oms/components';
import Fulfillment from './components/Fulfillment';

const overrideComponents = { Body, Details, Items, Header, Fulfillment };

export const RmFulfillmentView = props => {
  return <FulfillmentView components={overrideComponents} {...props} />;
};

RmFulfillmentView.TYPE = FulfillmentView.TYPE;
RmFulfillmentView.actionComponents = actionComponents;
export default RmFulfillmentView;
