/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useMemo } from 'react';
import { Field as FormikField, Formik } from 'formik';
import cx from 'classnames';
import classNames from 'classnames';
import { find, get } from 'lodash';
import * as yup from 'yup';

import Select from '@broadleaf/admin-components/dist/common/components/Select';
import { FieldDecorations } from '@broadleaf/admin-components/dist/form/helpers/FieldDecorations';
import SimpleModal from '@broadleaf/admin-components/dist/common/components/SimpleModal';
import Spinner from '@broadleaf/admin-components/dist/common/elements/Spinner';
import useEventCallback from '@broadleaf/admin-components/dist/common/hooks/useEventCallback';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import FormikError from '@broadleaf/admin-components/dist/form/components/FormikError';
import {
  clearFormikErrors,
  setFormikErrors
} from '@broadleaf/admin-components/dist/form/utils/RequestErrorHelpers';
import {
  IMetadata,
  IMetadataFieldComponent
} from '@broadleaf/admin-components/dist/types/metadata';
import {
  IConsolidatedOrder,
  IInvoiceSummary
} from '../../../utils/RmFulfillmentUtils';
import {
  useFormatDate,
  useFormatNumber
} from '@broadleaf/admin-components/dist/common';
import {
  getApplyInvoiceSettingsEndpoint,
  getApplyRevMedCreditEndpoint
} from '../../utils/InvoicesOperationUtils';
import { request } from '@broadleaf/admin-components/dist/metadata/utils/request';
import useContextParams from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/hooks/useContextParams';
import type { ICollectionStateHook } from '@broadleaf/admin-components/dist/types/collection';
import { UserAccess } from '@broadleaf/admin-components/dist/authentication';
import Link from '@broadleaf/admin-components/dist/common/elements/Link';
import { getInvoiceStatusOptions } from '../../utils/InvoicesGridUtils';

export const SubmitInvoicesModal: React.FC<
  SubmitInvoicesModalProps
> = props => {
  const { allSelectedItems, onClose } = props;
  return (
    <SimpleModal
      /*// @ts-ignore */
      closeOnClickOutside={false}
      isOpen
      onClose={onClose}
      size="lg"
      title={<>Submit Invoices</>}
      footer={
        <div>
          <SubmitButton
            submitLabel={'Submit'}
            submitInvoices={props.submitInvoices}
            isSubmitting={props.isSubmitting}
          />{' '}
        </div>
      }
    >
      <ModalBody {...props} />
    </SimpleModal>
  );
};

export interface SubmitInvoicesModalProps {
  allSelectedItems: any[];
  submitInvoices: () => void;
  isSubmitting: boolean;
  onClose: () => void;
}

const ModalBody: React.FC<SubmitInvoicesModalProps> = props => {
  const { allSelectedItems, submitInvoices, onClose } = props;
  const itemsRequirePO = allSelectedItems.filter(
    item => item.invoiceStatus === 'REQUIRES_PO'
  );
  const itemsCreditPending = allSelectedItems.filter(
    item => item.invoiceStatus === 'CREDIT_PENDING'
  );
  return (
    <div>
      <div>Submit {allSelectedItems.length} invoice(s)?</div>
      {itemsRequirePO.length > 0 && (
        <div>
          <div className={'tw-mt-2 tw-font-bold'}>
            Note: {itemsRequirePO.length} invoice(s) require a PO number and
            does not have one.
          </div>
        </div>
      )}
      {itemsCreditPending.length > 0 && (
        <div>
          <div className={'tw-mt-2 tw-font-bold'}>
            Note: {itemsCreditPending.length} invoice(s) are in the "Credit
            Pending" status These invoices may be ignored by Remitra and require
            resubmission.
          </div>
        </div>
      )}
    </div>
  );
};

const SubmitButton = ({
  isSubmitting,
  submitLabel,
  isDisabled = false,
  submitInvoices
}) => {
  return (
    <>
      <button
        disabled={isSubmitting || isDisabled}
        className={classNames(
          'tw-text-md focus:tw-shadow-outline tw-w-full tw-rounded tw-border tw-bg-primary-500 tw-px-4 tw-py-4 tw-font-semibold tw-text-primary-100 tw-shadow hover:tw-bg-primary-600 focus:tw-outline-none md:tw-w-auto md:tw-py-2',
          {
            'tw-cursor-not-allowed': isSubmitting || isDisabled
          }
        )}
        onClick={() => submitInvoices()}
        style={{ opacity: isSubmitting ? '0.65' : '1' }}
        type="submit"
      >
        {' '}
        {submitLabel}{' '}
        {isSubmitting && (
          <Spinner
            size="md"
            className="tw-ml-2"
            innerClassName="tw-border-gray-100"
          />
        )}
      </button>
    </>
  );
};

export default SubmitInvoicesModal;
