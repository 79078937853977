/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get } from 'lodash';

import { impersonate } from '@broadleaf/admin-components/dist/authentication/hooks/useImpersonateUser';

import SecondaryAction from '@broadleaf/admin-components/dist/view/named-components/view-types/EntityView/action-components/secondary/components/SecondaryAction';
import useAuthContext from '@broadleaf/admin-components/dist/authentication/hooks/useAuthContext';
import useCurrentTenant from '@broadleaf/admin-components/dist/tracking/hooks/useCurrentTenant';
import useEventCallback from '@broadleaf/admin-components/dist/common/hooks/useEventCallback';

/**
 * Action used for initiating the impersonation of a customer for an application.
 */
export const ImpersonateRevMedUser = ({
  actionDefinition,
  className,
  state
}) => {
  const impersonateUser = useImpersonateUser(get(state.data, 'email'));

  return (
    <SecondaryAction
      className={className}
      onClick={impersonateUser}
      title={actionDefinition.label}
    >
      {actionDefinition.label}
    </SecondaryAction>
  );
};

function useImpersonateUser(username) {
  // @ts-ignore
  const { clientId } = useAuthContext();
  const tenant = useCurrentTenant();
  return useEventCallback(async () => {
    // RM defaulting application id
    await impersonate({
      username,
      clientId,
      tenant,
      application: { id: '2', name: 'RevMed' }
    });
  }, [clientId, username, tenant]);
}

export default ImpersonateRevMedUser;
