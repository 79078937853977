/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useMemo } from 'react';
import { Formik, Field as FormikField } from 'formik';
import cx from 'classnames';
import { find, get, map, reduce } from 'lodash';
import * as yup from 'yup';

import Select from '@broadleaf/admin-components/dist/common/components/Select';
import { FieldDecorations } from '@broadleaf/admin-components/dist/form/helpers/FieldDecorations';
import SimpleModal from '@broadleaf/admin-components/dist/common/components/SimpleModal';
import SubmissionError from '@broadleaf/admin-components/dist/common/components/SubmissionError';
import SVG from '@broadleaf/admin-components/dist/common/components/SVG';
import Spinner from '@broadleaf/admin-components/dist/common/elements/Spinner';
import useEventCallback from '@broadleaf/admin-components/dist/common/hooks/useEventCallback';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import betweenWith from '@broadleaf/admin-components/dist/common/utils/lodash/betweenWith';
import FormikError from '@broadleaf/admin-components/dist/form/components/FormikError';
import {
  clearFormikErrors,
  setFormikErrors
} from '@broadleaf/admin-components/dist/form/utils/RequestErrorHelpers';
import FulfillmentItemSummary from '../FulfillmentItemSummary';

import messages from './FulfillmentStatusChangeModal.messages';
import {
  findComponent,
  hasComponent
} from '@broadleaf/admin-components/dist/metadata/utils/MetadataUtils/MetadataUtils';
import classNames from 'classnames';
import { IMetadata } from '@broadleaf/admin-components/dist/types/metadata';

export const FulfillmentStatusChangeModal: React.FC<FulfillmentStatusChangeModalProps> = ({
  metadata,
  fulfillment,
  nextStatus,
  onClose,
  onSubmit,
  readOnlyItems = false,
  selectAll = false,
  selectAllMessage = 'This action will apply to all items.',
  isSubmitting = false,
  submitLabel,
  submitNote = '',
  title
}) => {
  const initialValues = useInitialValues({ fulfillment, nextStatus });
  const validationSchema = useValidationSchema({ fulfillment, metadata });
  const handleSubmit = useEventCallback(
    async (values, formik) => {
      formik.setSubmitting(true);
      try {
        await onSubmit(values);
        formik.setSubmitting(false);
        clearFormikErrors(formik);
        onClose();
      } catch (error) {
        setFormikErrors(error, formik);
        formik.setSubmitting(false);
      }
    },
    [onSubmit]
  );
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {formik => (
        <SimpleModal
          /*// @ts-ignore */
          closeOnClickOutside={false}
          footer={
            <ModalFooter
              formik={formik}
              isSubmitting={isSubmitting}
              submitLabel={submitLabel}
              submitNote={submitNote}
            />
          }
          isOpen
          onClose={onClose}
          size="lg"
          title={title}
        >
          {selectAll ? (
            <SelectAllMessage message={selectAllMessage} />
          ) : (
            <Fields
              formik={formik}
              fulfillment={fulfillment}
              readOnlyItems={readOnlyItems}
              nextStatus={nextStatus}
              metadata={metadata}
            />
          )}
        </SimpleModal>
      )}
    </Formik>
  );
};

export interface FulfillmentStatusChangeModalProps {
  metadata: IMetadata;
  fulfillment: object;
  nextStatus: string;
  onClose: () => void;
  onSubmit: Function;
  readOnlyItems: boolean;
  selectAll: boolean;
  selectAllMessage: string;
  isSubmitting: boolean;
  submitLabel: string;
  submitNote?: string;
  title: string;
}

const SelectAllMessage = ({ message }) => {
  return (
    <div className="tw-flex tw-justify-center">
      <p className="tw-inline-block tw-items-center tw-rounded-md tw-border tw-border-blue-300 tw-bg-blue-100 tw-p-2 tw-text-sm tw-text-blue-700">
        <SVG
          className="tw-inline-block tw-h-5 tw-w-5 tw-fill-current"
          name="information-solid"
        />
        <span className="tw-ml-5">{message}</span>
      </p>
    </div>
  );
};

const Fields = props => {
  const { formik, fulfillment, readOnlyItems, nextStatus, metadata } = props;
  return (
    <div>
      {!readOnlyItems && (
        <>
          <div className="tw-flex-1 tw-p-2 tw-text-right tw-text-sm tw-text-gray-700">
            Select the number of items that should be {nextStatus.toLowerCase()}
            .
          </div>
          <hr className="tw-my-1 tw-border-gray-300" />
        </>
      )}
      {betweenWith(
        map(formik.values.items, (quantity, fulfillmentItemId) => {
          const fulfillmentItem = find(fulfillment.fulfillmentItems, {
            id: fulfillmentItemId
          });
          return (
            <div
              className="tw-mb-3 tw-flex tw-flex-col lg:tw-flex-row"
              key={fulfillmentItemId}
            >
              <FulfillmentItemSummary
                className="tw-flex-auto"
                fulfillmentItem={fulfillmentItem}
                showUnitPrice={false}
              />
              <QuantityInput
                className="tw-flex-initial tw-self-end tw-px-3 lg:tw-mt-3 lg:tw-self-start lg:tw-px-0"
                formik={formik}
                max={fulfillmentItem.quantity}
                name={`items.${fulfillmentItemId}`}
                readOnlyItems={readOnlyItems}
                nextStatus={nextStatus}
                value={quantity}
              />
            </div>
          );
        }),
        (_, ___, index) => (
          <hr key={`hr-${index}`} className="tw-my-1 tw-border-gray-300" />
        )
      )}

      {nextStatus === 'CAPTURING_PAYMENT' && (
        <AttestOrderCheckbox formik={formik} />
      )}

      {hasComponent(metadata, { name: 'reason' }) && (
        <StatusReason
          {...props}
          metadata={findComponent(metadata, { name: 'reason' })}
        />
      )}
    </div>
  );
};

const AttestOrderCheckbox = ({ className = '', formik }) => {
  const formik_name = 'attest';
  const formatMessage = useFormatMessage();
  const error = get(formik.errors, formik_name);
  const touched = get(formik.touched, formik_name);
  const showError = !!touched && !!error;

  return (
    <div className={cx(className, 'tw-flex tw-items-center tw-justify-end')}>
      <label className="tw-cursor-pointer tw-text-sm">
        <FormikField
          name={formik_name}
          type="checkbox"
          showError={showError}
          defaultValue={false}
          component={Input}
          className={cx('tw-h-3 tw-w-3', {
            'tw-ring-2 tw-ring-red-300': showError
          })}
        />
        <span className="tw-ml-2 ">
          {formatMessage(messages.attestFulfillOrder)}
        </span>
      </label>
    </div>
  );
};

const QuantityInput = ({
  className,
  formik,
  max,
  name,
  value,
  nextStatus,
  readOnlyItems
}) => {
  const formatMessage = useFormatMessage();
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name);
  const showError = !!touched && !!error;
  return (
    <div
      className={cx(
        className,
        'tw-flex tw-items-center tw-justify-end tw-whitespace-nowrap'
      )}
    >
      {showError && <SubmissionError className="tw-mr-2" errors={[error]} />}
      <FormikField
        max={max}
        min="0"
        name={name}
        disabled={readOnlyItems}
        showError={showError}
        step="1"
        type="number"
        value={value}
        component={Input}
      />
      <span className="tw-ml-1">
        {formatMessage(messages.quantityOf, { max })}
      </span>
    </div>
  );
};

const Input = ({
  field,
  form = { errors: [], touched: [] },
  showError = true,
  ...props
}) => {
  return (
    <input
      className={cx('tw-mr-1 tw-rounded tw-border tw-px-2 tw-py-1', {
        'tw-border-gray-300': !showError,
        'tw-border-red-600': showError
      })}
      {...field}
      {...props}
    />
  );
};

const StatusReason = props => {
  const { formik, metadata } = props;
  const name = metadata.name;
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name, false);
  const optionsValue = get(formik.values, name, '');
  return (
    <div className="tw-flex tw-flex-row">
      <div className="tw-flex-1"></div>
      <div className="tw-flex-1">
        <FieldDecorations fullWidth {...props} metadata={metadata}>
          <Select
            className={cx({
              'is-invalid': touched && !!error
            })}
            isClearable={false}
            isMulti={false}
            isDisabled={formik.isSubmitting}
            onChange={operator => {
              formik.setFieldValue(name, operator);
            }}
            onBlur={formik.handleBlur}
            options={metadata.options}
            name={name}
            placeholder={metadata.placeholder || 'Choose a reason'}
            value={optionsValue}
          />
        </FieldDecorations>
      </div>
    </div>
  );
};

const ModalFooter = ({ formik, isSubmitting, submitLabel, submitNote }) => {
  return (
    <>
      {formik.isSubmitting ? (
        <Spinner className="tw-mr-2" />
      ) : (
        <FormikError className="tw-mr-2" formik={formik} />
      )}

      <span className="tw-text-sm tw-text-gray-700">{submitNote}</span>
      <button
        disabled={isSubmitting}
        className={classNames(
          'tw-text-md focus:tw-shadow-outline tw-w-full tw-rounded tw-border tw-bg-primary-500 tw-px-4 tw-py-4 tw-font-semibold tw-text-primary-100 tw-shadow hover:tw-bg-primary-600 focus:tw-outline-none md:tw-w-auto md:tw-py-2',
          {
            'tw-cursor-not-allowed': isSubmitting
          }
        )}
        onClick={() => formik.submitForm()}
        style={{ opacity: isSubmitting ? '0.65' : '1' }}
        type="submit"
      >
        {' '}
        {submitLabel}{' '}
        {isSubmitting && (
          <Spinner
            size="md"
            className="tw-ml-2"
            innerClassName="tw-border-gray-100"
          />
        )}
      </button>
    </>
  );
};

function useInitialValues({ fulfillment, nextStatus }) {
  return useMemo(() => {
    return {
      fulfillmentId: fulfillment.id,
      status: nextStatus,
      attest: nextStatus !== 'CAPTURING_PAYMENT',
      reason: '',
      items: reduce(
        fulfillment.fulfillmentItems,
        (result, fulfillmentItem) => {
          return {
            ...result,
            [fulfillmentItem.id]:
              nextStatus === 'CANCELLED' || nextStatus === 'DISPUTED'
                ? 0
                : fulfillmentItem.quantity
          };
        },
        {}
      )
    };
  }, [fulfillment.fulfillmentItems, fulfillment.id, nextStatus]);
}

function useValidationSchema({ fulfillment, metadata }) {
  const formatMessage = useFormatMessage();
  const minMessage = formatMessage(messages.itemQuantityMinimum);
  const maxMessage = formatMessage(messages.itemQuantityMaximum);
  const requiredMessage = formatMessage(messages.itemQuantityRequired);
  const attestRequiredMessage = formatMessage(
    messages.attestFulfillOrderRequired
  );
  return useMemo(() => {
    const validation = yup.object().shape({
      fulfillmentId: yup.string(),
      status: yup.string(),
      attest: yup.boolean().oneOf([true], attestRequiredMessage),
      items: yup.object().shape(
        reduce(
          fulfillment.fulfillmentItems,
          (result, fulfillmentItem) => {
            return {
              ...result,
              [fulfillmentItem.id]: yup
                .number()
                .nullable()
                .transform(emptyStringToNull)
                .min(0, minMessage)
                .max(fulfillmentItem.quantity, maxMessage)
                .required(requiredMessage)
            };
          },
          {}
        )
      )
    });

    const reason = findComponent(metadata, { name: 'reason' });
    if (reason && reason['required']) {
      return validation.shape({
        reason: yup.string().required(reason['requiredMessage'])
      });
    }
    return validation;
  }, [fulfillment, metadata, maxMessage, minMessage, requiredMessage]);
}

function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

export default FulfillmentStatusChangeModal;
