/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { filter, get, isEmpty, map, entries, orderBy } from 'lodash';

import ColumnTypeRenderer from '@broadleaf/admin-components/dist/form/renderers/ColumnTypeRenderer';
import useActions from '@broadleaf/admin-components/dist/metadata/hooks/useActions';
import {
  getAttribute,
  isColumnComponent
} from '@broadleaf/admin-components/dist/metadata/utils/MetadataUtils';
import { evaluateConditionals } from '@broadleaf/admin-components/dist/common/services/ConditionalHelper';
import {
  IMetadata,
  IMetadataFieldComponent
} from '@broadleaf/admin-components/dist/types/metadata';
import { IFormik } from '@broadleaf/admin-components/dist/types/form';

export const ResidentMapField: React.FC<ResidentMapFieldProps> = ({
  disabled,
  formik,
  metadata
}) => {
  const collapsedByDefault = getAttribute(
    metadata,
    'collapsedByDefault',
    false
  );
  const emptyMessage = getAttribute(metadata, 'emptyMessage');
  const mapValue = get(formik.values, metadata.name, {});
  const sortedEntries = orderBy(
    entries(mapValue),
    o => o[1]['scanDate'],
    'desc'
  );
  const entryActions = useActions(metadata, 'ENTRY');
  const mapActions = useActions(metadata, 'MAP');
  return (
    <>
      {/*<FieldDecorations formik={formik} metadata={metadata}>*/}
      {!isEmpty(sortedEntries) && (
        <ul className="tw-mt-3 tw-w-full tw-list-none">
          {map(sortedEntries, entry => {
            return (
              <ResidentMapEntry
                key={entry[0]}
                collapsedByDefault={collapsedByDefault}
                disabled={disabled}
                entryActions={entryActions}
                entryKey={entry[0]}
                entryValue={entry[1]}
                formik={formik}
                metadata={metadata}
              />
            );
          })}
        </ul>
      )}
      {isEmpty(mapValue) && emptyMessage && (
        <div className="tw-my-3 tw-w-full tw-rounded tw-border tw-bg-gray-100 tw-p-3 tw-text-center tw-font-medium tw-text-gray-500">
          {emptyMessage}
        </div>
      )}

      {!isEmpty(mapActions) && (
        <ResidentMapActions
          disabled={disabled}
          formik={formik}
          mapActions={mapActions}
          mapValue={mapValue}
          metadata={metadata}
        />
      )}
    </>
    // </FieldDecorations>
  );
};

export interface ResidentMapFieldProps {
  disabled?: boolean;
  formik?: IFormik;
  metadata?: IMetadataFieldComponent & {
    attributes?: {
      /** Whether or not the entries are collapsed by default. */
      collapsedByDefault?: boolean;
      /** Empty message displayed when no entries found. */
      emptyMessage?: string;
    };
  };
}

const ResidentMapEntry = ({
  collapsedByDefault = false,
  disabled,
  entryActions,
  entryKey,
  entryValue,
  formik,
  metadata
}) => {
  const [isOpen, setIsOpen] = useState(!collapsedByDefault);
  const headers = filter(metadata.components, isColumnComponent);
  const entryActionProps = {
    disabled,
    entryKey,
    entryValue,
    formik,
    metadata
  };
  return (
    <li
      id={'bulk-scan-entry-' + entryKey}
      className="tw-mb-3 tw-flex tw-flex-col tw-rounded tw-border tw-border-gray-400 tw-shadow"
    >
      <div className="tw-flex tw-w-full tw-flex-1 tw-items-center tw-justify-between tw-rounded-t tw-border-b tw-border-gray-400 tw-bg-gray-100 tw-px-3 tw-py-2">
        <div className="tw-w-3/4 tw-flex-initial tw-truncate tw-font-medium tw-text-gray-700 ">
          {entryKey}
        </div>
        <div className="tw-flex tw-flex-initial tw-items-center">
          {!isEmpty(entryActions) && (
            <ResidentMapEntryActions
              actions={entryActions}
              actionProps={entryActionProps}
            />
          )}
        </div>
      </div>
      <AnimateHeight duration={500} height={isOpen ? 'auto' : 0}>
        <div className="tw-flex tw-w-full tw-flex-auto tw-flex-col tw-p-3">
          {map(headers, (header: IMetadata, headerIndex) => (
            <>
              {evaluateConditionals(header.conditionals, entryValue) && (
                <div
                  className="tw-flex tw-justify-between tw-py-1"
                  key={headerIndex}
                >
                  <span className="tw-font-medium tw-text-gray-700">
                    {header.label}
                  </span>
                  <ColumnTypeRenderer
                    disabled={disabled}
                    header={header}
                    headerIndex={headerIndex}
                    row={entryValue}
                    rowIndex={entryKey}
                  />
                </div>
              )}
            </>
          ))}
        </div>
      </AnimateHeight>
    </li>
  );
};

const ResidentMapActions = ({
  disabled,
  formik,
  mapActions,
  mapValue,
  metadata
}) => {
  const mapActionProps = {
    disabled,
    formik,
    mapValue,
    metadata
  };
  const actionElements = mapActions.map(
    ({ actionComponent: ActionComponent, actionDefinition }, index) => (
      <ActionComponent
        key={`${actionDefinition.placement}-${actionDefinition.type}-${index}`}
        actionDefinition={actionDefinition}
        {...mapActionProps}
      />
    )
  );
  return (
    <div className="tw-flex tw-flex-row tw-items-center">{actionElements}</div>
  );
};

const ResidentMapEntryActions: React.FC<ResidentMapEntryActionsProps> = ({
  actions,
  actionProps
}) => {
  const actionElements = actions.map(
    ({ actionComponent: ActionComponent, actionDefinition }, index) => (
      <span className="tw-pl-2">
        <ActionComponent
          key={`${actionDefinition.placement}-${actionDefinition.type}-${index}`}
          actionDefinition={actionDefinition}
          {...actionProps}
        />
      </span>
    )
  );
  return <>{actionElements}</>;
};

interface ResidentMapEntryActionsProps {
  actions?: Array<any>;
  actionProps: object;
}

export default ResidentMapField;
