/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import NavMenu from './NavMenu';
import NavMobileToggle from './NavMobileToggle';
import NavError from './NavError';
import { useNavigationCache } from '@broadleaf/admin-components/dist/view';
import { LOCALE_CONTEXT_PARAM } from '@broadleaf/admin-components/dist/form/constants/TranslateModeProperties';
import { ContextParameters } from '@broadleaf/admin-components/dist/tracking/utils/ContextRequest';
import { hooks, contexts, unstable } from '@broadleaf/admin-components';
import useNavigationService from './hooks/useNavigationService';
import useTracking from './hooks/useTracking';

const Navigation = () => {
  const navCache = useNavigationCache();
  const [menuCollapsed, setMenuCollapsed] = useState(navCache.get('collapsed'));

  // use an effect to persist to cache anytime it changes
  useEffect(() => {
    navCache.set('collapsed', menuCollapsed);
  }, [menuCollapsed, navCache]);

  const { currentLocale } = useContext(contexts.I18nContext);
  const tracking = useTracking();
  const context = useMemo(
    () => ({
      [LOCALE_CONTEXT_PARAM]: currentLocale,
      [ContextParameters.TRACKING]: tracking
    }),
    [currentLocale, tracking]
  );
  const [
    isLoading,
    isError,
    isSuccess,
    modules,
    fetchNavigation
  ] = useNavigationService(context);
  const [filter, setFilter] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <React.Fragment>
      <div
        id="NavMenuWrapper"
        className={cx(
          'tw-absolute tw-z-20 tw-h-screen tw-w-screen tw-transform tw-pt-16 tw-shadow-xl sm:tw-z-20 sm:tw-w-64',
          {
            'tw--translate-x-full tw-transition-all tw-duration-200 tw-ease-out': !menuOpen,
            'tw-translate-x-0 tw-transition-all tw-duration-200 tw-ease-in': menuOpen,
            'lg:tw-w-64': !menuCollapsed,
            'lg:tw-w-16': menuCollapsed
          }
        )}
      >
        <div className={'tw-flex tw-h-full tw-flex-col tw-bg-gray-900'}>
          {isError && (
            <div
              className={cx('tw-flex tw-items-center tw-justify-center', {
                'tw-px-2': menuCollapsed,
                'tw-px-4': !menuCollapsed
              })}
            >
              <NavError fetchNavigation={fetchNavigation} />
            </div>
          )}

          {isSuccess && (
            <div
              className={cx(
                'tw-my-4 tw-flex tw-items-center tw-justify-center',
                {
                  'tw-px-2': menuCollapsed,
                  'tw-px-4': !menuCollapsed
                }
              )}
            >
              <unstable.ApplicationSelector
                isCollapsed={menuCollapsed}
                setCollapsed={setMenuCollapsed}
              />
            </div>
          )}

          {isSuccess && (
            <NavMenu
              filter={filter}
              isLoading={isLoading}
              modules={modules}
              setFilter={setFilter}
              setMenuOpen={setMenuOpen}
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            />
          )}
        </div>
      </div>
      {menuOpen && (
        <div
          className="tw-absolute tw-z-10 tw-h-full tw-w-full tw-bg-gray-600 tw-opacity-50"
          onClick={() => setMenuOpen(false)}
        ></div>
      )}
      <NavMobileToggle menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
    </React.Fragment>
  );
};

Navigation.propTypes = {
  /** The location object, typically provided by withRouter or a Route. */
  location: PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    state: PropTypes.object
  })
};

export default Navigation;
