/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { includes } from 'lodash';

import log from '@broadleaf/admin-components/dist/common/utils/log';
import useQuery from '../../../../hooks/useQuery';
import {
  IMetadata,
  IMetadataAction
} from '@broadleaf/admin-components/dist/types/metadata';
import { IFulfillmentViewLocalState } from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/fulfillment';
import { getEndpointByType } from '@broadleaf/admin-components/dist/metadata/utils/MetadataUtils';
import { request } from '@broadleaf/admin-components/dist/metadata/utils/request';
import useContextParams from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/hooks/useContextParams';
import { queueFetch } from '@broadleaf/admin-components/dist/oms/hooks/useFufillmentState';
import RmFulfillmentAction from '../../components/RmFulfillmentAction';
import cx from 'classnames';
import { hasPackingSlipBeenPrinted } from '../../../utils/RmFulfillmentUtils';

const logger = log.getLogger(
  'oms.components.FulfillmentView.action-components.PrintPackingSlip'
);

export interface PrintPackingSlipProps {
  actionDefinition: IMetadataAction;
  dispatch: Function;
  metadata: IMetadata;
  state: IFulfillmentViewLocalState;
}

const PrintPackingSlip: FC<PrintPackingSlipProps> = props => {
  const { actionDefinition, dispatch, state, metadata } = props;
  const { attributes = {}, label } = actionDefinition;
  const { allowedStatuses = [] } = attributes as Record<string, any>;

  const packingSlipEndpoint = getEndpointByType(
    actionDefinition,
    'PRINT_PACKING_SLIP'
  );

  const contextParams = useContextParams(metadata, state);

  const packingSlip = useQuery().has('packingSlip');
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = useCallback(async () => {
    try {
      setLoading(true);
      if (!packingSlip) {
        history.push(`${window.location.pathname}?packingSlip=true&print=true`);
      }
      await request({ method: 'post', ...packingSlipEndpoint }, contextParams);
    } finally {
      setLoading(false);
      dispatch(queueFetch());
    }
  }, [contextParams, dispatch, packingSlip, state.data]);

  if (!includes(allowedStatuses, state.data.status)) {
    return <></>;
  }

  let actionTextColor = 'gray';
  const hasPrintedPackingSlip = hasPackingSlipBeenPrinted(state.data);
  if (!hasPrintedPackingSlip) {
    actionTextColor = 'black';
  }

  return (
    <div
      className={cx('tw-w-full', {
        'tw-order-first': !hasPrintedPackingSlip
      })}
    >
      <RmFulfillmentAction
        disabled={state.isFetching || loading}
        color={actionTextColor}
        onClick={handleClick}
      >
        {label}
      </RmFulfillmentAction>
    </div>
  );
};

export default PrintPackingSlip;
export { PrintPackingSlip };
