/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

/**
 * A higher-order field component to display product data
 *
 * @param {ReactComponent} DisplayComponent React component accepting `error`, `isLoading`, and `productInfo` props
 * @returns {ReactComponent}
 */
const ProductInfoField = DisplayComponent => {
  /**
   * Field component to display information about a product.
   * This component is responsible for the API call logic, passing data to a display component
   *
   * @param {Object} props.formik   Formik form state object
   * @param {Object} props.metadata Broadleaf metadata object
   * @returns {ReactComponent}
   */
  const ProductInfoFieldDisplay = ({ formik }) => {
    const product = formik.values.product;

    return (
      <DisplayComponent
        error={null}
        isLoaded={product != null}
        productInfo={product}
      />
    );
  };

  return ProductInfoFieldDisplay;
};

export default ProductInfoField;
