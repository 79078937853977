/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { Component } from 'react';
import Button from '@broadleaf/admin-components/dist/common/elements/Button';
import {
  Barcode,
  BarcodePicker as ScanditSDKBarcodePicker,
  configure,
  ScanSettings,
  Parser
} from 'scandit-sdk';
import cx from 'classnames';
import { Environment } from '@broadleaf/admin-components/dist/common';

function getScanditLicense() {
  return (
    Environment.get('barcode.scandit.license', '') ||
    import.meta.env.VITE_BARCODE_SCANDIT_LICENSE
  );
}
function isScanditParserEnabled() {
  return (
    (Environment.get('barcode.scandit.parser.enabled', '') ||
      import.meta.env.VITE_BARCODE_SCANDIT_PARSER_ENABLED) === 'true'
  );
}

function formatDate(parsedDate) {
  let formattedDate = undefined;
  if (parsedDate && parsedDate.month) {
    formattedDate =
      parsedDate?.year + '-' + String(parsedDate?.month).padStart(2, '0');
  }
  if (parsedDate && parsedDate.day) {
    formattedDate += '-' + String(parsedDate.day).padStart(2, '0');
  }
  return formattedDate;
}
// Configure the library and activate it with a license key
const configurationPromise = configure(getScanditLicense(), {
  engineLocation: 'scandit-build'
}).catch(error => {
  console.log(error);
});

const getDefaultScanSettings = () => {
  const scanSettings = new ScanSettings({
    enabledSymbologies: [
      Barcode.Symbology.CODE128,
      Barcode.Symbology.DATA_MATRIX,
      Barcode.Symbology.GS1_DATABAR,
      Barcode.Symbology.GS1_DATABAR_EXPANDED,
      Barcode.Symbology.INTERLEAVED_2_OF_5
    ],
    maxNumberOfCodesPerFrame: 1,
    searchArea: { x: 0, y: 0.3, width: 1, height: 0.4 },
    codeDuplicateFilter: 1500
  });
  scanSettings
    .getSymbologySettings(Barcode.Symbology.INTERLEAVED_2_OF_5)
    .setActiveSymbolCountsRange(6, 50);
  scanSettings
    .getSymbologySettings(Barcode.Symbology.CODE128)
    .setActiveSymbolCountsRange(6, 50);
  scanSettings
    .getSymbologySettings(Barcode.Symbology.DATA_MATRIX)
    .setColorInvertedEnabled(true);
  return scanSettings;
};

const isValidBarcode = (barcode: Barcode) => {
  return (
    barcode.data && (barcode.isGs1DataCarrier || barcode.data.startsWith('01'))
  );
};

const isPrimaryBarcode = (barcode: Barcode) => {
  return barcode.data.startsWith('01');
};

class ScanditBarcodePicker extends Component<BarcodePickerProps> {
  state: BarcodePickerState = {
    isReady: false,
    isPaused: this.props.scanningPaused,
    savedPrimaryDI: null
  };
  static defaultProps = {
    videoFit: ScanditSDKBarcodePicker.ObjectFit.COVER,
    scanSettings: getDefaultScanSettings(),
    engineLocation: 'scandit-build',
    playSoundOnScan: true
  };
  private ref: React.RefObject<HTMLDivElement>;
  private barcodePicker: ScanditSDKBarcodePicker;
  private gs1Parser: Parser;
  private hibcParser: Parser;

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = { ...this.state, isPaused: this.props.scanningPaused };
  }

  componentDidMount() {
    configurationPromise.then(() => {
      ScanditSDKBarcodePicker.create(this.ref.current, this.props).then(
        barcodePicker => {
          this.barcodePicker = barcodePicker;
          try {
            if (isScanditParserEnabled()) {
              this.gs1Parser = barcodePicker.createParserForFormat(
                Parser.DataFormat.GS1_AI
              );
              this.gs1Parser.setOptions({
                strictMode: false,
                allowHumanReadableCodes: true,
                outputHumanReadableString: true
              });
              this.hibcParser = barcodePicker.createParserForFormat(
                Parser.DataFormat.HIBC
              );
            }
          } catch (e) {
            console.error('Unable to configure Scandit Parser', e);
          }
          this.setState({ isPaused: this.props.scanningPaused, isReady: true });
          if (this.props.onScan != null) {
            barcodePicker.on('scan', async result => {
              console.log('Scanned: ', result);
              const barcode = result.barcodes[0];
              if (!isValidBarcode(barcode)) {
                result.rejectCode(barcode);
                console.log('Rejected because not GS1: ', barcode);
                return;
              }
              if (
                this.state.savedPrimaryDI == null &&
                !isPrimaryBarcode(barcode)
              ) {
                result.rejectCode(barcode);
                console.log('Rejected because not PrimaryDI: ', barcode);
                return;
              }
              if (
                this.state.savedPrimaryDI != null &&
                isPrimaryBarcode(barcode)
              ) {
                result.rejectCode(barcode);
                console.log(
                  'Rejected because not Secondary Barcode: ',
                  barcode
                );
                return;
              }

              this.barcodePicker.pauseScanning();
              this.setState({ isPaused: true });

              if (
                isPrimaryBarcode(barcode) &&
                barcode.data.length >= 14 &&
                barcode.data.length <= 16
              ) {
                this.setState({
                  savedPrimaryDI: {
                    productInfo: {
                      di: barcode.data.substring(2)
                    },
                    barcode
                  }
                });
                return;
              }

              let parsedGs1ProductInfo = null;
              try {
                if (isScanditParserEnabled()) {
                  // const parsedGs1 = await this.gs1Parser.parseRawData(result.barcodes[0]?.rawData);
                  // using string parsing vs raw data because for some reason we're missing the last character off of the lot
                  const parsedGs1 = await this.gs1Parser.parseString(
                    result.barcodes[0]?.data
                  );
                  if (parsedGs1 && parsedGs1.fields.length > 0) {
                    const parseIssues = parsedGs1.fields.flatMap(
                      // @ts-ignore
                      field => field.issues || []
                    );
                    if (parseIssues != null && parseIssues.length > 0) {
                      result.rejectCode(barcode);
                      console.log(
                        'Issues parsing GS1: ',
                        parseIssues?.join(',')
                      );
                      return;
                    }
                    parsedGs1ProductInfo = {
                      di: parsedGs1.fieldsByName['01']?.parsed['GTIN'],
                      serialNumber: parsedGs1.fieldsByName['21']?.parsed,
                      expirationDate: formatDate(
                        parsedGs1.fieldsByName['17']?.parsed
                      ),
                      manufacturingDate: formatDate(
                        parsedGs1.fieldsByName['11']?.parsed
                      ),
                      lotNumber: parsedGs1.fieldsByName['10']?.parsed,
                      quantity: parsedGs1.fieldsByName['30']?.parsed
                    };
                    if (this.state.savedPrimaryDI != null) {
                      parsedGs1ProductInfo = {
                        ...parsedGs1ProductInfo,
                        ...this.state.savedPrimaryDI.productInfo
                      };
                      this.setState({ savedPrimaryDI: null });
                    } else if (
                      // if we don't detect a lot/serial or expire prompt for second barcode
                      (parsedGs1ProductInfo.lotNumber == null &&
                        parsedGs1ProductInfo.serialNumber == null) ||
                      parsedGs1ProductInfo.expirationDate == null
                    ) {
                      this.setState({
                        savedPrimaryDI: {
                          productInfo: parsedGs1ProductInfo,
                          barcode
                        }
                      });
                      return;
                    }
                  }
                }
              } catch (e) {
                console.error('Unable to parse GS1 using Scandit', e);
              }
              this.props.onScan({
                ...result,
                barcodes: [
                  this.state.savedPrimaryDI?.barcode,
                  ...result.barcodes
                ],
                parsedGs1ProductInfo
              });
            });
          }
          if (this.props.onError != null) {
            barcodePicker.on('scanError', this.props.onError);
          }
        }
      );
    });
  }

  componentWillUnmount() {
    if (this.barcodePicker != null) {
      this.barcodePicker.destroy();
    }
  }

  componentDidUpdate(prevProps) {
    // These are just some examples of how to react to some possible property changes

    if (
      JSON.stringify(prevProps.scanSettings) !==
      JSON.stringify(this.props.scanSettings)
    ) {
      this.barcodePicker.applyScanSettings(this.props.scanSettings);
    }

    if (prevProps.visible !== this.props.visible) {
      this.barcodePicker.setVisible(this.props.visible);
    }

    if (prevProps.visible !== this.props.visible) {
      this.barcodePicker.setVisible(this.props.visible);
    }
  }

  render() {
    return (
      <>
        <div className={'revmed-scandit-barcode-picker tw-relative'}>
          {this.state.isPaused && (
            <div
              className={
                'overlay tw-absolute tw-z-20 tw-h-full tw-w-full tw-bg-gray-600 tw-opacity-50'
              }
            ></div>
          )}
          <div
            className={'revmed-scandit-barcode-picker-container'}
            ref={this.ref}
          ></div>
        </div>
        {this.props.showPauseButton && this.barcodePicker && (
          <>
            {this.state.savedPrimaryDI && (
              <div className="tw-mt-4 tw-w-full tw-text-center">
                Detected Device {this.state.savedPrimaryDI.productInfo.di} but
                need to scan secondary barcode for{' '}
                {!this.state.savedPrimaryDI.productInfo.lotNumber &&
                !this.state.savedPrimaryDI.productInfo.serialNumber &&
                !this.state.savedPrimaryDI.productInfo.expirationDate ? (
                  <>Lot/Expiration</>
                ) : !this.state.savedPrimaryDI.productInfo.lotNumber &&
                  !this.state.savedPrimaryDI.productInfo.serialNumber ? (
                  <>Lot Number</>
                ) : !this.state.savedPrimaryDI.productInfo.expirationDate ? (
                  <>Expiration</>
                ) : (
                  <></>
                )}
              </div>
            )}
            <div className="tw-mt-4 tw-flex tw-w-full tw-items-center">
              <Button
                className="tw-mx-auto"
                color="primary"
                size="lg"
                onClick={() => {
                  if (this.state.isPaused) {
                    this.barcodePicker.resumeScanning();
                  } else {
                    this.barcodePicker.pauseScanning();
                  }
                  this.setState({ isPaused: !this.state.isPaused });
                }}
              >
                {this.state.isPaused ? 'Start' : 'Pause'} Scanning{' '}
                {this.state.savedPrimaryDI ? 'Secondary Barcode' : ''}
              </Button>
            </div>
            {this.state.savedPrimaryDI && (
              <div className="tw-mt-4 tw-w-full">
                <div className="tw-text-center">
                  Can't find a secondary barcode?
                </div>
                <div className="tw-mt-2 tw-flex tw-items-center">
                  <Button
                    className="tw-ml-auto"
                    color="primary"
                    size="sm"
                    onClick={() => {
                      this.props.onScan({
                        barcodes: [this.state.savedPrimaryDI],
                        parsedGs1ProductInfo: this.state.savedPrimaryDI
                          .productInfo
                      });
                      this.setState({ savedPrimaryDI: null });
                    }}
                  >
                    Save Anyway
                  </Button>
                  <Button
                    className="tw-ml-2 tw-mr-auto"
                    color="danger"
                    size="sm"
                    onClick={() => {
                      this.setState({ savedPrimaryDI: null });
                    }}
                  >
                    Cancel Scan
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

type BarcodePickerState = {
  isPaused: boolean;
  isReady: boolean;
  savedPrimaryDI: SavedPrimaryDI;
};
type SavedPrimaryDI = {
  productInfo: ParsedGS1ProductInfo;
  barcode: Barcode;
};
type ParsedGS1ProductInfo = {
  di: string;
  serialNumber?: string;
  expirationDate?: string;
  manufacturingDate?: string;
  lotNumber?: string;
  quantity?: string;
};
type BarcodePickerProps = typeof ScanditBarcodePicker.defaultProps & {
  visible?: boolean;
  playSoundOnScan?: boolean;
  vibrateOnScan?: boolean;
  scanningPaused?: boolean;
  showPauseButton?: boolean;
  guiStyle?: ScanditSDKBarcodePicker.GuiStyle;
  videoFit?: ScanditSDKBarcodePicker.ObjectFit;
  scanSettings?: any;
  enableCameraSwitcher?: boolean;
  enableTorchToggle?: boolean;
  enableTapToFocus?: boolean;
  enablePinchToZoom?: boolean;
  accessCamera?: boolean;
  camera?: any;
  cameraSettings?: any;
  targetScanningFPS?: number;
  className?: string;
  onScanStart?: (event: any) => void;
  onScan?: (event: any) => void;
  onError?: (event: any) => void;
};

export default ScanditBarcodePicker;
export { getDefaultScanSettings, ScanditBarcodePicker };
