/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useState } from 'react';
import { useFetchNihProduct } from './hooks/use-fetch-nih-product';
import { useFetchBlcProduct } from './hooks/use-fetch-blc-product';

import { uniq } from 'lodash';
import ScanditBarcodePicker from '../../barcode/ScanditBarcodePicker';
import { useTracking } from '@broadleaf/admin-components/dist/tracking';

function getUnknownDeviceIdFailureMessage(deviceId) {
  return `No product found with Device ID: ${deviceId}. Please contact RevMed for further assistance`;
}

function getPleaseTryAgainErrorMessage() {
  return 'Invalid scan was detected. Please try again.';
}

function getUnableToParseErrorMessage(barcode) {
  return 'Unable to parse device information from barcode: ' + barcode;
}

function getMultipleBarcodesDetectedMessage() {
  return 'Multiple barcodes were detected';
}

const getUdi = scans => {
  if (!(scans instanceof Array)) {
    return { error: getPleaseTryAgainErrorMessage() };
  }

  const barcodes = uniq(
    scans.filter(scan => !!scan?.data).map(scan => scan.data)
  );
  if (barcodes.length === 0) {
    return { error: getPleaseTryAgainErrorMessage() };
  }
  if (barcodes.length === 1) {
    return { udi: barcodes[0] };
  }
  if (barcodes.length === 2) {
    const code1 = barcodes[0];
    const code2 = barcodes[1];
    const applicationIdentifier1 = code1.substr(0, 2);
    const applicationIdentifier2 = code2.substr(0, 2);

    // Check both don't include the same application identifier (generally GTIN first)
    if (applicationIdentifier1 !== applicationIdentifier2) {
      if (applicationIdentifier1.localeCompare(applicationIdentifier2) < 0) {
        return { udi: code1 + code2 };
      } else {
        return { udi: code2 + code1 };
      }
    }

    // The two barcodes must be for two separate products
    return { error: getMultipleBarcodesDetectedMessage() };
  }
  if (barcodes.length > 2) {
    return { error: getPleaseTryAgainErrorMessage() };
  }
};

/**
 * A higher-order field component to display barcode scanner
 *
 * @param {ReactComponent} DisplayComponent React component accepting `error`, `isLoading`, and `productInfo` props
 * @returns {ReactComponent}
 */
export const ScanditBarcodeScannerField = props => {
  const { formik, metadata } = props;
  const [error, setError] = useState(null);
  const [isBarcodeScanningInProcess, setIsBarcodeScanningInProcess] = useState(
    false
  );
  const tracking = useTracking(metadata);
  const contextParams = {
    tracking
  };
  const { getProductInfoByUdi } = useFetchNihProduct();
  const { getProductByDeviceID } = useFetchBlcProduct();

  const onCaptureBarcode = async function (productData) {
    const { product, productInfo } = productData;

    if (productInfo != null) {
      if (productInfo.expirationDate != null) {
        formik.setFieldValue('expirationDate', productInfo.expirationDate);
      }
      if (productInfo.manufacturingDate != null) {
        formik.setFieldValue(
          'manufacturingDate',
          productInfo.manufacturingDate
        );
      }
      if (productInfo.lotNumber != null) {
        let lotNumber = productInfo.lotNumber;
        if (productInfo.serialNumber != null) {
          lotNumber = lotNumber + '21' + productInfo.serialNumber;
          console.log(
            'Both Lot Number and Serial Number found. Assuming this was a bad parse where 21 was in the ' +
              'lotNumber. Combining to full lotNumber: ' +
              lotNumber
          );
        }
        formik.setFieldValue('lotNumber', lotNumber);
      }
      if (productInfo.serialNumber != null) {
        formik.setFieldValue('serialNumber', productInfo.serialNumber);
      }
      if (productInfo.donationId != null) {
        formik.setFieldValue('donationIdNumber', productInfo.donationId);
      }
      // set the uom option value equal to the device Id
      formik.setFieldValue('optionValues.uom', productInfo.di);
      const isPrimaryDIScanned = product?.upc === productInfo.di;
      formik.setFieldValue(
        'product.attributes.primaryDeviceID.scanned',
        isPrimaryDIScanned ? 'Y' : 'N'
      );
    }
    formik.setFieldValue('product', product);
  };

  const getProductDetails = async (udi, result) => {
    if (!udi && !result.parsedGs1ProductInfo) {
      console.log('No UDI found');
      return { error: getPleaseTryAgainErrorMessage() };
    }

    let productInfo = result.parsedGs1ProductInfo;
    if (!productInfo) {
      productInfo = await getProductInfoByUdi(udi);
      if (!productInfo) {
        console.log('Invalid barcode scanned: ', udi);
        return { error: getUnableToParseErrorMessage(udi) };
      }
    }

    let product = await getProductByDeviceID(productInfo.di, contextParams);

    if (!product) {
      console.log('No BLC product found');
      return { error: getUnknownDeviceIdFailureMessage(productInfo.di) };
    }

    return { product, productInfo };
  };

  const onBarcodeScanned = async result => {
    let scanResult = {};
    try {
      console.log('Scanned: ', result);
      scanResult = getUdi(result.barcodes);
      const { udi } = scanResult;
      console.log('Found UDI:' + udi);
      if (!scanResult.error || result.parsedGs1ProductInfo) {
        setIsBarcodeScanningInProcess(true);
        scanResult = await getProductDetails(udi, result);

        if (!scanResult.error) {
          console.log('Product Data: ', scanResult);
          await onCaptureBarcode(scanResult);
        }
      }
    } catch (e) {
      console.log(e);
      scanResult = { error: getPleaseTryAgainErrorMessage() };
    } finally {
      setIsBarcodeScanningInProcess(false);
      if (scanResult?.error) {
        setError(scanResult.error);
      }
    }
  };
  return (
    <div className="bulk-scan-container tw-mx-auto tw-max-w-3xl">
      <div>
        <ScanditBarcodePicker
          onScan={onBarcodeScanned}
          showPauseButton={true}
          scanningPaused={true}
        />
        <div className="tw-h-8">
          {isBarcodeScanningInProcess && (
            <div className="tw-mt-4 tw-flex tw-w-full tw-items-center">
              <button
                type="button"
                className="tw-mx-auto tw-inline-flex tw-cursor-not-allowed tw-items-center tw-rounded tw-px-4 tw-py-2 tw-text-sm tw-font-semibold tw-leading-6 tw-shadow tw-transition tw-duration-150 tw-ease-in-out"
                disabled=""
              >
                <svg
                  className="tw--ml-1 tw-mr-3 tw-h-5 tw-w-5 tw-animate-spin tw-text-black"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="tw-opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                Processing...
              </button>
            </div>
          )}

          {error && (
            <div
              id="toast-warning"
              className="tw-flex tw-w-full tw-items-center tw-p-2"
              role="alert"
            >
              <div className="tw-dark:text-red-200 tw-dark:bg-red-700 tw-inline-flex tw-h-8 tw-w-8 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-lg tw-bg-red-600 tw-text-red-600">
                <svg
                  className="tw-h-5 tw-w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="tw-mx-3 tw-text-sm tw-font-normal">{error}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default () => {
  return props => {
    return <ScanditBarcodeScannerField {...props} />;
  };
};
