import React from 'react';

/**
 * Exact same component as proivided in AdminWeb, but included here since
 * ToggleSwitch depends on it, and we're overriding ToggleSwitch
 */
export const ToggleSwitchItem: React.FC<ToggleSwitchItemProps> = ({
  children,
  handleKeyPress,
  index
}) => (
  <div
    className="tw-relative tw-z-0 tw-inline-block tw-m-0 focus:tw-outline-none"
    key={index}
    onKeyPress={handleKeyPress}
    tabIndex={index}
  >
    {children}
  </div>
);

interface ToggleSwitchItemProps {
  children?: React.ReactNode;
  handleKeyPress: React.KeyboardEventHandler<HTMLDivElement>;
  index: number;
}

export default ToggleSwitchItem;
