/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import axios from 'axios';

export const useFetchNihProduct = () => {
  const getProductInfoByUdi = async udi => {
    // eslint-disable-next-line no-useless-escape
    const cleanUdi = udi.replace(/[^a-z0-9\+]/gi, '');
    try {
      const response = await axios.get(
        'https://accessgudid.nlm.nih.gov/api/v2/parse_udi.json',
        {
          params: {
            udi: cleanUdi
          }
        }
      );
      const { data } = response;
      if (data && data.donationId) {
        data.donationIdNumber = data.donationId;
      }
      return data;
    } catch (error) {
      console.log(error);
    }

    return false;
  };

  return { getProductInfoByUdi };
};
