/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { useHistory } from 'react-router-dom';
import { hooks, unstable } from '@broadleaf/admin-components/dist';
import { setApplication } from '@broadleaf/admin-components/dist/tracking/actions/tenant';

const DashboardTile: React.FC<DashboardTileProps> = props => {
  const { label, link, isApplication, Icon } = props;
  const history = useHistory();

  const applications = hooks.useAvailableApplications();
  const actions = hooks.useTenantActions({
    queueFetchApplications: unstable.tenantActions.queueFetchApplications,
    setApplication: unstable.tenantActions.setApplication
  });

  return (
    <li className="tw-h-40 tw-w-40 tw-flex-shrink-0 tw-bg-gray-300 tw-p-2 tw-font-semibold tw-duration-100 hover:tw-bg-gray-350">
      <a
        onClick={() => {
          if (!isApplication) {
            actions.setApplication(null);
          } else if (applications?.length > 0) {
            actions.setApplication(applications[0]);
          }
          // setting a timeout because the product page has to wait for sandboxes to load or it will get an error
          setTimeout(() => {
            history.push(link);
          }, 500);
        }}
        className="tw-cursor-pointer hover:tw-no-underline"
      >
        <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-space-y-2">
          <Icon />
          <span className="tw-text-center">{label}</span>
        </div>
      </a>
    </li>
  );
};

type DashboardTileProps = {
  link: string;
  label: string;
  isApplication: boolean;
  Icon: React.FC;
};

export default DashboardTile;
