import { IMetadata } from '@broadleaf/admin-components/dist/types/metadata';
import { useContext } from 'react';
import {
  CatalogContext,
  SandboxContext,
  useTenantContext
} from '@broadleaf/admin-components/dist/tracking';
import { get, isEmpty } from 'lodash';
import { isCatalogDiscriminated } from '@broadleaf/admin-components/dist/tracking/utils/CatalogUtils';
import { isSandboxDiscriminated } from '@broadleaf/admin-components/dist/tracking/utils/SandboxUtils';

export default function useTracking(metadata?: IMetadata) {
  const catalog = useContext(CatalogContext);
  const sandbox = useContext(SandboxContext);
  const [
    { applicationById, applicationId, tenantById, tenantId }
  ] = useTenantContext();

  const tracking: Tracking = {};

  if (tenantId) {
    tracking.tenantId = tenantId;
    tracking.tenant = tenantById[tenantId];
  } else {
    tracking.tenantId = null;
    tracking.tenant = null;
  }

  if (applicationId) {
    tracking.applicationId = applicationId;
    tracking.application = applicationById[applicationId];
  } else {
    tracking.applicationId = null;
    tracking.application = null;
  }

  const customerContextId = get(tracking, 'application.customerContextId');
  if (customerContextId) {
    tracking.customerContextId = customerContextId;
  } else {
    tracking.customerContextId = null;
  }

  if (isEmpty(metadata) || isCatalogDiscriminated(metadata)) {
    tracking.catalog = catalog;
  }

  if (isEmpty(metadata) || isSandboxDiscriminated(metadata)) {
    tracking.sandbox = sandbox;
  }

  return tracking;
}

type Tracking = {
  tenantId?: string;
  tenant?: any;
  applicationId?: string;
  application?: any;
  customerContextId?: string;
  catalog?: any;
  sandbox?: any;
};
