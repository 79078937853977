/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useState } from 'react';

import useEventCallback from '@broadleaf/admin-components/dist/common/hooks/useEventCallback';
import { ModalForm } from '../components/ModalForm';
import IconButton from '@broadleaf/admin-components/dist/common/components/IconButton';
import { getAttribute } from '@broadleaf/admin-components/dist/metadata/utils/MetadataUtils';

import EntryAction from '@broadleaf/admin-components/dist/form/named-components/field-types/ResidentMapField/action-components/components';

const ResidentMapFieldUpdate: React.FC<ResidentMapFieldUpdateProps> = ({
  actionDefinition,
  disabled,
  formik,
  entryKey,
  entryValue,
  metadata
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleSubmit = useEventCallback(
    async values => {
      const entryPath = `${metadata.name}.${entryKey}`;
      formik.setFieldValue(entryPath, values);
    },
    [entryKey, formik.setFieldValue, metadata.name]
  );
  return (
    <>
      <IconButton
        className="ml-1 update-button"
        color="secondary-clear"
        disabled={disabled}
        label={actionDefinition.label}
        name="edit"
        onClick={() => setIsOpen(true)}
        type="button"
      />
      {isOpen && (
        <ModalForm
          key={actionDefinition.type}
          components={actionDefinition.components}
          closeOnSubmit
          initialValues={entryValue}
          isOpen
          onClose={() => setIsOpen(false)}
          onSubmit={handleSubmit}
          submitLabel={getAttribute(actionDefinition, 'submitLabel')}
          title={actionDefinition.label}
        />
      )}
    </>
  );
};

export interface ResidentMapFieldUpdateProps extends IEntryActionProps {}

export default ResidentMapFieldUpdate;
