/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';
import SVG from '@broadleaf/admin-components/dist/common/components/SVG';

const NavMobileToggle = ({ menuOpen, setMenuOpen }) => {
  return (
    <button
      className="tw-absolute tw-z-30 tw-flex tw-items-center tw-justify-around tw-bg-gray-900 tw-p-4 print:tw-hidden"
      style={{ width: '60px', height: '60px' }}
      onClick={() => setMenuOpen(menuOpen => !menuOpen)}
    >
      {!menuOpen ? (
        <SVG
          name="menu"
          className="tw-h-4 tw-w-4 tw-fill-current tw-text-gray-100"
        />
      ) : (
        <SVG
          name="close"
          className="tw-h-4 tw-w-4 tw-fill-current tw-text-gray-100"
        />
      )}
    </button>
  );
};

NavMobileToggle.propTypes = {
  /** The function responsible for toggling the menu **/
  setMenuOpen: PropTypes.func.isRequired
};

export default NavMobileToggle;
