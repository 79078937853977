/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';

import messages from './Header.messages';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import { Dropdown } from '@broadleaf/admin-components/dist/common';

const UserSettingsDropdown = props => {
  const formatMessage = useFormatMessage();
  const { onChangePasswordClick, onLogoutClick } = props;
  const user = props.user || { username: 'Anonymous' };
  const itemClassName =
    'tw-bg-gray-800 tw-text-gray-400 tw-border-t tw-border-gray-700 hover:tw-bg-gray-700 hover:tw-text-gray-100 tw-truncate';
  return (
    <Dropdown>
      <Dropdown.Menu.Trigger
        className="tw-group tw-w-full tw-truncate tw-bg-gray-800 tw-text-gray-400 hover:tw-bg-gray-700 hover:tw-text-gray-100"
        color={null}
      >
        {user.username}
      </Dropdown.Menu.Trigger>
      <Dropdown.Menu>
        <Dropdown.Menu.Item
          className={itemClassName}
          onClick={onChangePasswordClick}
        >
          {formatMessage(messages.changePassword)}
        </Dropdown.Menu.Item>
        <Dropdown.Menu.Item className={itemClassName} onClick={onLogoutClick}>
          {formatMessage(messages.logout)}
        </Dropdown.Menu.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

UserSettingsDropdown.propTypes = {
  /** the currently logged in user's context */
  user: PropTypes.shape({
    /** the current user's username */
    username: PropTypes.string
  })
};

export default UserSettingsDropdown;
