/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import cx from 'classnames';
import { filter, find, isEmpty, map } from 'lodash';
import useVisibleActionDefinitions from '@broadleaf/admin-components/dist/metadata/hooks/useVisibleActionDefinitions';
import Totals from './Totals';
import useQuery from '../../../hooks/useQuery';
import { IFulfillmentViewCommon } from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/fulfillment';
import FulfillmentViewSection from './FulfillmentSection';
export const FulfillmentViewMain: React.FC<FulfillmentViewMainProps> = ({
  actionComponents,
  className,
  components,
  dispatch,
  metadata,
  state
}) => {
  if (state.isFetchError) {
    return (
      <components.Error
        actionComponents={actionComponents}
        className={className}
        components={components}
        dispatch={dispatch}
        metadata={metadata}
        state={state}
      />
    );
  }
  return (
    <components.Layout
      actionComponents={actionComponents}
      className={className}
      components={components}
      dispatch={dispatch}
      metadata={metadata}
      state={state}
    >
      <FulfillmentViewSection
        actionComponents={actionComponents}
        components={components}
        dispatch={dispatch}
        metadata={metadata}
        state={state}
      />
    </components.Layout>
  );
};

export interface FulfillmentViewMainProps extends IFulfillmentViewCommon {
  className?: string;
}

export default FulfillmentViewMain;
