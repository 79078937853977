/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext } from 'react';
import { find, get } from 'lodash';

import ActionListGrid from '@broadleaf/admin-components/dist/collection/components/ActionListGrid';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import useRefreshEffect from '@broadleaf/admin-components/dist/common/hooks/useRefreshEffect';
import useTracking from '@broadleaf/admin-components/dist/tracking/hooks/useTracking';
import ActionRegistrar from '@broadleaf/admin-components/dist/metadata/services/ActionRegistrar';
import useInvoicesGrid from '../../hooks/useInvoicesGrid';
import { getFetchInvoicesEndpoint } from '../../utils/InvoicesOperationUtils';

import messages from './OtherInvoices.messages';
import type { ICommonGridHeader } from '@broadleaf/admin-components/dist/types/common';
import actionComponents from './action-components';
import { getDefaultInvoiceHeaders } from '../../utils/InvoicesGridUtils';
import ActionListGridQueryFilter from '@broadleaf/admin-components/dist/collection/components/ActionListGrid/action-components/ActionListGridQueryFilter';

export const OtherInvoicesGrid: ReadyToSubmitGridDefinition = () => {
  const { collectionState, getActionListGridProps } = useInvoicesGrid({
    readConfig: getFetchInvoicesEndpoint(),
    invoiceStatuses: [
      'ORDER_PROCESSING',
      'SUBMITTED',
      'REIMBURSED',
      'REJECTED',
      'INVOICE_PAID',
      'DISPUTED',
      'READY_TO_SUBMIT',
      'HOLD_FOR_REVIEW',
      'REQUIRES_PO',
      'CREDIT_PENDING'
    ],
    excludeInvoiceStatuses: true
  });

  const actionListGridProps = getActionListGridProps();
  const actionComponents = ActionRegistrar.getActionComponents(
    'View',
    OtherInvoicesGrid.VIEW_TYPE
  );
  const actionDefinitions = useReadyToSubmitActionDefinitions();
  const headers = useInvoiceHistoryHeaders();
  const label = useLabel();
  return (
    <ActionListGrid
      {...actionListGridProps}
      actionComponents={actionComponents}
      actionDefinitions={actionDefinitions}
      headers={headers}
      label={label}
      sortable={true}
    />
  );
};

interface ReadyToSubmitGridDefinition extends React.FC {
  VIEW_TYPE: string;
  actionComponents: any;
}

OtherInvoicesGrid.VIEW_TYPE = 'OTHER_INVOICES_GRID';
OtherInvoicesGrid.actionComponents = actionComponents;

const useInvoiceHistoryHeaders = (): Array<ICommonGridHeader> => {
  const formatMessage = useFormatMessage();
  return (
    [
      ...getDefaultInvoiceHeaders(formatMessage),
      {
        classifier: 'Field',
        label: formatMessage(messages.headers.invoiceDate),
        name: 'invoiceDate',
        type: 'STRING',
        sortable: true,
        order: 350
      }
    ] as ICommonGridHeader[]
  ).sort((a, b) => get(a, 'order', 0) - get(b, 'order', 0));
};

const useReadyToSubmitActionDefinitions = () => {
  const formatMessage = useFormatMessage();
  return [
    {
      label: formatMessage(messages.gridActions.viewDetails),
      placement: 'GRID',
      type: 'VIEW_DETAILS'
    },
    {
      attributes: {
        filterKey: 'query'
      },
      label: formatMessage(messages.gridActions.criteriaQuery),
      placement: 'QUERY_FILTER',
      type: 'QUERY'
    },
    {
      attributes: {
        filterKey: 'query_builder'
      },
      label: formatMessage(messages.gridActions.criteriaQuery),
      placement: 'ADVANCED_FILTER',
      type: 'QUERY_BUILDER'
    }
  ];
};

/** Returns a label for the grid with the current sandbox name included. */
const useLabel = () => {
  const tracking = useTracking();
  const formatMessage = useFormatMessage();

  return formatMessage(messages.label);
};

export default OtherInvoicesGrid;
