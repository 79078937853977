import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { join } from '@broadleaf/admin-components/dist/common/utils/path';
import { ICommonOrderViewProps } from '@broadleaf/admin-components/dist/types/omsMetadata';
import { getComponents } from '@broadleaf/admin-components/dist/metadata/utils/MetadataUtils';
import { useAccessForSections } from '@broadleaf/admin-components/dist/oms/components';
import { ViewTypeRenderer } from '@broadleaf/admin-components/dist/view';
import { IFulfillmentViewCommon } from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/fulfillment';

export const FulfillmentViewSection: React.FC<FulfillmentViewSectionProps> = ({
  dispatch,
  actionComponents,
  components,
  metadata,
  state
}) => {
  const { url } = useRouteMatch();
  const sections = getComponents(metadata);
  const sectionAccessByIndex = useAccessForSections(sections);
  return (
    <Switch>
      {sections.map((section, index) => {
        if (!sectionAccessByIndex[index]) {
          return null;
        }
        const { exact, path } = section.attributes;
        const joinedPath = join(url, path);
        return (
          <Route key={joinedPath} path={joinedPath} exact={exact}>
            {routeProps =>
              index === 0 ? (
                <components.Body
                  actionComponents={actionComponents}
                  components={components}
                  dispatch={dispatch}
                  metadata={metadata}
                  state={state}
                />
              ) : (
                <ViewTypeRenderer
                  {...routeProps}
                  dispatch={dispatch}
                  metadata={section}
                  orderMetadata={metadata}
                  state={state}
                />
              )
            }
          </Route>
        );
      })}
    </Switch>
  );
};

export interface FulfillmentViewSectionProps extends IFulfillmentViewCommon {
  className?: string;
}

export default FulfillmentViewSection;
