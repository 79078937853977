/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { FormattedMessage } from 'react-intl';
import metadataInvariant from '@broadleaf/admin-components/dist/metadata/utils/MetadataInvariant';
import { get, has, isEmpty, isNil, every } from 'lodash';

import log from '@broadleaf/admin-components/dist/common/utils/log';
import ToggleSwitch from '@broadleaf/admin-components/dist/form/components/ToggleSwitch';
import { FieldDecorations } from '@broadleaf/admin-components/dist/form/helpers/FieldDecorations';
import useTranslateMode from '@broadleaf/admin-components/dist/form/hooks/useTranslateMode';
import { mustHaveMetadataProps } from '@broadleaf/admin-components/dist/form/utils/invariantHelpers';
import { isFieldDisabled } from '@broadleaf/admin-components/dist/metadata/utils/MetadataUtils';

import messages from './EnumSwitchField.messages';
import { IFormik } from '@broadleaf/admin-components/dist/types/form';
import { IMetadataFieldComponent } from '@broadleaf/admin-components/dist/types/metadata';

const logger = log.getLogger(
  'form.named-components.field-types.EnumSwitchField'
);

/**
 * Renders an enumerated ToggleSwitch field.
 *
 * @visibleName ENUM_SWITCH
 */
export const EnumSwitchField: EnumSwitchFieldDefinition = props => {
  logger.warn(
    'Found usage of deprecated `EnumSwitchField` component. Please use `EnumSelectField` instead.'
  );

  const { metadata, formik } = props;
  mustHaveMetadataProps(metadata, ['name']);

  const { name, required } = metadata;
  const translateMode = useTranslateMode();

  let value = get(formik.values, name);
  let options = getOptionMetadata(metadata);

  const includeEmptyOption = !required || isNil(value);

  // contribute "null" value if the field has no value or is not required
  if (includeEmptyOption) {
    options = [EnumSwitchField.EMPTY_OPTION, ...options];

    // if the value is nil, set the value to the "empty" value
    if (isNil(value)) {
      value = EnumSwitchField.EMPTY_OPTION.value;
    }
  }

  metadataInvariant(
    !isEmpty(options),
    `Failed to render a field of type, ${metadata.type}. The required "options" attribute was not provided. This attribute controls the options we display and without any options this component will not function.`,
    metadata
  );

  metadataInvariant(
    every(options, option => has(option, 'label') && has(option, 'value')),
    `Failed to render a field of type, ${
      metadata.type
    }. The "options" attribute expects to have a "label" and "name" for every option, but instead received:\n\n${JSON.stringify(
      options,
      null,
      2
    )}`
  );

  return (
    <FieldDecorations {...props}>
      <ToggleSwitch
        disabled={isFieldDisabled(props, translateMode)}
        name={name}
        options={options}
        onBlur={formik.handleBlur}
        onChange={value => {
          if (value !== EnumSwitchField.EMPTY_OPTION.value) {
            formik.setFieldValue(name, value);
          } else {
            formik.setFieldValue(name, null);
          }
        }}
        value={value}
      />
    </FieldDecorations>
  );
};

export interface EnumSwitchFieldProps {
  /** Flag for if only the input should be returned. This will NOT include: label, hints, or errors. */
  decorated?: boolean;
  /** True if you want to disable the radio buttons, false otherwise. */
  disabled?: boolean;
  /** Reference to Formik instance **/
  formik: IFormik;
  /** Change handler, this will be passed to the onChange event handler. */
  handleChange?: Function;
  /** Blur handler, this will be passed to the onBlur event handler. */
  handleBlur?: Function;
  /** Metadata for the EnumSwitchField including its label, name, and type. */
  metadata: IMetadataFieldComponent;
}

interface EnumSwitchFieldDefinition extends React.FC<EnumSwitchFieldProps> {
  EMPTY_OPTION: {
    color?: string;
    label?: string | React.ReactNode;
    value?: string;
  };
}

EnumSwitchField.EMPTY_OPTION = {
  color: 'danger',
  label: <FormattedMessage {...messages.emptyLabel} />,
  value: '__NONE'
};

const getOptionMetadata = metadata => {
  let options = get(metadata, 'attributes.options', []);

  if (isEmpty(options)) {
    options = get(metadata, 'options', []);
  }

  return options;
};

EnumSwitchField.defaultProps = {
  decorated: true,
  disabled: false,
  metadata: {
    required: false,
    readOnly: false
  }
};

export default EnumSwitchField;
