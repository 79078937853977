/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { filter, get, isEmpty } from 'lodash';

import FormComponents from '@broadleaf/admin-components/dist/form/components/FormComponents';
import { evaluateConditionals } from '@broadleaf/admin-components/dist/common/services/ConditionalHelper';
import { IFormik } from '@broadleaf/admin-components/dist/types/form';
import { IMetadata } from '@broadleaf/admin-components/dist/types/metadata';

const spliceDividerBetweenComponents = (
  components,
  divider,
  disabled,
  formik
) => {
  const componentsToRender = filter(components, metadata =>
    checkShouldRender({ formik, metadata })
  );
  const noComponents = componentsToRender.length;
  const formComponents = componentsToRender.map(
    component => (
      // support nested groups or other as-yet-to-be-invented non-field components
      <FormComponents
        key={component.name + '_fc'}
        components={[component]}
        formik={formik}
        readOnly={disabled}
      />
    ),
    []
  );

  if (divider) {
    for (let i = noComponents - 1; i > 0; i--) {
      formComponents.splice(
        i,
        0,
        <span key={componentsToRender[i].name + '_divider'}>{divider}</span>
      );
    }
  }

  return formComponents;
};

const checkShouldRender = ({ formik, metadata }) => {
  return (
    isEmpty(metadata.conditionals) ||
    evaluateConditionals(metadata.conditionals, formik.values)
  );
};

/**
 * The inline group is a container for displaying components "inline".
 *
 * @visibleName INLINE
 */
export const InlineGroup: React.FC<InlineGroupProps> = ({
  disabled,
  formik,
  metadata
}) => {
  const attributes = get(metadata, 'attributes', {});
  const divider = get(attributes, 'divider', '');
  return (
    <div
      className={
        divider
          ? // RM update the tw-space-x-4 to only apply to sm and above
            'tw-relative tw-flex tw-flex-wrap tw-items-center sm:tw-flex-nowrap sm:tw-space-x-4'
          : 'tw-relative tw-flex tw-flex-wrap tw-items-baseline sm:tw-flex-nowrap sm:tw-space-x-4'
      }
    >
      {spliceDividerBetweenComponents(
        metadata.components,
        divider,
        disabled,
        formik
      )}
    </div>
  );
};

export interface InlineGroupProps {
  disabled?: boolean;
  /** Reference to Formik instance **/
  formik: IFormik;
  metadata: IMetadata;
}

export default InlineGroup;
