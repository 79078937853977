import { IMetadataEndpoint } from '@broadleaf/admin-components/dist/types/metadata';

export function getFetchInvoicesEndpoint(): IMetadataEndpoint {
  return {
    params: {
      q: '${filter.query}',
      cq: '${filter.query_builder}',
      page: '${paging.pageNumber}',
      size: 50
    },
    mapParams: [
      {
        from: 'sort.sort',
        to: 'sort'
      }
    ],
    scope: 'ORDER',
    type: 'GET',
    uri: '/search/order-search'
  };
}

export function getSubmitInvoicesEndpoint(): IMetadataEndpoint {
  return {
    scope: 'INVOICE',
    type: 'POST',
    uri: '/order/invoices/export'
  };
}

export function getApplyRevMedCreditEndpoint(
  invoiceId: string
): IMetadataEndpoint {
  return {
    scope: 'INVOICE',
    type: 'POST',
    uri: '/order/invoices/' + invoiceId + '/credit'
  };
}

export function getApplyInvoiceSettingsEndpoint(
  invoiceId: string
): IMetadataEndpoint {
  return {
    scope: 'INVOICE',
    type: 'POST',
    uri: '/order/invoices/' + invoiceId + '/settings'
  };
}

export function getConsolidatedOrderEndpoint(
  orderId: string
): IMetadataEndpoint {
  return {
    scope: 'ORDER',
    type: 'GET',
    uri: '/order/orders/' + orderId + '/consolidated'
  };
}
