/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useCallback, useContext, useState } from 'react';
import { selectAllSelectedIds } from '@broadleaf/admin-components/dist/collection/hooks/useCollectionState';
import Button from '@broadleaf/admin-components/dist/common/elements/Button';
import type {
  IMetadata,
  IMetadataAction
} from '@broadleaf/admin-components/dist/types/metadata';
import type { ICollectionStateHook } from '@broadleaf/admin-components/dist/types/collection';
import { IConsolidatedOrder } from '../../../../utils/RmFulfillmentUtils';
import InvoiceDetailsModal from '../../InvoiceDetailsModal';
import { request } from '@broadleaf/admin-components/dist/metadata/utils/request';
import useContextParams from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/hooks/useContextParams';
import log from '@broadleaf/admin-components/dist/common/utils/log';
import { ToastContainerContext } from '@broadleaf/admin-components/dist/common';
import { getConsolidatedOrderEndpoint } from '../../../utils/InvoicesOperationUtils';

const logger = log.getLogger(
  'oms.components.InvoicesView.ReadyToSubmitGrid.action-components.GetInvoiceDetailsAction'
);
export const GetInvoiceDetailsAction: React.FC<GetInvoiceDetailsActionProps> = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  //using consolidated order because it pulls back the invoice and credit account information
  const [
    consolidatedOrder,
    setConsolidatedOrder
  ] = useState<IConsolidatedOrder>(null);
  const contextParams = useContextParams(props.metadata);
  const toast = useContext(ToastContainerContext);

  const allSelectedIds = selectAllSelectedIds(props.collectionState.state);
  const disabled =
    props.isSelectAll ||
    allSelectedIds.length === 0 ||
    allSelectedIds.length > 1;

  const fetchConsolidatedOrder = useCallback(async () => {
    setLoading(true);
    try {
      const req = {
        invoiceIds: allSelectedIds
      };
      const endpoint = getConsolidatedOrderEndpoint(allSelectedIds[0]);
      const { data } = await request(
        { method: 'get', data: req, ...endpoint },
        contextParams
      );
      setConsolidatedOrder(data);
      setIsOpen(true);
    } catch (err) {
      if (err.cancelled) {
        return;
      }
      const errorMessage = `An error occurred while loading invoice: ${err.message}`;
      logger.error(errorMessage);
      toast.addToast(errorMessage, {
        autoClose: 5000,
        closeOnClick: true,
        type: 'error'
      });
    } finally {
      setLoading(false);
    }
  }, [allSelectedIds, contextParams, toast]);

  function handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    fetchConsolidatedOrder();
  }

  return (
    <>
      <Button
        className="ml-2"
        color="primary"
        disabled={disabled}
        onClick={handleClick}
        size="sm"
        type="button"
      >
        {props.actionDefinition.label}
      </Button>
      {isOpen && consolidatedOrder && (
        <InvoiceDetailsModal
          consolidatedOrder={consolidatedOrder}
          metadata={props.metadata}
          onClose={() => {
            setIsOpen(false);
            setConsolidatedOrder(null);
            props.collectionState.handleClearAllSelections();
          }}
          collectionState={props.collectionState}
        />
      )}
    </>
  );
};

export interface GetInvoiceDetailsActionProps {
  metadata: IMetadata;
  actionDefinition?: IMetadataAction;
  collectionState?: ICollectionStateHook;
  isSelectAll?: boolean;
}

export default GetInvoiceDetailsAction;
