/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { FC, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import cx from 'classnames';

import useQuery from '../../../hooks/useQuery';
import { join } from '@broadleaf/admin-components/dist/common/utils/path';
import PlaceholderBlock from '@broadleaf/admin-components/dist/common/components/PlaceholderBlock';
import SVG from '@broadleaf/admin-components/dist/common/components/SVG';
import Link from '@broadleaf/admin-components/dist/common/elements/Link';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import useBackLocation from '@broadleaf/admin-components/dist/metadata/hooks/useBackLocation';

import messages from './Header.messages';
import { IFulfillmentViewCommon } from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/fulfillment';
import { getComponents } from '@broadleaf/admin-components/dist/metadata/utils/MetadataUtils';
import { useAccessForSections } from '@broadleaf/admin-components/dist/oms/components';

/**
 * Renders the header for the order view.
 *
 * @author ncrum
 */
export const Header: React.FC<HeaderProps> = ({
  className,
  metadata,
  state,
  actionComponents,
  dispatch,
  components
}) => {
  const packingSlip = useQuery().has('packingSlip');
  const print = useQuery().has('print');
  const history = useHistory();
  const fulfillmentItemsLength = state.data?.fulfillmentItems?.length;
  useEffect(() => {
    if (packingSlip && print && fulfillmentItemsLength > 0) {
      window.addEventListener(
        'afterprint',
        event => {
          setTimeout(
            () =>
              history.replace(window.location.pathname + '?packingSlip=true'),
            100
          );
        },
        { once: true }
      );
      window.print();
    }
  }, [fulfillmentItemsLength, packingSlip, print]);
  return (
    <>
      <header
        className={cx(
          className,
          'tw-relative tw-z-10 tw-flex tw-w-full tw-flex-row tw-items-center tw-border-b tw-border-t tw-border-gray-200 tw-bg-white tw-px-3 tw-pt-4 tw-shadow'
        )}
      >
        <BackButton
          metadata={metadata}
          state={state}
          toOverride={packingSlip ? window.location.pathname : null}
        />

        <div className="tw-ml-2 tw-flex tw-flex-col tw-overflow-hidden print:tw-ml-0">
          {state.data ? (
            <TitleContent state={state} packingSlip={packingSlip} />
          ) : (
            <TitlePlaceholder />
          )}
        </div>
        {state.isPrintLabelError && (
          <div className="text-danger tw-mx-4 tw-justify-self-end">
            {state.fetchError.message}
          </div>
        )}
        <div className="tw-mr-4 tw-flex tw-flex-auto tw-flex-row-reverse print:tw-hidden">
          <Link
            className=""
            to={{
              pathname: window.location.pathname,
              search: packingSlip ? '' : '?packingSlip'
            }}
          >
            View {packingSlip ? 'Fulfillment' : 'Packing Slip'}
          </Link>
        </div>
      </header>
      <FulfillmentViewNav
        actionComponents={actionComponents}
        components={components}
        dispatch={dispatch}
        metadata={metadata}
        state={state}
      />
    </>
  );
};

export interface HeaderProps extends IFulfillmentViewCommon {
  className?: string;
}

const BackButton = ({ metadata, state, toOverride }) => {
  const to = useBackLocation(metadata, state.data);
  return (
    <Link
      className="tw-border-none tw-text-gray-700 hover:tw-text-gray-900 hover:tw-no-underline focus:tw-outline-none print:tw-hidden"
      to={toOverride || to}
    >
      <SVG
        className="tw-inline-block tw-h-8 tw-w-8 tw-fill-current tw-align-middle"
        name="cheveron-left"
      />
    </Link>
  );
};

const TitleContent = ({ state, packingSlip }) => {
  return (
    <React.Fragment>
      <TitleReferenceNumber
        referenceNumber={state.data.orderFulfillmentNumber}
        packingSlip={packingSlip}
      />
    </React.Fragment>
  );
};

const TitleReferenceNumber = ({ referenceNumber, packingSlip }) => {
  const formatMessage = useFormatMessage();
  let referenceNumberMessage = formatMessage(messages.referenceNumber, {
    referenceNumber: referenceNumber
  });
  if (packingSlip) {
    referenceNumberMessage += ' Packing Slip';
  }
  useEffect(() => {
    document.title = 'RevMed ' + referenceNumberMessage;
  }, [referenceNumberMessage]);
  return (
    <div
      className="tw-truncate tw-text-xl tw-font-medium tw-text-gray-900"
      title={referenceNumber}
    >
      {referenceNumberMessage}
    </div>
  );
};

const TitlePlaceholder: React.FC = () => {
  return (
    <React.Fragment>
      <PlaceholderBlock height="24" width="400" />
      <PlaceholderBlock className="tw-mt-1" height="16" width="300" />
    </React.Fragment>
  );
};

export const FulfillmentViewNav: React.FC<FulfillmentViewNavProps> = ({
  className,
  metadata
}) => {
  const { url } = useRouteMatch();
  const sections = getComponents(metadata);
  const sectionAccessByIndex = useAccessForSections(sections);
  return (
    <ul
      className={cx(
        'OrderViewNav',
        className,
        'tw-relative tw-flex tw-w-full tw-list-none tw-overflow-x-auto tw-border-b tw-border-gray-200 tw-bg-white tw-px-4 tw-shadow print:tw-hidden'
      )}
    >
      {sections.map((section, index) => {
        if (!sectionAccessByIndex[index]) {
          return null;
        }
        const { exact, path } = section.attributes;
        const joinedPath = join(url, path);
        return (
          <li key={joinedPath} title={section.label}>
            <NavLink exact={exact} to={joinedPath}>
              {section.label}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};

export interface FulfillmentViewNavProps extends IFulfillmentViewCommon {
  className?: string;
}

export interface NavLink {
  exact: boolean;
  to: string;
}

export const NavLink: FC<NavLink> = ({ children, exact, to }) => {
  const match = useRouteMatch(to);
  const isActive = !!match && (!exact || match.isExact);
  return (
    <Link
      className={cx(
        'tw-block tw-whitespace-nowrap tw-border-b-2 tw-px-4 tw-py-3 tw-font-medium hover:tw-no-underline',
        {
          'tw-border-transparent tw-text-gray-600 hover:tw-border-gray-400 hover:tw-text-primary-500': !isActive,
          'tw-border-primary-500 tw-text-gray-900': isActive
        }
      )}
      to={to}
    >
      {children}
    </Link>
  );
};
export default Header;
