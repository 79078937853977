/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
/**
 * === REACT INTL ===
 *
 * Adds the polyfills and locale data for any locale included in the entry file. This
 * will bundle up the locale data with the app code.
 *
 * See https://formatjs.io/docs/polyfills for polyfills.
 *
 * === FORMATJS NUMBERFORMAT POLYFILL ===
 *
 * Adds the polyfill and locale data for Intl.NumberFormat. This adds support
 * for everything in the ES2020 Internationalization API spec
 * (https://tc39.es/ecma402). See
 * https://formatjs.io/docs/polyfills/intl-numberformat for details.
 *
 * Additional locale data should be added as more expected locales are introduced.
 *
 * === FORMATJS RELATIVEFORMAT POLYFILL ===
 *
 * Adds the polyfill and locale data for Intl.RelativeFormat. This adds support
 * for everything in the ES2020 Internationalization API spec
 * (https://tc39.es/ecma402). See
 * https://formatjs.io/docs/polyfills/intl-relativeformat for details.
 *
 * Additional locale data should be added as more expected locales are introduced.
 *
 * === MOMENT ===
 *
 * Adds the locale data for the known locales so that MomentJS can properly
 * internationalize the dates for our date pickers and date fields.
 *
 * See `I18nProvider` for the functionality that actually switches between the
 * locale for the moment instance.
 *
 * The list of available locale data to load from moment can be found in their
 * Github repository at https://github.com/moment/moment/tree/develop/locale.
 * Additionally, these locales are located within `node_modules/moment/locale/*`.
 */
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en'; // locale-data for en
import '@formatjs/intl-numberformat/locale-data/fr'; // locale-data for fr
import '@formatjs/intl-numberformat/locale-data/es'; // locale-data for es

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en'; // locale-data for en
import '@formatjs/intl-relativetimeformat/locale-data/fr'; // locale-data for fr
import '@formatjs/intl-relativetimeformat/locale-data/es'; // locale-data for es

import 'moment/locale/fr';
import 'moment/locale/fr-ca';
import 'moment/locale/es';
import moment from 'moment-timezone';
// default the locale to 'en'
moment.locale('en');
