/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isNil } from 'lodash';
import { defineMessages } from 'react-intl';
import { MetadataUtils } from '@broadleaf/admin-components/dist/metadata';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import { FieldDecorations } from '@broadleaf/admin-components/dist/form/helpers/FieldDecorations';
import ToggleSwitch from '@broadleaf/admin-components/dist/form/components/ToggleSwitch';
import useTranslateMode from '@broadleaf/admin-components/dist/form/hooks/useTranslateMode';

/**
 * This component was copied from BLC admin-components source to fix a bug with passing noLabel (it was always set to yesLabel)
 * We also added the ability to pass a "yesColor" and "noColor" attribute to customize the color of the boolean field
 */
var booleanMessages = defineMessages({
  trueLabel: {
    id: 'BooleanField.trueLabel',
    defaultMessage: 'Yes',
    description: 'Label for the "true" option.'
  },
  falseLabel: {
    id: 'BooleanField.falseLabel',
    defaultMessage: 'No',
    description: 'Label for the "false" option.'
  }
});

var OPTION_YES = {
  color: 'primary',
  value: true
};
var OPTION_NO = {
  color: 'danger',
  value: false
};

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}
function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}
function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      // eslint-disable-next-line no-loop-func
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      // eslint-disable-next-line no-loop-func
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(
          target,
          key,
          Object.getOwnPropertyDescriptor(source, key)
        );
      });
    }
  }

  return target;
}

const LabeledBooleanField = props => {
  var metadata = props.metadata,
    formik = props.formik;
  //MetadataUtils.mustHaveMetadataProps(metadata, ['name']);
  var name = metadata.name;

  var value = get(formik.values, name, get(metadata, 'defaultValue', false));

  var yesLabel = MetadataUtils.getAttribute(metadata, 'yesLabel');
  var noLabel = MetadataUtils.getAttribute(metadata, 'noLabel');
  var yesColor = MetadataUtils.getAttribute(metadata, 'yesColor');
  var noColor = MetadataUtils.getAttribute(metadata, 'noColor');
  var formatMessage = useFormatMessage();
  // this is the section where we fixed the noLabel bug and added support for configuring color
  var options = React.useMemo(
    function () {
      return [
        _objectSpread2(
          _objectSpread2({}, OPTION_YES),
          {},
          {
            label: isNil(yesLabel)
              ? formatMessage(booleanMessages.trueLabel)
              : yesLabel,
            color: isNil(yesColor) ? OPTION_YES.color : yesColor
          }
        ),
        _objectSpread2(
          _objectSpread2({}, OPTION_NO),
          {},
          {
            label: isNil(noLabel)
              ? formatMessage(booleanMessages.falseLabel)
              : noLabel,
            color: isNil(noColor) ? OPTION_NO.color : noColor
          }
        )
      ];
    },
    [formatMessage, yesLabel, noLabel, yesColor, noColor]
  );
  var translateMode = useTranslateMode();
  return (
    <FieldDecorations {...props}>
      <ToggleSwitch
        disabled={MetadataUtils.isFieldDisabled(props, translateMode)}
        name={name}
        options={options}
        onBlur={formik.handleBlur}
        onChange={function onChange(value) {
          formik.setFieldValue(name, value);
        }}
        value={value}
      />
    </FieldDecorations>
  );
};

export default LabeledBooleanField;
