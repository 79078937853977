/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { DecimalField } from '@broadleaf/admin-components/dist/form/named-components/field-types';
import { isFieldDisabled } from '@broadleaf/admin-components/dist/metadata/utils/MetadataUtils';

/**
 * A higher-order field component to display product data
 *
 * @param {ReactComponent} DisplayComponent React component accepting `error`, `isLoading`, and `productInfo` props
 * @returns {ReactComponent}
 */
const CalculatedPriceField = ({ disabled, formik, metadata }) => {
  React.useEffect(() => {
    if (!isFieldDisabled({ disabled, metadata, formik }, { isActive: false }))
      return;
    var calcField = formik.values[metadata.attributes.calcFieldName];
    var currField = formik.values[metadata.name];
    var value = null;
    if (calcField != null) {
      value = (calcField * metadata.attributes.multipleValue).toFixed(2);
    }
    var currFieldValue = null;
    if (currField != null) {
      currFieldValue =
        typeof currField === 'number' ? currField.toFixed(2) : '';
    }
    if (value !== null && !isNaN(value) && value !== currFieldValue) {
      //todo support money field
      formik.setFieldValue(metadata.name, value);
      formik.setFieldTouched(metadata.name, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values[metadata.attributes.calcFieldName]]);
  return (
    //todo support money field
    <DecimalField disabled={disabled} formik={formik} metadata={metadata} />
  );
};

export default CalculatedPriceField;
