/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  readyToSubmit: {
    id: 'InvoicesView.readyToSubmit',
    defaultMessage: 'Ready to Submit',
    description: ''
  },
  orderProcessing: {
    id: 'InvoicesView.orderProcessing',
    defaultMessage: 'Order Processing',
    description: ''
  },
  heldInvoices: {
    id: 'InvoicesView.heldInvoices',
    defaultMessage: 'Held Invoices',
    description: ''
  },
  invoiceSubmissionHistory: {
    id: 'InvoicesView.invoiceSubmissionHistory',
    defaultMessage: 'Submitted Invoices',
    description: ''
  },
  otherInvoices: {
    id: 'InvoicesView.otherInvoices',
    defaultMessage: 'Other Invoices',
    description: ''
  },
  title: {
    id: 'InvoicesView.title',
    defaultMessage: 'Invoices',
    description: ''
  }
});
