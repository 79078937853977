import React from 'react';
import classNames from 'classnames';

/**
 * This button component was copied from AdminWeb, and the rounding stylings
 * were removed so that all buttons have sharp corners
*/
export const RMButton: React.FC<ButtonProps> = ({
  block,
  className,
  flat,
  innerRef,
  narrow,
  onClick,
  size,
  color = 'default',
  disabled = false,
  type = 'button',
  ...rest
}) => {
  return (
    <button
      className={classNames(
        'tw-inline-flex tw-flex-shrink-0 tw-items-center tw-py-2 focus:tw-outline-none tw-transition focus:tw-shadow-outline',
        {
          'tw-w-full': block,
          'tw-text-white tw-bg-primary-500 hover:tw-bg-primary-600':
            color === 'primary',
          'tw-text-white tw-bg-gray-800 hover:tw-bg-gray-900':
            color === 'secondary',
          'tw-text-white tw-bg-red-700 hover:tw-bg-red-800': color === 'danger',
          'tw-text-gray-900 tw-bg-white hover:tw-bg-gray-100':
            color === 'default',
          'tw-bg-transparent hover:tw-bg-transparent': color === 'transparent',
          'tw-text-xs': size === 'sm',
          'tw-text-base': size === 'lg',
          'tw-text-sm': !size,
          'tw-cursor-not-allowed': disabled,
          'tw-px-2': narrow,
          'tw-px-4': !narrow,
          'tw-shadow': !flat,
          [className]: !!className
        }
      )}
      disabled={disabled}
      onClick={e => !disabled && onClick && onClick(e)}
      ref={innerRef}
      style={{ opacity: disabled ? '0.65' : '1' }}
      type={type}
      {...rest}
    />
  );
};

interface ButtonProps {
  block?: boolean;
  /** the color of the button (e.g. `primary`, `secondary`, `danger`)*/
  color?: string | boolean;
  /** A ref for the button element */
  innerRef?: React.LegacyRef<HTMLButtonElement>;
  /** the size of the button (e.g. `sm`, `lg`) */
  size?: 'sm' | 'lg';
  /** the corner radius of the button (e.g. `full`, `left`, `right`) */
  rounded?: 'full' | 'left' | 'right';
  /** the type of the <button> (e.g. `submit`, `button`, etc.)*/
  type?: 'button' | 'submit' | 'reset';
  /** the content to display inside the button */
  children: React.ReactNode;
  flat?: boolean;
  narrow?: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: Function;
  title?: string;
  name?: string;
}

export default RMButton;