/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useState } from 'react';
import { ulid } from 'ulid';
import { isFieldDisabled } from '@broadleaf/admin-components/dist/metadata/utils/MetadataUtils';
import { StringField } from '@broadleaf/admin-components/dist/form/named-components/field-types';
/**
 * A higher-order field component to display Network Ref
 *
 * @param {ReactComponent} DisplayComponent React component accepting `error`, `isLoading`, and `productInfo` props
 * @returns {ReactComponent}
 */
const CalculatedNetworkRefField = ({ disabled, formik, metadata }) => {
  const [randomSuffix, setRandomSuffix] = useState('');
  React.useEffect(() => {
    setRandomSuffix(ulid().slice(-6));
  }, []);
  React.useEffect(() => {
    if (!isFieldDisabled({ disabled, metadata, formik }, { isActive: false }))
      return;
    var calcField = formik.values[metadata.attributes.calcFieldName];
    var currField = formik.values[metadata.name];
    var value = null;
    if (calcField != null) {
      value = 'RN-' + getFirstLetters(calcField) + '-' + randomSuffix;
    } else {
      value = 'RN-' + randomSuffix;
    }
    if (value !== null && value !== currField) {
      formik.setFieldValue(metadata.name, value);
      formik.setFieldTouched(metadata.name, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values[metadata.attributes.calcFieldName], randomSuffix]);
  return (
    //todo support money field
    <StringField disabled={disabled} formik={formik} metadata={metadata} />
  );
};

function getFirstLetters(str) {
  return str
    .split(' ')
    .map(word => (word[0] !== undefined ? word[0].toUpperCase() : undefined))
    .filter(str => str !== undefined && str.match(/[a-z]/i))
    .slice(0, 3)
    .join('');
}

export default CalculatedNetworkRefField;
