/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { has, get } from 'lodash';
import { Environment } from '@broadleaf/admin-components/dist/common';

/**
 * Resolve properties from Vite at build time.
 * This overrides the default build-time properties resolver, but is still
 * subsequent to the runtime resolver.
 */
function buildTimeResolver(propertyName) {
  if (has(import.meta.env, propertyName)) {
    return get(import.meta.env, propertyName);
  }

  // handle the case where we are evaluating this in CRA, which requires prefixing VITE_
  if (has(import.meta.env, `VITE_${propertyName}`)) {
    return get(import.meta.env, `VITE_${propertyName}`);
  }

  return undefined;
}

Environment.registerResolver(buildTimeResolver, 2000);
