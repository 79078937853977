/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import cx from 'classnames';
import { filter, find, isEmpty, map } from 'lodash';
import useVisibleActionDefinitions from '@broadleaf/admin-components/dist/metadata/hooks/useVisibleActionDefinitions';
import Totals from './Totals';
import useQuery from '../../../hooks/useQuery';
import { IFulfillmentViewCommon } from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/fulfillment';

export const Body: React.FC<BodyProps> = ({
  actionComponents,
  className,
  components,
  dispatch,
  metadata,
  state
}) => {
  const packingSlip = useQuery().has('packingSlip');
  return (
    <div
      className={cx(
        className,
        'tw-flex tw-min-h-full tw-w-full tw-flex-col tw-bg-white lg:tw-flex-row'
      )}
    >
      <div className="tw-order-2 tw-flex tw-flex-auto tw-flex-col lg:tw-order-1">
        <components.Details
          actionComponents={actionComponents}
          className="tw-flex"
          components={components}
          dispatch={dispatch}
          metadata={metadata}
          state={state}
        />

        <h1 className="tw-p-3">Items</h1>
        <hr className="tw-my-1 tw-flex-initial tw-border-gray-300" />

        <components.Items
          actionComponents={actionComponents}
          className="tw-flex-initial"
          components={components}
          dispatch={dispatch}
          metadata={metadata}
          state={state}
        />
        {!packingSlip && (
          <>
            <h1 className="tw-p-3">Totals</h1>
            <hr className="tw-my-1 tw-flex-initial tw-border-gray-300" />

            <Totals
              actionComponents={actionComponents}
              className="tw-flex-auto"
              components={components}
              dispatch={dispatch}
              metadata={metadata}
              state={state}
            />
          </>
        )}
      </div>

      {!packingSlip && (
        <Actions
          actionComponents={actionComponents}
          className="tw-order-1 tw-flex lg:tw-order-2 print:tw-hidden"
          dispatch={dispatch}
          metadata={metadata}
          state={state}
        />
      )}
    </div>
  );
};

export interface BodyProps extends IFulfillmentViewCommon {
  className?: string;
}

const Actions = ({
  actionComponents,
  className,
  dispatch,
  metadata,
  state
}) => {
  const actionDefinitions = useVisibleActionDefinitions(metadata);
  const primaryActionDefinitions = filter(actionDefinitions, {
    placement: 'PRIMARY'
  });
  if (isEmpty(primaryActionDefinitions)) {
    return null;
  }
  const actionElements =
    state.data &&
    map(primaryActionDefinitions, (actionDefinition, index) => {
      const actionComponent = find(actionComponents, {
        placement: actionDefinition.placement,
        type: actionDefinition.type
      });
      if (!actionComponent) {
        return null;
      }
      return (
        <actionComponent.component
          key={index}
          actionDefinition={actionDefinition}
          dispatch={dispatch}
          metadata={metadata}
          state={state}
        />
      );
    });
  return (
    <div
      className={cx(
        className,
        'tw-flex tw-w-full tw-flex-row tw-overflow-auto tw-border-b tw-bg-gray-100 tw-p-4 lg:tw-max-w-xs lg:tw-flex-col lg:tw-items-center lg:tw-border-b-0 lg:tw-border-l'
      )}
    >
      {actionElements}
    </div>
  );
};

export default Body;
