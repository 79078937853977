import React, { useContext, useEffect, useMemo, useState } from 'react';
import { find, get, isEmpty, isNaN, isNil, replace, trim } from 'lodash';

import I18nContext from '@broadleaf/admin-components/dist/common/contexts/I18nContext';
import { FieldDecorations } from '@broadleaf/admin-components/dist/form/helpers/FieldDecorations';
import useTranslateMode from '@broadleaf/admin-components/dist/form/hooks/useTranslateMode';
import {
  getAttribute,
  isFieldDisabled
} from '@broadleaf/admin-components/dist/metadata/utils/MetadataUtils';
import type { ICommonMonetaryAmount } from '@broadleaf/admin-components/dist/types/common';
import type {
  IDerivedFieldType,
  IFormik
} from '@broadleaf/admin-components/dist/types/form';
import type {
  IMetadata,
  IMetadataFieldComponent
} from '@broadleaf/admin-components/dist/types/metadata';

export const RmMoneyField: React.FC<MoneyFieldProps> = props => {
  const { disabled, formik, metadata, derivedValue } = props;
  const translateMode = useTranslateMode();
  const { handleBlur, setFieldTouched, setFieldValue } = formik;
  const { name, placeholder } = metadata;
  const amount = useGetAmount(metadata, formik, derivedValue);
  const currency = getCurrency(metadata, formik);
  const { currentLocale } = useContext(I18nContext);
  const {
    currencySymbol,
    textToDecimal,
    decimalToText
  } = useCurrencyFormatters(currency, currentLocale);
  const [draft, setDraft] = useDraft(decimalToText(amount));

  /**
   * Change handler for the input field. This function is responsible for parsing
   * out the decimal value from the input's value and updating the Formik state.
   */
  const handleChange = e => {
    const textValue = trim(e.target.value);
    const decimalValue = textToDecimal(textValue);
    if (isNaN(decimalValue) && textValue !== '-') {
      // block non-decimal inputs
      return;
    }

    setDraft(textValue);
    setFieldTouched(name, true);

    if (isEmpty(textValue) || isNil(decimalValue)) {
      // if empty, clear the whole value
      setFieldValue(name, undefined);
      return;
    }

    if (isSimpleValue(metadata)) {
      setFieldValue(name, decimalValue);
    } else {
      setFieldValue(`${name}.amount`, decimalValue);
    }
  };

  return (
    <FieldDecorations fullWidth {...props}>
      <div className="MoneyField tw-flex tw-w-full tw-items-center">
        <span
          className="MoneyField__symbol tw-flex tw-h-10 tw-shrink tw-grow-0 tw-items-center tw-rounded-tl tw-rounded-tr-none tw-rounded-br-none tw-rounded-bl tw-border tw-border-r-0 tw-border-solid tw-border-gray-300 tw-bg-gray-200 tw-py-1.5 tw-px-3 tw-text-center tw-align-middle rtl:tw-rounded-bl-none rtl:tw-rounded-br rtl:tw-rounded-tl-none rtl:tw-rounded-tr rtl:tw-border-l-0 rtl:tw-border-r"
          title={currency}
        >
          {currencySymbol}
        </span>
        <input
          className="MoneyField__input tw-tansition-colors motion-reduce:transition-none tw-block tw-h-10 tw-w-full tw-flex-auto tw-rounded-tl-none tw-rounded-tr tw-rounded-br tw-rounded-bl-none tw-border tw-border-solid tw-border-gray-300 tw-bg-white tw-bg-clip-padding tw-py-1.5 tw-px-3.5 tw-text-sm tw-font-normal tw-leading-normal tw-text-gray-600 tw-transition focus:tw-border-green-300  focus:tw-bg-white focus:tw-text-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-green-500 focus:tw-ring-opacity-30 rtl:tw-rounded-tr-none rtl:tw-rounded-br-none rtl:tw-rounded-tl rtl:tw-rounded-bl"
          disabled={isFieldDisabled(
            { disabled, formik: props.formik, metadata },
            translateMode
          )}
          id={name}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          type="text"
          value={draft}
        />
      </div>
    </FieldDecorations>
  );
};

export interface MoneyFieldProps
  extends IDerivedFieldType<ICommonMonetaryAmount | number> {
  // whether or not the field is disabled
  disabled?: boolean;
  // the formik props
  formik: IFormik;
  // the metadata for this field
  metadata: IMetadataFieldComponent;
}

const useDraft = initialDraft => {
  const [draft, setDraft] = useState(initialDraft);

  useEffect(() => {
    // when the initial draft value changes, we re-set the draft value
    setDraft(initialDraft);
  }, [initialDraft]);

  return [draft, setDraft];
};

const useCurrencyFormatters = (currency, locale) => {
  return useMemo(() => {
    const { currencySymbol, decimalSymbol, groupSymbol } = getCurrencySymbols(
      currency,
      locale
    );

    const textToDecimal = text => {
      // trim and replace decimal symbol with a valid decimal
      const formattedText = trim(
        replace(
          replace(text, stringMatching(groupSymbol), ''),
          stringMatching(decimalSymbol),
          '.'
        )
      );

      if (isEmpty(formattedText)) {
        return undefined;
      }

      return Number(formattedText);
    };

    const decimalToText = amount => {
      if (isNil(amount)) {
        return '';
      }

      const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency
      });

      const formattedAmount = formatter.format(amount);
      return trim(replace(formattedAmount, stringMatching(currencySymbol), ''));
    };

    return {
      currencySymbol,
      decimalSymbol,
      groupSymbol,
      decimalToText,
      textToDecimal
    };
  }, [currency, locale]);
};

const getCurrencySymbols = (currency, locale) => {
  let formatter;
  try {
    formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency
    });
  } catch (e) {
    if (e instanceof RangeError) {
      formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: 'USD'
      });
    } else {
      throw e;
    }
  }

  // use a number big enough to require a "group" symbol
  const formats = formatter.formatToParts(10000);

  // find an entry with the `decimal` type
  const decimalSymbol = get(find(formats, { type: 'decimal' }), 'value', '.');

  // find an entry with the `currency` type
  const currencySymbol = get(find(formats, { type: 'currency' }), 'value', '$');

  // find an entry with the `group` type
  const groupSymbol = get(find(formats, { type: 'group' }), 'value', ',');
  return { currencySymbol, decimalSymbol, groupSymbol };
};

/**
 * Gets the decimal amount of money.
 *
 * @return {Number} the number amount
 */
const useGetAmount = (
  metadata: IMetadata,
  formik: IFormik,
  derivedValue: ICommonMonetaryAmount | number
): number => {
  const [values, setValues] = useState<Record<string, any>>(
    formik.initialValues
  );

  useEffect(() => {
    // have to switch to looking at the current values at the time that
    // derivedValue changes to make sure we have the right ones
    setValues(formik.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [derivedValue]);

  return getAmount(metadata, values, derivedValue);
};

const getAmount = (
  metadata: IMetadata,
  values: Record<string, any>,
  derivedValue: ICommonMonetaryAmount | number
): number => {
  const { name } = metadata;
  if (typeof derivedValue !== 'undefined') {
    return isSimpleValue(metadata)
      ? (derivedValue as number)
      : (derivedValue as ICommonMonetaryAmount)?.amount;
  }

  if (isSimpleValue(metadata)) {
    return get(values, name);
  }

  const defaultValue = get(metadata, 'defaultValue.amount');
  const value = get(values, `${name}.amount`);
  return !value && value !== 0 ? defaultValue : value;
};

/**
 * Gets the currency for the field.
 *
 * @return {String} the currency
 */
const getCurrency = (metadata, formik) => {
  const { name } = metadata;

  let tracking = get(formik, 'initialValues.$tracking', {});
  if (isEmpty(tracking)) {
    tracking = get(formik, 'initialValues.$parent.$tracking', {});
  }
  const contextState = get(formik, 'initialValues.contextState', {});

  if (!isSimpleValue(metadata)) {
    const currencyFromValue = get(formik.values, `${name}.currency`);
    if (currencyFromValue) {
      return currencyFromValue;
    }

    const currencyFromInitialValue = get(
      formik.initialValues,
      `${name}.currency`
    );
    if (currencyFromInitialValue) {
      return currencyFromInitialValue;
    }
  }

  const currencyCodeField = getAttribute(metadata, 'currencyCodeField');
  if (currencyCodeField) {
    const currencyFromField = get(formik.values, currencyCodeField);
    if (currencyFromField) {
      return currencyFromField;
    }
  }

  const currentCatalogId = get(tracking, 'catalog.currentCatalogId');
  if (currentCatalogId) {
    const currency = getCurrencyFromCatalog(tracking, currentCatalogId);
    if (currency) {
      return currency;
    }
  }

  const entityCatalogId = get(contextState, 'catalog.contextId');
  if (entityCatalogId) {
    const currency = getCurrencyFromCatalog(tracking, entityCatalogId);
    if (currency) {
      return currency;
    }
  }

  const applicationCurrency = get(tracking, 'application.defaultCurrency');
  if (applicationCurrency) {
    return applicationCurrency;
  }

  const tenantCurrency = get(tracking, 'tenant.defaultCurrency');
  if (tenantCurrency) {
    return tenantCurrency;
  }

  return 'USD';
};

const isSimpleValue = metadata => {
  return getAttribute(metadata, 'simpleValue', false);
};

/**
 * Gets the currency from the catalog that the entity belongs to.
 * <p>
 * This is typically relevant for components in the application contexts, since there may be different
 * catalogs in a single application, using the catalog from the entity itself is most accurate.
 *
 * @param tracking the tracking details
 * @param entityCatalogId the id of the catalog that this entity belongs to
 */
const getCurrencyFromCatalog = (tracking, entityCatalogId) => {
  // check directly assigned
  const assignedCatalog = find(tracking.catalog.assignedCatalogs, {
    id: entityCatalogId
  });
  if (assignedCatalog) {
    return assignedCatalog.defaultCurrency;
  }

  // check implicit
  const parentOfImplicitCatalog = find(tracking.application.isolatedCatalogs, {
    implicit: entityCatalogId
  });
  if (parentOfImplicitCatalog) {
    const parentCatalog = find(tracking.catalog.assignedCatalogs, {
      id: parentOfImplicitCatalog.id
    });
    if (parentCatalog) {
      return parentCatalog.defaultCurrency;
    }
  }
};

const stringMatching = search => {
  const escapedSearch = replace(search, /[.*+?^${}()|[\]\\]/g, '\\$&');
  return new RegExp(escapedSearch, 'g');
};

export default RmMoneyField;
