/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useEffect, useState } from 'react';

import { RequestUtils } from '@broadleaf/admin-components/dist/metadata';
import { Environment } from '@broadleaf/admin-components/dist/common';
import useEventCallback from '@broadleaf/admin-components/dist/common/hooks/useEventCallback';

const useNavigationService = context => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [apiResponse, setApiResponse] = useState({});

  const fetchNavigation = useEventCallback(async () => {
    // clear the state in case we're retrying the fetch
    setIsLoading(true);
    setIsError(false);
    try {
      const { submenu } = await getNavigation(context);
      setApiResponse(submenu);
      setIsLoading(false);
      setIsSuccess(true);
      return submenu;
    } catch (error) {
      setIsLoading(false);
      setIsSuccess(false);
      setIsError(true);

      if (error.response) {
        if (error.response.data !== undefined) {
          setApiResponse(error.response.data);
        } else {
          setApiResponse({
            error: 'not_found'
          });
        }
      }
      setApiResponse(error);
    }
  }, [context]);

  useEffect(
    () => {
      fetchNavigation();
    },
    // eslint-disable-next-line
    [context.tracking.applicationId]
  );

  return [isLoading, isError, isSuccess, apiResponse, fetchNavigation];
};

async function getNavigation(context) {
  const { data } = await RequestUtils.get(
    {
      scope: 'ADMIN_NAVIGATION',
      url: `/${Environment.get(
        'NAVIGATION_GATEWAY',
        'admin-navigation/admin-menu-hierarchy'
      )}`
    },
    context
  );
  return data;
}

export default useNavigationService;
