/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  itemQuantityMinimum: {
    id: 'FulfillmentStatusChangeModal.itemQuantityMinimum',
    defaultMessage: 'Must provide a quantity greater than zero',
    description: 'Error message when the quantity for an item is less than zero'
  },
  itemQuantityMaximum: {
    id: 'FulfillmentStatusChangeModal.itemQuantityMaximum',
    defaultMessage:
      'Must provide a quantity no greater than the quantity available',
    description:
      'Error message when the quantity for an item is greater than the quantity of the item available'
  },
  itemQuantityRequired: {
    id: 'FulfillmentStatusChangeModal.itemQuantityRequired',
    defaultMessage: 'Must provide a quantity',
    description: 'Error message when the quantity for an item is not provided'
  },
  quantityOf: {
    id: 'FulfillmentStatusChangeModal.quantityOf',
    defaultMessage: 'of {max}',
    description: 'Message for the quantity input out of the maximum quantity'
  },
  attestFulfillOrder: {
    id: 'fulfillment.attest-fulfill-order',
    defaultMessage:
      'I confirm that I can fulfill all of the items above and that they meet the minimum expiration dates',
    description: 'Message for the user to confirm the fulfillment is accurate'
  },
  attestFulfillOrderRequired: {
    id: 'fulfillment.attest-fulfill-order-required',
    defaultMessage: 'Please confirm items are ready',
    description:
      'Error message when the fulfillment accuracy has not been attested'
  }
});
