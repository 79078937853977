/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  capitalize,
  find,
  get,
  isEmpty,
  join,
  map,
  toLower,
  words
} from 'lodash';
import cx from 'classnames';
import warning from 'warning';

import SimpleModal from '@broadleaf/admin-components/dist/common/components/SimpleModal';
import ActionLink from '@broadleaf/admin-components/dist/common/elements/ActionLink';
import Button from '@broadleaf/admin-components/dist/common/elements/Button';
import Toggle from '@broadleaf/admin-components/dist/common/helpers/Toggle';
import { mustHaveMetadataProps } from '@broadleaf/admin-components/dist/form/utils/invariantHelpers';
import { ICommonGridColumn } from '@broadleaf/admin-components/dist/types/common';
import { HelpText, SVG } from '@broadleaf/admin-components/dist/common';

/**
 * <p>
 * Renders a table column for ENUM type fields. In the table, the labels of the
 * enum options are displayed and their text color would be the corresponding
 * color set in the metadata. Additionally, if the options described in the
 * metadata also contain a "reason" prop, then the table cell will also be
 * clickable, expanding a modal to display the value of the reason for the
 * enum's current value.
 * </p>
 *
 * <div>
 * If provided, "reason" should be an object with 2 fields: "sourceField" and
 * "preformatted".
 * <ul>
 *  <li><b>sourceField</b>: Required. Indicates the name of the field on the row
 *  that holds the actual value of the reason for the enum's current value. For
 *  example, the enum could correspond to the status of a sandbox operation: If
 *  an error has occurred during a promotion, then the reason would be the
 *  a stack trace.</li>
 *  <li><b>preformatted</b>: Optional. Boolean indicating whether the value of
 *  the reason should be rendered as preformatted text (i.e, in a `<pre>`).
 *  Again, this would be set to true if the reason were a stack trace.</li>
 * </ul>
 * </div>
 *
 * @param header the header metadata for the column such as the display name of
 *     the column and the name of the corresponding field in the row
 * @param row the actual values to use in the row
 * @returns {*} A table cell for displaying the value of an ENUM type field.
 */
// @TODO: support tooltip/description for enum options
export const RawEnumColumn: React.FC<RawEnumColumnProps> = ({
  header,
  row
}) => {
  mustHaveMetadataProps(header, ['name']);

  const columnHeading = header.name;
  const rawStatus = get(row, columnHeading);

  if (!rawStatus) {
    return <div>{rawStatus}</div>;
  }

  const statusOption = getOptionMetadata(header, rawStatus);
  const color = getColor(statusOption);
  const label = get(
    statusOption,
    'label',
    rawStatus
    //RM use RAW status instead of pretty status
    // join(map(words(rawStatus), capitalize), ' ')
  );
  const reason = get(statusOption, 'reason', {});
  const isReasonTooltip = get(reason, 'tooltip', false);
  const title = join(map(words(columnHeading), capitalize), ' ');

  const reasonField = get(reason, 'sourceField');
  const reasonValue = get(row, reasonField, '');
  if (isEmpty(reason) || isEmpty(reasonField) || isEmpty(reasonValue)) {
    return (
      <div className={`text-truncate d-block ${color}`} title={title}>
        {label}
      </div>
    );
  }
  if (isReasonTooltip) {
    return (
      <div className={`text-truncate tw-inline tw-flex ${color}`} title={title}>
        {label}{' '}
        <Toggle
          render={({ isOpen, toggleOpen }) => (
            <>
              <SVG
                className={cx(
                  'tw-ml-1 tw-h-3 tw-w-3 tw-cursor-pointer tw-fill-current',
                  {}
                )}
                name={'question'}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleOpen();
                }}
              />

              <SimpleModal
                title={title}
                body={renderReason({ reason, row })}
                footer={
                  <>
                    {(title: any) => (
                      <Button
                        color="primary"
                        onClick={toggleOpen}
                        type="button"
                        title={title}
                      >
                        Close
                      </Button>
                    )}
                  </>
                }
                /*// @ts-ignore */
                isOpen={isOpen}
                onClose={() => toggleOpen()}
                size="lg"
              />
            </>
          )}
        />
      </div>
    );
  }

  return (
    <Toggle
      render={({ isOpen, toggleOpen }) => (
        <Fragment>
          <ActionLink
            className={cx(
              'text-truncate',
              'd-block',
              { active: isOpen },
              color
            )}
            onClick={() => toggleOpen()}
            title={title}
          >
            {label}
          </ActionLink>

          <SimpleModal
            title={title}
            body={renderReason({ reason, row })}
            footer={
              <>
                {(title: any) => (
                  <Button
                    color="primary"
                    onClick={toggleOpen}
                    type="button"
                    title={title}
                  >
                    Close
                  </Button>
                )}
              </>
            }
            /*// @ts-ignore */
            isOpen={isOpen}
            onClose={() => toggleOpen()}
            size="lg"
          />
        </Fragment>
      )}
    />
  );
};

function getColor(statusOption) {
  const color = toLower(get(statusOption, 'color') || 'black');

  if (legacyColors.has(color)) {
    return `text-${color}`;
  }

  return `tw-text-${color}-600`;
}

const legacyColors = Object.freeze(
  new Set(['primary', 'secondary', 'warning', 'success', 'danger', 'info'])
);

function getOptionMetadata(header, rawStatus) {
  let options = get(header, 'attributes.options', []);

  if (isEmpty(options)) {
    options = get(header, 'options', []);
  }

  return find(options, ['value', rawStatus]) || {};
}

/**
 * Renders the value of the <code>reason.sourceField</code> in either a `<p>`
 * or `<pre>` depending on <code>reason.preformatted</code>. SourceField must
 * point to a field on the row that contains the reason message concerning the
 * enum's current value. Preformatted indicates whether the reason found should
 * be rendered in a paragraph or preformatted tag, depending on if it is a
 * simple text message or preformatted text, like a stack trace or markup.
 *
 * @param reason An object containing the fields <code>sourceField</code>
 *     (required) and <code>preformatted</code> (optional; default is false).
 * @param row The row containing the field with a reason mesage for the enum's
 *     value.
 * @returns {*} The value gleaned from the row for the enum's value's reason.
 */
const renderReason = ({ reason, row }) => {
  mustHaveMetadataProps(reason, ['sourceField']);
  const reasonField = get(reason, 'sourceField');
  const isReasonPreformatted = get(reason, 'preformatted', false);
  warning(!!get(row, reasonField), `Row is missing value for '${reasonField}'`);

  const reasonValue = get(row, reasonField, '') || 'Reason not found';

  if (isReasonPreformatted) {
    return <pre className="pre-wrap">{reasonValue}</pre>;
  }

  return <p>{reasonValue}</p>;
};

/**
| Property | Default | Description |
| ---- | ---- | ---- |
| `label` | `undefined` | the label of this column, used by ListGrid |
| `attributes.options.[*].color` | `"secondary"` | Display color used for the text |
| `attributes.options.[*].label` | `attributes.options.[*].value` or `options.[*].value` | Friendly text to display in place of the raw enum value |
| `attributes.options.[*].reason` | `{}` | Describes the reason for the current enum value. If the enum corresponds to something like a sandbox operation status and the option was for an error status, then this would contain info indicating the field containing a stack trace for the error and if it should be displayed as preformatted text |
| `attributes.options.[*].reason.sourceField` | NA | Required if reason present. Defines the name of the field in the form state where the value of the reason message can be found |
| `attributes.options.[*].reason` | `false` | Optional if reason present. Defines whether to wrap the reason message in `<pre>` instead of `<p>`. |
 */
export interface RawEnumColumnProps extends ICommonGridColumn {}

export default RawEnumColumn;
