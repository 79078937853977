/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useEffect } from 'react';
import cx from 'classnames';
import { isEmpty, map } from 'lodash';
import sanitize from 'sanitize-html';
import Currency from '@broadleaf/admin-components/dist/common/components/Currency';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import PopoverLabel from '@broadleaf/admin-components/dist/oms/components/PopoverLabel';
import {
  getItemAdjustments,
  getItemAdjustmentsTotal,
  getItemAttributeChoices,
  getItemName,
  getItemPrices,
  getItemTotal
} from '@broadleaf/admin-components/dist/oms/utils/FulfillmentItemUtils';

import messages from './FulfillmentItemSummary.messages';
import useQuery from '../../../hooks/useQuery';
import { IOrderFulfillmentItem } from '@broadleaf/admin-components/dist/types/oms';

export const FulfillmentItemSummary: React.FC<FulfillmentItemSummaryProps> = ({
  className,
  fulfillmentItem,
  showUnitPrice = true
}) => {
  const packingSlip = useQuery().has('packingSlip');
  return (
    <div
      className={cx(
        className,
        'FulfillmentItemSummary tw-flex tw-flex-col tw-items-center tw-p-3 md:tw-flex-row md:tw-items-start'
      )}
    >
      <div className="tw-flex tw-w-full tw-flex-1 tw-flex-row tw-overflow-hidden md:tw-ml-3 md:tw-w-auto">
        <div className="tw-flex-1 tw-overflow-hidden">
          <ItemName fulfillmentItem={fulfillmentItem} />
          <ItemSku
            fulfillmentItem={fulfillmentItem}
            packingSlip={packingSlip}
          />
          <ItemPromotions fulfillmentItem={fulfillmentItem} />
        </div>

        <div className="tw-ml-3 tw-flex-initial">
          {packingSlip ? (
            <ItemPackingSlipInfo fulfillmentItem={fulfillmentItem} />
          ) : (
            <ItemTotal
              fulfillmentItem={fulfillmentItem}
              showUnitPrice={showUnitPrice}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export interface FulfillmentItemSummaryProps {
  className?: string;
  fulfillmentItem?: Record<string, any>;
  showUnitPrice?: boolean;
}

export const ItemName = ({ fulfillmentItem }) => {
  const name = getItemName(fulfillmentItem);
  return (
    <div
      className="tw-truncate tw-text-lg tw-font-semibold tw-leading-tight tw-text-gray-800"
      title={name}
    >
      {name}
    </div>
  );
};

export const ItemSku = ({ fulfillmentItem, packingSlip }) => {
  const item = fulfillmentItem.orderItem;
  const itemAttrs = {
    Company: item.attributes.companyName
  };
  if (packingSlip) {
    itemAttrs['Device ID'] = item.attributes.upc;
  } else {
    itemAttrs['Catalog / Mfr #'] = item.attributes.productSku;
  }
  itemAttrs['Description'] = item.attributes.description;
  return (
    <div className="tw-truncate tw-text-sm tw-text-gray-700">
      {Object.entries(itemAttrs).map(([key, value]) => (
        <>
          {value && (
            <div className="tw-flex tw-max-w-sm tw-items-center">
              <div className="tw-hidden tw-font-medium tw-text-gray-500 md:tw-block">
                {key}
              </div>
              <div
                className="tw-ml-1"
                dangerouslySetInnerHTML={{
                  __html: sanitize(value, {
                    allowedAttributes: {
                      '*': ['class', 'style']
                    }
                  })
                }}
              ></div>
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export const ItemAttributeChoices = ({ fulfillmentItem }) => {
  const attributeChoices = getItemAttributeChoices(fulfillmentItem);
  if (isEmpty(attributeChoices)) {
    return null;
  }
  return (
    <ul className="tw-mt-2 tw-list-none tw-text-sm">
      {map(attributeChoices, (attributeChoice, attributeName) => (
        <li key={attributeName}>
          <span
            className="tw-font-semibold tw-text-gray-700"
            title={attributeChoice.optionLabel || attributeName}
          >
            {`${attributeChoice.optionLabel || attributeName}: `}
          </span>
          <span
            className="tw-font-medium tw-text-gray-600"
            title={attributeChoice.label || attributeChoice.value}
          >
            {attributeChoice.label || attributeChoice.value}
          </span>
        </li>
      ))}
    </ul>
  );
};

export const ItemPromotions = ({ fulfillmentItem }) => {
  const formatMessage = useFormatMessage();
  const itemAdjustmentsTotal = getItemAdjustmentsTotal(fulfillmentItem);
  const itemAdjustments = getItemAdjustments(fulfillmentItem);
  if (isEmpty(itemAdjustments)) {
    return null;
  }
  return (
    <div className="tw-mt-2">
      <PopoverLabel
        label={formatMessage(messages.promotionsApplied)}
        position="bottom-left"
      >
        <div className="tw-p-2">
          {itemAdjustments.map((itemAdjustment, index) => {
            const { amount, codeUsed, offerRef } = itemAdjustment;
            const label = codeUsed || (offerRef && offerRef.name) || '---';
            return (
              <ItemLabeledPrice
                key={index}
                className="tw-mb-1 tw-text-xs"
                label={label}
                price={amount}
              />
            );
          })}
        </div>
        <hr className="tw-border-gray-300" />
        <div className="tw-p-2">
          <ItemLabeledPrice
            className="tw-text-sm"
            label={formatMessage(messages.itemAdjustmentsTotal)}
            price={itemAdjustmentsTotal}
          />
        </div>
      </PopoverLabel>
    </div>
  );
};

// purgecss: tw-text-xl tw-text-2xl tw-text-3xl tw-text-4xl tw-text-5xl tw-w-18
export const ItemPackingSlipInfo = ({ fulfillmentItem }) => {
  const attrs = fulfillmentItem.orderItem.attributes;
  const packingSlipAttrs = {
    'Catalog / Mfr #': { value: attrs.productSku, size: '2xl', className: '' }
  };

  if (attrs['expirationDate']) {
    packingSlipAttrs['Min Exp. Date'] = {
      value: attrs.expirationDate,
      size: 'xl'
    };
  }

  if (attrs['lotNumber']) {
    packingSlipAttrs['Lot Number'] = { value: attrs.lotNumber, size: '2xl' };
  }

  if (attrs['serialNumber']) {
    packingSlipAttrs['Serial Number'] = {
      value: attrs.serialNumber,
      size: '2xl'
    };
  }
  // quantity should always be last
  packingSlipAttrs['Unit'] = {
    value: attrs.unitOfMeasurement,
    size: 'sm',
    className: 'tw-w-18 tw-text-center'
  };
  // quantity should always be last
  packingSlipAttrs['Quantity'] = {
    value: fulfillmentItem.quantity,
    size: '4xl'
  };
  return (
    <>
      <div className="tw-flex tw-flex-1 tw-space-x-8 tw-px-8 tw-py-2">
        {Object.entries(packingSlipAttrs).map(([key, value]) => (
          <div className="tw-flex tw-flex-col tw-items-center">
            <div
              className={cx(
                value.className,
                'tw-block tw-text-2xl tw-font-medium tw-text-gray-600'
              )}
            >
              {key}
            </div>
            <div
              className={`tw-h-10 tw-leading-10 tw-text-${value.size} tw-text-center`}
            >
              {value.value}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export const ItemTotal = ({ fulfillmentItem, showUnitPrice }) => {
  const formatMessage = useFormatMessage();
  const attrs = fulfillmentItem.orderItem.attributes;
  const itemAdjustmentsTotal = getItemAdjustmentsTotal(fulfillmentItem);
  const itemTotal = (fulfillmentItem as IOrderFulfillmentItem).merchandiseTotal;
  const { unitPrice } = getItemPrices(fulfillmentItem);
  return (
    <>
      <div className="tw-p-2">
        {showUnitPrice && (
          <ItemLabeledPrice
            className="tw-mb-1 tw-text-sm"
            label={formatMessage(messages.itemUnitPrice)}
            price={unitPrice}
            prefix={`${fulfillmentItem.quantity} x`}
          />
        )}

        <div className="tw-mb-1 tw-flex tw-justify-between tw-text-sm">
          <div className="tw-px-6 tw-text-gray-700">UOM</div>
          <div className="tw-font-medium tw-text-gray-900">
            {attrs.unitOfMeasurement}
          </div>
        </div>

        {attrs.expirationDate && (
          <div className="tw-mb-1 tw-flex tw-justify-between tw-text-sm">
            <div className="tw-px-6 tw-text-gray-700">Min Exp. Date</div>
            <div className="tw-font-medium tw-text-gray-900">
              {attrs.expirationDate}
            </div>
          </div>
        )}

        <ItemLabeledPrice
          className="tw-mb-1 tw-text-sm"
          label={formatMessage(messages.itemTotal)}
          price={itemTotal}
        />

        {!!itemAdjustmentsTotal && itemAdjustmentsTotal.amount > 0 && (
          <ItemLabeledPrice
            className="tw-mb-1 tw-text-sm tw-text-red-600"
            label={formatMessage(messages.itemAdjustmentsTotal)}
            price={{
              ...itemAdjustmentsTotal,
              amount: -itemAdjustmentsTotal.amount
            }}
          />
        )}
      </div>
    </>
  );
};

export const ItemLabeledPrice = ({ className, label, price, prefix = '' }) => {
  return (
    <div className={cx(className, 'tw-flex tw-justify-between')}>
      <div className="tw-px-6 tw-text-gray-700">{label}</div>
      <div className="tw-font-medium tw-text-gray-900">
        {prefix && <span className="tw-text-xs">{prefix}</span>}{' '}
        <Currency currency={price.currency} value={price.amount} />
      </div>
    </div>
  );
};

export default FulfillmentItemSummary;
