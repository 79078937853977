/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useState } from 'react';
import cx from 'classnames';
import { ulid } from 'ulid';

export const RmFulfillmentAction: React.FC<FulfillmentActionProps> = ({
  children,
  color = 'gray',
  disabled = false,
  onClick,
  tooltip = ''
}) => {
  const tooltipId = 'tooltip-' + ulid().slice(-6);
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="tw-relative tw-w-full">
      <button
        className={cx(
          'FulfillmentAction',
          `focus:tw-shadow-outline tw-rounded tw-px-4 tw-py-4 tw-text-sm tw-font-semibold focus:tw-outline-none tw-text-${color}-700 hover:tw-bg-${color}-100 focus:tw-bg-${color}-100 active:tw-bg-${color}-200 tw-border tw-border-${color}-200 tw-bg-white tw-shadow md:tw-mb-3 md:tw-py-2 lg:tw-w-full`,
          {
            'tw-cursor-not-allowed': disabled
          }
        )}
        disabled={disabled}
        onClick={!disabled ? onClick : undefined}
        type="button"
        data-tooltip-target={tooltipId}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {children}
      </button>
      {isHovered && tooltip && (
        <div
          data-tooltip={tooltipId}
          role="tooltip"
          className="tw-absolute tw-top-full tw-z-[100] tw-whitespace-normal tw-break-words tw-rounded-lg tw-bg-black tw-px-3 tw-py-1.5 tw-text-sm tw-font-normal tw-text-white focus:tw-outline-none"
        >
          <span className="rw-rounded-sm tw-absolute tw-top-[-3px] tw-left-1/2 tw--z-10 tw-h-2 tw-w-2 tw--translate-x-1/2 tw-rotate-45 tw-bg-black"></span>
          {tooltip}
        </div>
      )}
    </div>
  );
};

export interface FulfillmentActionProps {
  color?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  tooltip?: string;
}

export default RmFulfillmentAction;
