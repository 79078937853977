/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

import type { ICommonGridHeader } from '@broadleaf/admin-components/dist/types/common';
import messages from './InvoicesGridUtils.messages';

export const getDefaultInvoiceHeaders = (
  formatMessage
): Array<ICommonGridHeader> => {
  const statusOptions = getInvoiceStatusOptions(formatMessage);
  return [
    {
      attributes: {
        colSpan: 1,
        dateFormat: 'L'
      },
      classifier: 'Field',
      label: formatMessage(messages.headers.submitDate),
      name: 'submitDate',
      type: 'DATE',
      sortable: true,
      order: 100
    },
    {
      classifier: 'Field',
      label: formatMessage(messages.headers.orderNumber),
      name: 'orderNumber',
      type: 'STRING',
      sortable: true,
      order: 200
    },
    {
      classifier: 'Field',
      label: formatMessage(messages.headers.invoiceStatus),
      name: 'invoiceStatus',
      type: 'RAW_ENUM',
      sortable: true,
      options: statusOptions,
      order: 300
    },
    {
      classifier: 'Field',
      label: formatMessage(messages.headers.shipToOrg),
      name: 'invoiceShipToOrganization',
      type: 'STRING',
      sortable: true,
      order: 400
    },
    {
      classifier: 'Field',
      label: formatMessage(messages.headers.invoiceTotal),
      name: 'invoiceTotal',
      type: 'MONEY',
      align: 'right',
      sortable: true,
      order: 500
    },
    {
      classifier: 'Field',
      label: formatMessage(messages.headers.revMedCreditAmount),
      name: 'invoiceRevMedCreditAmount',
      type: 'MONEY',
      align: 'right',
      sortable: true,
      order: 600
    }
  ];
};

export function getInvoiceStatusOptions(formatMessage) {
  const restingStatuses = [
    {
      color: 'primary',
      label: formatMessage(messages.statusOptions.resting.submitted),
      value: 'SUBMITTED'
    },
    {
      color: 'primary',
      label: formatMessage(messages.statusOptions.resting.invoicePaid),
      value: 'INVOICE_PAID'
    },
    {
      color: 'primary',
      label: formatMessage(messages.statusOptions.resting.reimbursed),
      value: 'REIMBURSED'
    },
    {
      color: 'danger',
      label: formatMessage(messages.statusOptions.resting.rejected),
      value: 'REJECTED',
      reason: {
        sourceField: 'invoiceStatusReason',
        preformatted: false
      }
    },
    {
      color: 'danger',
      label: formatMessage(messages.statusOptions.resting.disputed),
      value: 'DISPUTED',
      reason: {
        sourceField: 'invoiceStatusReason',
        preformatted: false
      }
    }
  ];

  const activeStatuses = [
    {
      color: 'info',
      label: formatMessage(messages.statusOptions.active.orderProcessing),
      value: 'ORDER_PROCESSING'
    },
    {
      color: 'info',
      label: formatMessage(messages.statusOptions.active.creditPending),
      value: 'CREDIT_PENDING'
    },
    {
      color: 'info',
      label: formatMessage(messages.statusOptions.active.readyToSubmit),
      value: 'READY_TO_SUBMIT'
    }
  ];

  const errorStatuses = [
    {
      color: 'danger',
      label: formatMessage(messages.statusOptions.error.holdForReview),
      value: 'HOLD_FOR_REVIEW',
      reason: {
        sourceField: 'invoiceStatusReason',
        tooltip: true
      }
    },
    {
      color: 'danger',
      label: formatMessage(messages.statusOptions.error.requiresPo),
      value: 'REQUIRES_PO'
    }
  ];
  return [...restingStatuses, ...activeStatuses, ...errorStatuses];
}
