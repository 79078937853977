import React from 'react';

import { Option } from './RMToggleSwitch';

/**
 * This is the exact same component as provided in AdminWeb, but included here
 * since ToggleSwitch depends on it, and we are ovreriding ToggleSwitch
 */
export const ToggleSwitchInput: React.FC<ToggleSwitchInputProps> = ({
  disabled = false,
  handleBlur,
  id,
  name,
  option,
  selected = false
}) => {
  return (
    <input
      checked={selected}
      className="tw-absolute tw-w-0 tw-invisible"
      disabled={disabled}
      id={id}
      name={name}
      onBlur={handleBlur}
      readOnly
      type="radio"
      value={option.value}
    />
  );
};

interface ToggleSwitchInputProps {
  disabled?: boolean;
  id: string;
  handleBlur?: React.FocusEventHandler<HTMLInputElement>;
  name: string;
  option: Option;
  selected?: boolean;
}

export default ToggleSwitchInput;